.withdrawalTitleWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.withdrawalTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-transform: uppercase;
}

.withdrawalFormWrap {
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.withdrawalForm {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0;
  }
  h4 {
    width: 278px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    color: #888aa7;
    margin: 0;
  }
  h5 {
    width: 288px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    color: #888aa7;
    margin: 2px 0 0 0;
  }
  h3 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0;
  }
}

.withdrawalFormCopy {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  z-index: 10;
  margin-top: 10px;
  position: relative;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.withdrawalFormCopyDrop {
  width: 100%;
  position: relative;
  display: flex;
  height: 38px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0 15px;
}

.withdrawalFormBtn {
  position: absolute;
  right: 10px;
  top: 8px;

  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;

  border: none;
  background-color: transparent;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:disabled {
    cursor: auto !important;
  }
}

.withdrawalNumbers {
  color: #d5d6df;
  font-weight: 600;
  display: inline-block;
  margin: 0 3px;
}

.withdrawalAmountsWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.withdrawalAmounts {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  h5 {
    width: auto;
  }
}
.withdrawalAddressBook {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #161e31;
  border-radius: 8px;
  padding: 10px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto;
  img {
    display: inline-block;
    width: 50px;
  }
}

.withdrawalFormFooter {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0;
  }
}

.withdrawalFormFooterAddress {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 5px 0 15px;

  img {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 28px;
    right: 20px;
  }
  textarea {
    width: 400px;
  }
}

.withdrawalFormTextarea {
  background-color: #00000033;
  border-radius: 19px;
  height: 40px;
  margin-top: 5px;
  border: none;
  padding: 8px 15px 3px 15px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  outline: none;
}

.withdrawalTitleForbidden {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  width: 90%;
  margin-top: 30%;
}

.withdrawalFormErrorMessage {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 10px;
  color: #ff274b;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: 15px;
}

.memoWarning {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 10px;
  margin: 5px 0 15px;

  color: #ffffff;

  background: rgba(7, 11, 22, 0.72);
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 12px;
}

.memoTitleWrapper {
  display: flex;
  align-items: center;
}

.memoIcon {
  height: 16px;
  width: auto;
  margin-right: 8px;
}

.memoTitle {
  margin: 0;

  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
}

.memoDesc {
  margin: 10px 0;

  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
}

.button {
  margin-top: auto;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .withdrawalTitle {
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .withdrawalFormFooterAddress {
    textarea {
      width: 300px;
    }
  }
}
