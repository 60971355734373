.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 415px;
  width: 100%;
  margin: 0 0 15px;
  padding: 0 30px;

  background: linear-gradient(
    75.91deg,
    #39286d 41.18%,
    rgba(35, 73, 143, 0.06) 90.97%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 20px;
}

.info {
  position: relative;
  display: flex;
  height: 53%;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.image {
  margin-top: 100px;
  width: 380px;
  height: auto;

  pointer-events: none;
}

.auto {
  position: absolute;
  top: 1rem;
  right: 0;

  display: flex;
  align-items: center;

  span {
    margin-right: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;

    cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }
}

@media (max-width: 1400px) {
  .image {
    width: 340px;
    margin-top: 80px;
  }

  .auto span {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding: 20px 20px 0;
    height: auto;
  }

  .auto {
    top: 0;
  }

  .info {
    height: 190px;
  }
}

@media (max-width: 700px) {
  .image {
    width: 320px;
    margin-top: 60px;
  }
}

@media (max-width: 640px) {
  .wrapper {
    height: 240px;
    padding: 15px 15px 0;
    margin: 0 0 10px;
  }
  .info {
    height: 90px;
  }
  .image {
    width: 180px;
    margin-top: 30px;
  }
}

@media (max-width: 570px) {

}

@media (max-width: 470px) {
  .image {
    width: 160px;
    margin-top: 44px;
  }
}

@media (max-width: 370px) {
  .image {
    width: 130px;
    margin-top: 34px;
  }
}
