.limboWinInfoWin,
.limboWinInfoLose {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 88px;
  letter-spacing: -1px;
}

.limboWinInfoWin {
  color: #0E9628;
}

.limboWinInfoLose {
  color: #E01F48;
}

@media (max-width: 900px) {
  .limboWinInfoWin,
  .limboWinInfoLose {
    font-size: 52px;
    line-height: 68px;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 640px) {
  .limboWinInfoWin,
  .limboWinInfoLose {
    top: 10%;
  }
}
@media (max-width: 580px) {
  .limboWinInfoWin,
  .limboWinInfoLose {
    font-size: 40px;
    line-height: 48px;
  }
}
@media (max-width: 500px) {
}
@media (max-width: 400px) {
}
