.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 330px;
  padding: 40px 32px;

  background: #202d4a;
  border-radius: 16px;
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin: 0 0 20px;

  text-decoration: none;

  img {
    margin-right: 1rem;

    width: 18px;
    height: auto;
  }

  p {
    margin: 0;

    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.12px;
    color: #ffffff;
  }
}

.articles {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 200px;
  margin: 0;
  padding: 0;

  list-style-type: none;
  overflow: hidden;
}

.article {
  margin: 0 0 14px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #a2a5cc;

  cursor: pointer;
}

.articleHidden {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100px;

  background: rgb(162, 165, 204);
  background: linear-gradient(0deg, rgba(32, 45, 74, 1) 0%, rgba(162, 165, 204, 0) 100%);
}

.highlight {
  color: white;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topics {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fccd00;

  &::selection {
    pointer-events: none;
  }
}
