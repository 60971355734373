.betDetailsWrap {
  display: flex;
  flex-direction: column;
  padding: 0 30px 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.betDetailsTitle {
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 36px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 10px;
}

.betDetailsSubtitle {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.betDetailsSubtitleItem1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 25px;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #888aa7;
    margin: 5px 0 0 0;
    padding: 0;
  }
}

.betDetailsSubtitleItem2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  h3 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin: 5px 0 0 0;
    padding: 0;
  }
}

.betDetailsAmountBlock {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

.betDetailsAmount {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 16px;
  background: #2f374f;
  border: 2px solid rgba(136, 138, 167, 0.29);
  padding: 0 0 0 17px;
}

.betDetailsPayout {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 16px;
  background: #2f374f;
  border: 2px solid rgba(136, 138, 167, 0.29);
  padding: 0 0 0 17px;
}

.betDetailsMultiplier {
  width: auto;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  background: #2f374f;
  border: 2px solid rgba(136, 138, 167, 0.29);
  padding: 5px 10px;
  margin: 0 10px;
}

.betDetailsAmountTitle {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.09px;
  color: #888aa7;
  margin: 0;
  padding: 0;
}

.betDetailsAmountSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 -5px;
  height: 30px;
}

.betDetailsTokenIcon {
  width: 30px;
  height: 30px;
}
.betDetailsAmountResult {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.09px;
  color: #ffffff;
}

.seeds {
  margin: 5px 0 0;
}

.subtitleItem:first-child {
  margin: 5px 1rem 0 0;
  min-width: 100px;

  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #888aa7;

  p {
    margin: 0;
    padding: 0.3rem 0;
  }
}

.subtitleItem:last-child {
  flex-grow: 1;
  width: 50%;
}

.seedsText {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  padding: 0.3rem 0.6rem;
  margin: 0;
  width: 100%;

  border: 2px solid #3757dd;
  border-radius: 14px;

  p {
    margin: 0;

    color: #ffffff;
    font-size: 0.8rem;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 17px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div {
    margin-left: auto;
  }
}

.verifyButtonWrapper {
  margin-top: 1rem;
}

.button {
  text-transform: uppercase;
}

@media (max-width: 540px) {
  .betDetailsTitle {
    font-size: 24px;
  }
  .betDetailsSubtitleItem1 {
    margin-right: 15px;
    width: 50px;
    p {
      font-size: 12px;
    }
  }
  .betDetailsSubtitleItem2 {
    h3 {
      font-size: 10px;
      word-break: break-all;
    }
  }
  .betDetailsAmountBlock {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .betDetailsPayout,
  .betDetailsAmount {
    align-items: center;
    padding: 0;
    img {
      margin-left: -3px;
    }
  }
  .betDetailsMultiplier {
    width: 150px;
    height: 60px;
    padding: 5px 10px;
    margin: 10px 0;
  }
  .betDetailsAmountTitle {
    font-size: 12px;
  }
  .betDetailsTokenIcon {
    width: 25px;
    height: 25px;
  }
  .betDetailsAmountResult {
    font-size: 12px;
  }
}
