.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 14px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;

  &:last-child {
    margin: 0;
  }
}

.col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  margin: 0 0 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #7074a4;
}

.data,
.data > span {
  display: flex;
  align-items: center;
  height: 28px;
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.coin {
  width: 28px;
  height: auto;
}

@media (max-width: 640px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .row {
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;
  }

  .col {
    &:first-child {
      margin: 0 14px 0 0;
    }
  }
}

@media (max-width: 500px) {
  .wrapper {
    flex-direction: column;
  }

  .col {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: 0 14px 0 0;
    }

    &:first-child {
      width: 215px;
      min-width: 215px;
    }
  }
}

@media (max-width: 400px) {
  .col:first-child {
    width: 165px;
    min-width: 165px;
  }

  .title {
    margin: 0 8px 0 0;

    font-size: 12px;
    line-height: 16px;
  }

  .data,
  .data > span {
    font-size: 12px;
    line-height: 16px;

    img {
      width: 18px;
    }
  }
}

@media (max-width: 330px) {
  .col:first-child {
    width: 135px;
    min-width: 135px;
  }

  .title {
    margin: 0 6px 0 0;

    font-size: 10px;
    line-height: 14px;
  }

  .data,
  .data > span {
    font-size: 10px;
    line-height: 14px;

    img {
      width: 16px;
    }
  }
}