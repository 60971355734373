.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #202D4A;
  border-radius: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  img {
    width: 100%;
    height: auto;
    display: inline-block;
  }

  p {
    padding: 0 16px;
    color: #FCCD00;
    
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
}

.body {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.bodyInp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 2px;
  margin-right: 10px;
  padding-right: 8px;

  div {
    justify-content: flex-start !important;
    img {
      position: absolute;
      width: 35px !important;
      height: 35px !important;
      top: 2px !important;
      left: 5px !important;
    }
  }
}