.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  cursor: url("../../../../../assets/img/common/cursor-pointer.webp"), auto !important;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }
}

.avatar {
  margin: 0 10px 0 0;
  width: 30px;
}

.alias {
  display: inline-block;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 0 0;
}

@media (max-width: 1250px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 863px) {
  .alias {
    font-size: 10px;
  }
  .avatar {
    width: 25px;
  }
}
@media (max-width: 700px) {
}

@media (max-width: 580px) {
  .alias {
    font-size: 10px;
  }

  .avatar {
    width: 20px;
  }
}
@media (max-width: 400px) {
  .alias {
    font-size: 9px;
  }
}
@media (max-width: 380px) {
  .alias {
    font-size: 8px;
  }
}
