.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 16px;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  backdrop-filter: blur(10px);
  box-shadow: 0px 20px 40px 10px #00000040;
  padding: 32px;


  img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  button {
    min-width: 140px;
    width: auto;
    padding: 0 16px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p{
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
  }

  h3 {
    font-size: 14px;
    color: #A2A5CC;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
  }
}