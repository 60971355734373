.gamesCardsWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  max-width: 1340px;
  font-size: 10px;
  width: 100%;
  margin-top: 30px;
}

.gamesTitle {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gamesLine {
  width: 90px;
  height: 2px;
  border-radius: 1px;
  background-color: #ed9700;
}

.gamesCard {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 30px;

  grid-template-columns: repeat(4, 1fr);
}

.gamesAddMore {
  margin-top: 30px;
}

.button {
  text-transform: uppercase;
}

@media (min-width: 1700px) {
}

@media (max-width: 1300px) {
  .gamesCardsWrap {
    font-size: 9px;
  }
}
@media (max-width: 1200px) {
  .gamesCardsWrap {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .gamesCard {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}
@media (max-width: 940px) {
  .gamesCardsWrap {
    font-size: 7px;
  }
}
@media (max-width: 840px) {
}
@media (max-width: 768px) {
}
@media (max-width: 650px) {
  .gamesCardsWrap {
    font-size: 5px;
  }
}
@media (max-width: 550px) {
  .gamesCard {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 450px) {
}
@media (max-width: 400px) {
  .gamesTitle {
    font-size: 40px;
  }
}
