.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1264px;
}

.title {
  margin: 0 0 16px;

  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
}

.orange {
  color: #fccd00;
}

.desc {
  margin: 0 0 24px;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.bonuses {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 80%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.bonus {
  position: relative;
  z-index: 0;

  height: 270px;
  padding: 35px 10px;

  border-radius: 16px;
  background: #202d4a;

  overflow: hidden;
}

.iconBg {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 50%;

  background-image: url('../../../../../assets/img/Vip/weekly-bonus-bg.webp');
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.bonusIcon {
  height: 100px;
  width: auto;

  filter: grayscale(1);
}

.content {
  position: relative;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.text {
  display: flex;
  align-items: center;
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.secondary {
  width: 80px;

  color: #a2a5cc;
}

.bonusEmoji {
  margin: 0 10px 0 0;
}

.main {
  display: flex;
  align-items: center;

  img {
    margin: 0 6px 0 0;

    width: 28px;
    height: auto;
  }
}

@media (max-width: 950px) {
  .bonuses {
    max-width: 100%;
  }
}

@media (max-width: 790px) {
  .text span {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 730px) {
  .bonuses {
    grid-template-columns: repeat(2, 1fr);
  }

  .text span {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 530px) {
  .bonuses {
    grid-template-columns: 1fr;
  }
}
