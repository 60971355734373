.kenoBetAmountBlockWrap {
  display: flex;
  flex-grow: 1;
}

@media (max-width: 640px) {
  .kenoBetAmountBlockWrap {
    width: 100%;
  }
}
