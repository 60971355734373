.kenoWinAnimation {
  display: flex;
  width: 250px;
  height: 250px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  justify-content: center;
  align-items: center;
}

.kenoWinAnimation.kenoWinAnimationActive {
  animation: scaleKenoWin 1s ease-in-out;
  z-index: 2;
}

.kenoWinAnimation.kenoWinAnimationActiveFast {
  -moz-animation: scaleKenoWin 0.4s ease-in-out;
  -webkit-animation: scaleKenoWin 0.4s ease-in-out;
  -ms-animation: scaleKenoWin 0.4s ease-in-out;
  -o-animation: scaleKenoWin 0.4s ease-in-out;
  animation: scaleKenoWin 0.4s ease-in-out;
  z-index: 2;
}

@keyframes scaleKenoWin {
  0% {
    -moz-transform: translate(-50%, -50%) scale(0);
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    -o-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  20% {
    -moz-transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    -moz-transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}

.kenoWinAnimationContentWrap {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Montserrat';
  color: #FFFFFF;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
  h3 {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    font-size: 36px;
    line-height: 68px;
    z-index: 4;
  }
}

.kenoWinAnimationContentPayout {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat';
  color: #FFFFFF;
  z-index: 4;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    margin: 0;
  }
  img {
    width: 30px;
    position: unset !important;
    margin: 0;
    display: inline-block;
    transform: translate(0, 0) !important;
  }
}

@media (max-width: 640px) {
  .kenoWinAnimation {
    width: 200px;
    height: 200px;
  }
  .kenoWinAnimationContentWrap {
    h3 {
      font-size: 24px;
    }
  }
  .kenoWinAnimationContentPayout {
    h4 {
      font-size: 12px;
    }
    img {
      width: 25px;
    }
  }
}
