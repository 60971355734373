.gameTitlesWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat';
  width: 100%;
}

.gamesTitles {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  padding: 10px 20px;
  background: #161E31;
  border-radius: 8px;
  p {
    cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    transition: 0.2s ease-in-out;
    margin: 0;
    &:hover {
      color: #ED9700;
    }
  }
}

.gamesTitlesCurrent {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);
  border-radius: 20px;
  padding: 10px 20px;
  p {
    cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .gameTitlesWrap {
    flex-wrap: wrap;
  }
  .gamesTitles,
  .gamesTitlesCurrent{
    font-size: 14px;
    margin-right: 2px;
  }
}
