.signupContent {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  form {
    width: 100%;
  }
  h3 {
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
    color: #ffffff;
  }

  p {
    margin: 0;
  }
}

.submitButton {
  text-transform: uppercase;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 15px 0 6px;

    &:first-child {
      margin-top: 0;
    }
  }

  #user-email {
    text-transform: lowercase;

    &::placeholder {
      text-transform: none;
    }
  }

  .promocode input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }

  .checkboxes {
    margin: 14px 0;
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    width: 288px;
    margin: 0;

    .checkboxWrapperLabel {
      display: flex;
      flex-grow: 1;
      margin: 0 0 0 30px;

      label {
        text-align: start;

        a {
          color: #ffb22d;
          font-weight: 400;

          &:hover {
            color: #ffb22d;
            cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
          }
        }
      }
    }
  }
}

.signEmailIcon {
  position: absolute;
  height: 12px;
  width: 14px;
  left: 25px;
  top: 35%;
  z-index: 1;
}
.signLockIcon {
  position: absolute;
  height: 15px;
  width: 14px;
  left: 25px;
  top: 30%;
  z-index: 1;
}
.signAdditionalIcon {
  position: absolute;
  height: 14px;
  width: 14px;
  right: 25px;
  top: 30%;
  z-index: 1;
  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  width: 288px;
  min-height: 50px;

  p {
    font-weight: 600;
    font-size: 14px;
    color: #f02f41;
  }
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;

  p {
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0 10px 0 0;
  }

  button {
    color: #888aa7;
    font-weight: 400;
    font-size: 14px;

    border: none;
    border-bottom: 1px solid #888aa7;
    background: transparent;

    &:hover {
      cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    }
  }
}