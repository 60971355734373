.seoWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  flex-direction: column;
  margin: 0 0 30px 0;
  background: #202d4a;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
}

.seoContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  overflow: hidden;

  font-family: 'Montserrat';
  font-weight: 400;
  color: #7074a4;
  font-size: 14px;
  h1 {
    margin: 10px 0 25px 0;
    font-size: 26px;
    font-weight: 700;
  }
  h2 {
    margin: 10px 0 20px 0;
    font-size: 20px;
    font-weight: 700;
  }
  h4,
  h3,
  h5 {
    margin: 10px 0 15px 0;
    font-size: 18px;
    font-weight: 700;
  }
  p {
    margin: 8px 0 10px 0;
    font-size: 14px;
    font-weight: 500;
  }
  li {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #7074a4;
    margin: 5px 0 5px 0;
  }
  a {
    color: white;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }

  table {
    width: 100%;
    margin: 0 0 32px;

    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid #7074a4;

    tr {
      border-bottom: 1px solid #7074a4;
    }

    thead {
      th {
        padding: 10px 4px;

        font-weight: bold;

        border-right: 1px solid #7074a4;
      }
    }

    tbody {
      td {
        padding: 10px 4px;

        border-right: 1px solid #7074a4;
      }
    }
  }
}

.seoTitle {
  width: 100%;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 30px 0;
  position: relative;
  color: #ffffff;
  &::before {
    content: '';
    width: 90px;
    height: 2px;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #ed9700;
  }
}

.seoShadow {
  display: flex;
  width: 100%;
  height: 0;
  box-shadow: 0 -10px 35px 30px #202d4a;
}

.seoBtn {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  button {
    height: 42px !important;
    text-transform: uppercase !important;
  }
}

@media (max-width: 640px) {
  .seoWrap {
    padding: 30px 15px 15px;
  }

  .seoShadow {
    box-shadow: 0 -10px 35px 14px #202d4a;
  }
}
