.myBetsTableWrap {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 20px;
}
.myBetsTableContent{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.myBetsTableThead {
  display: flex;
  height: 50px;
  background: rgba(109, 157, 251, 0.08);
  border-radius: 19px;
  width: 100%;
}

.myBetsTableTheadTr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}

.myBetsTableTheadTh{
  width: 25%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  b {
    color: #6987C7;;
  }
}

.myBetsTableTbody{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 3px;
}

.myBetsTableTbodyTr {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  border-radius: 19px;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  &:nth-of-type(even) {
    background-color: #00000033;
  }
}
.myBetsTableTbodyTh {
  display: flex;
  width: 25%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  height: 50px;
}
.myBetsTableTbodyTd {
  display: flex;
  width: 25%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  strong {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #8bc834;
    margin: 0;
  }
  code {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #f02f41;
    margin: 0;
  }
  small {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
  span {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
}

.myBetsTableCurrencyImg {
  width: 45px;
  height: 45px;
  margin: -5px 0 0 -9px;
}

@media (max-width: 1024px) {
  .myBetsTableTbodyTd{
    small,
    strong,
    code {
      font-size: 12px;
    }
  }
  .myBetsTableCurrencyImg {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 863px) {
  .myBetsTableTheadTh{
    b {
      font-size: 13px;
    }
  }
  .myBetsTableTbodyTd{
    small,
    strong,
    code {
      font-size: 10px;
    }
    span {
      font-size: 10px;
    }
  }
}



@media (max-width: 640px) {
  .myBetsTableContent{
    padding: 30px 10px;
  }

  .myBetsTableTbodyTd:nth-last-of-type(2) {
    display: none;
  }

  .myBetsTableTheadTh:nth-last-of-type(2) {
    display: none;
  }

  .myBetsTableTheadTh,
  .myBetsTableTbodyTd {
    width: 35%;
    &:nth-last-of-type(4) {
      width: 30%;
    }
  }
}

@media (max-width: 480px) {
  .myBetsTableTbodyTd{
    small,
    strong,
    code {
      display: inline-block;
      width: 62px;
      overflow: hidden;
      height: 14px;
      margin-left: 0;
      margin-bottom: 5px;
      word-spacing: 15px;
    }
    span {
      font-size: 8px;
    }
  }
  .myBetsTableCurrencyImg {
    width: 25px;
    height: 25px;
  }
  .myBetsTableTheadTh,
  .myBetsTableTbodyTd {
    width: 35%;
    padding: 0;
    &:nth-last-of-type(3) {
      width: 30%;
    }
    &:nth-last-of-type(4) {
      width: 30%;
      padding: 0 0 0 10px;
    }
  }
}
