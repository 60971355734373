.wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 60% 36%;
  grid-gap: 15px;
  position: relative;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    color: #7074A4;
    margin-bottom: 7px;
  }
}

.block div {
  width: 100%;
}


.buyCryptoInputDropWrap {
  display: flex;
  width: 100%;
}

.buyCryptoInputDropWrap.disable {
  opacity: 0.5;
  pointer-events: none;
}

.buyCryptoInputDropHeaderPara {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500 !important;
  margin: 0 !important;
  color: #ffffff;
}

.buyCryptoInputDropHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 38px;
  background: #00000024;
  border-radius: 18px;
  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto;
  padding-left: 20px;
  &:hover {
    transition: 0.2s ease-in-out;
    border: 2px solid #6c5dd3;
  }
}

.buyCryptoInputDropI {
  border: 1px solid #888aa7;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 3px;
  margin-right: 30px;
  position: absolute;
  top: 12px;
  right: -10px;
  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto;
  transition: all 0.3s ease-in-out;
}

.buyCryptoInputDropI.iUp {
  margin-top: 3px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}
.buyCryptoInputDropI.iDown {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 3px;
}

.buyCryptoInputDropBody {
  visibility: hidden;
  flex-direction: column;
  width: 100%;
  height: 0;
  background: rgba(7, 11, 22, 1);
  transition: all 0.1s ease-in-out;
  transition-duration: 000ms;
  transition-delay: 000ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  margin-top: 10px;
  border-radius: 24px;
  overflow: hidden;
  opacity: 0;


  position: absolute;
  top: 60px;
  left: 0;
  max-height: 0;
}
.buyCryptoInputDropBodyOpen {
  visibility: visible;
  opacity: 0.94;
  transition: all 0.3s ease-in-out;
  transition-duration: 100ms;
  transition-delay: 100ms;
  z-index: 5;
  padding: 10px 15px 20px 0;

  height: auto;
  max-height: 200px;
}

.buyCryptoInputDropBodyInputWrap {
  display: flex;
  width: 100%;
  position: relative;
  margin: 5px 0 10px;
  padding: 0 0 0 10px;
  img {
    position: absolute;
    top: 50% !important;
    right: 10px !important;
    transform: translateY(-50%) !important;
    width: 16px !important;
    height: auto !important;
  }
}

.buyCryptoInputDropBodyInput {
  width: 100%;
  background: transparent;
  border-radius: 19px;
  height: 38px;
  outline: none;
  box-shadow: none;
  color: #ffffff;

  padding: 0 0 0 15px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #424862
}

.buyCryptoInputDropBodyInput::placeholder {
  color: #7074A4;
}

.buyCryptoInputDropBodyContent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 160px;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: #424862 transparent;
  border-bottom: 20px solid transparent;
  padding: 0 0 0 10px;
}
.buyCryptoInputDropBodyContent::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

.buyCryptoInputDropBodyContent::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.buyCryptoInputDropBodyContent::-webkit-scrollbar-thumb {
  background-color: #424862;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid transparent;  /* creates padding around scroll thumb */
}

.buyCryptoInputDropBodyItem {
  display: flex;
  width: 100%;
  padding: 5px 7px;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto;
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #5942EE;
    padding: 5px;
    margin-right: 10px;
  }
  img {
    display: inline-block;
    height: 25px;
    width: 25px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  h4, h3 {
    font-family: 'Montserrat';
    font-weight: 500;
    margin: 0;
    font-size: 14px;
  }
  h4 {
    color: #424862;
  }
  h3 {
    color: #ffffff;
  }
}

.byCryptoInputDropNoResults {
  font-family: 'Montserrat';
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  color: #424862;
  padding: 20px 0;
  text-align: center;
  width: 100%;
}