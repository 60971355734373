p {
  margin: 0;
}

.article {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  grid-column: span 2;

  background: #202d4a;
  border-radius: 16px;
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);

  .imageWrapper {
    height: 210px;
    width: 100%;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .image {
      width: auto;
      height: 100%;
    }
  }

  .contentWrapper {
    width: 100%;
    height: 210px;
  }

  &:nth-child(1),
  &:nth-child(2) {
    height: 230px;
    flex-direction: row;
    grid-column: span 3;

    .imageWrapper {
      width: 50%;
      height: 100%;

      border-top-right-radius: 0;
      border-bottom-left-radius: 16px;
    }

    .contentWrapper {
      max-width: 50%;
      height: 230px;
    }
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;

  overflow: hidden;
}

.meta {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 16px;
}

.tagWrapper {
  display: flex;
  align-items: center;
  height: 16px;
}

.tag {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #fccd00;

  button {
    font: inherit;
    color: inherit;

    border: none;
    background: none;
  }

  &::after {
    content: " |";
    margin-right: 0.25rem;

    font-size: 13px;
    line-height: 15px;
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
}

.date {
  margin-left: auto;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #7074a4;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  margin: 0 0 8px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;

  overflow: hidden;

  &:hover {
    color: #ffffff;
  }
}

.desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #c1c5fb;

  overflow: hidden;
}

.link {
  position: relative;

  display: flex;
  align-items: center;
  width: max-content;
  margin-top: auto;

  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;

  span {
    position: relative;
    z-index: 2;

    padding: 3px 13px;
    width: 100%;
    height: 100%;

    background: #202d4a;
    border-radius: 16px;
  }

  &:hover {
    color: white;
    box-shadow: -7.9602e-16px 0px 15px #7a2cff;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: -3px;
    top: -3px;

    padding: 3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);

    background: rgb(56, 86, 221);
    background: linear-gradient(
      90deg,
      rgba(56, 86, 221, 1) 0%,
      rgba(122, 44, 255, 1) 100%
    );
    border-radius: 16px;
  }
}

@media (max-width: 1200px) {
  .title {
    font-size: 14px;
    line-height: 18px;
  }

  .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .tag {
    font-size: 11px;
    line-height: 15px;
  }

  .date {
    font-size: 11px;
    line-height: 15px;
  }
}

@media (max-width: 900px) {
  .article {
    grid-column: span 3;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      height: max-content;
      flex-direction: column;

      .imageWrapper {
        height: 210px;
        width: 100%;

        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .contentWrapper {
        width: 100%;
        max-width: 100%;
        height: 210px;
        max-height: 210px;
      }
    }
  }
}

@media (max-width: 550px) {
  .article {
    grid-column: span 6;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      grid-column: span 6;
    }
  }
}
