.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  padding: 40px 0 30px;

  background: linear-gradient(180deg, rgba(217, 171, 64, 1) 0%, rgba(40, 44, 68, 1) 100%);
  background-size: 100% 220px;
  background-repeat: no-repeat;
}

.image {
  width: 182px;
  height: auto;
  margin-bottom: 32px;
}

.title {
  color: white;
  font-family: Montserrat;
  font-size: 24px;
  text-transform: uppercase;
}

.description {
  max-width: 300px;

  color: #c1c5fb;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.action {
  width: 328px !important;
  margin: 80px 16px 0;
}

@media (max-width: 768px) {
}
