.kenoSubmitButtonWrap {
  display: flex;
  flex-grow: 4;
  height: 91px;
}

.kenoSubmitButton{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: #FFFFFF;
}

@media (max-width: 640px) {
  .kenoSubmitButtonWrap {
    height: 60px;
  }
  .kenoSubmitButton{
    font-size: 14px;
  }
}
