.limboBetAmountBlockWrap {
  display: flex;
  width: auto;
  flex-grow: 4;

}

@media (max-width: 640px) {
  .limboBetAmountBlockWrap {
    width: 100%;
  }
}
