.userInfo {
  z-index: 800;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 136px;
  margin: 0 auto;
}

.userInfoContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 10px;
  margin-bottom: 18px;
  margin-top: 10px;
  width: 147px;
}

.userInfoFooter {
  display: flex;
  align-items: center;
  width: 127px;
  height: 50px;
  border-top: 1px solid #313e57;
  img {
    width: 14px;
    height: 14px;
    cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    margin-right: 13px;
  }

  p {
    margin: 0 !important;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    text-transform: none;
    cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }

  &:hover {
    p,
    img {
      transform: scale(1.2);
    }
  }
}

.userInfoContentItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;
  margin: 7px 0;

  p {
    text-transform: none;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin: 0;

    &:hover {
      color: #ffa608;
      cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    }
  }
}

.userInfoContentElem {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 147px;
  font-family: 'Montserrat';
  margin: 0;
  font-weight: 300;
  font-size: 13px;
  color: #ffffff;

  a {
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    text-transform: none;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    text-decoration: none;

    &:hover {
      color: #ffa608;
      cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    }
  }
  i {
    border: 1px solid #313e57;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
    margin-right: 30px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
