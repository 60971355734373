.wrapper {
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 30px 0 15px;

  background: rgba(255, 255, 255, 0.24);
  border: none;
  border-radius: 8px;
}

.icon {
  width: 12px;
  height: auto;

  color: white;
}

.prev {
  right: 190px;
}

.next {
  right: 125px;
}

.prevIcon {
  rotate: 180deg;
}
