.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  font-family: 'Montserrat';
}

.tableTitlesWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tablesTitles {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  background: transparent;
  padding: 10px 20px !important;

  p {
    cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
    transition: 0.2s ease-in-out;
    margin: 0;
    text-transform: uppercase;

    &:hover {
      color: #ed9700;
    }
  }
}

.tablesTitlesCurrent {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  background: linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  border-radius: 20px;
  padding: 10px 20px !important;

  p {
    cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
    margin: 0;
    text-transform: uppercase;
  }
}

.calendar {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}

.resetButton {
  margin-right: 10px;

  background: none;
  border: none;

  cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.resetIcon {
  width: 20px;
  height: auto;

  fill: white;

  border-radius: 12px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 12px 0 white;
  }
}

@media (max-width: 1090px) {
  .tablesTitles,
  .tablesTitlesCurrent {
    font-size: 16px;
  }
}

@media (max-width: 1090px) {
  .tableTitlesWrap {
    flex-wrap: wrap;
  }
  .tablesTitles,
  .tablesTitlesCurrent {
    font-size: 13px;
    padding: 5px 10px !important;
  }
}

@media (max-width: 825px) {
  .wrapper {
    flex-direction: column;
  }

  .calendar {
    margin: 20px auto 0;
  }
}

@media (max-width: 476px) {
  .calendar {
    margin: 10px auto 0 0;
  }
}
@media (max-width: 370px) {
  .tablesTitles,
  .tablesTitlesCurrent {
    font-size: 12px;
  }
}
