.wrapper {
  display: flex;
  flex-direction: column;
  background: #202d4a;
  border-radius: 20px;
  position: relative;
  flex-grow: 3;
  min-width: 500px;
  min-height: 480px;
  overflow: hidden;
}

.icon {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: 100%;
  max-width: 605px;
}

.header {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 3;
  margin-bottom: 24px;
  h3 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
  }
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  z-index: 1;
}

.earn {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  background-image: url('../../../../../assets/img/ReferralPage/green-bg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left;
  border-radius: 16px;

  h2 {
    font-size: 24px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0 0 24px;
    font-weight: 700;
  }
}

.earnLineWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.earnLine {
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    color: #fccd00;
    font-family: 'Montserrat';
    margin: 0 10px 0 0;
    font-weight: 700;
    font-size: 24px;
  }

  p {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
    color: #7074a4;
    font-family: 'Montserrat';
    margin: 0 0 0 5px;
    font-weight: 600;
  }
}

.linkTitle {
  font-size: 14px;
  color: #7074a4;
  font-family: 'Montserrat';
  margin: 24px 0 0 0;
  font-weight: 600;
}

.linkWrap {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
  z-index: 2;
  background: #070b16b8;
  border-radius: 18px;
  h5 {
    font-size: 14px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    flex-grow: 1;
    line-height: 36px;
    padding: 0 16px;

    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.linkBodyTooltip {
  position: absolute;
  right: 0;
  width: 100%;
  // padding:  0 16px;
  height: 37px;
  border-radius: 19px;
  background: #808080;
  visibility: hidden;
  transform: translateY(0);
  transition: all 0.1s ease-in-out;
  transition-duration: 200ms;
  transition-delay: 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 11px;
  color: #ffff;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.linkBodyTooltip.open {
  visibility: visible;
  transform: translateY(-150%);
}

.linkBodyButton {
  position: relative;
  // right: 0;
  // width: 90px;
  padding: 0 24px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  border: none;
  background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
  box-shadow: -7.9602e-16px 13px 16px rgba(0, 0, 0, 0.2);
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  transition: 0.1s ease-in-out;
  img {
    position: absolute;
    left: 12px;
    top: 13px;
    width: 12px;
    height: 12px;
  }
  p {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 11px;
    color: #ffff;
    margin-left: 18px;
    margin-bottom: 2px;
  }
  &:hover {
    filter: brightness(120%);
  }
}

.share {
  display: flex;
  width: 100%;
  padding: 16px;
  background-image: url('../../../../../assets/img/ReferralPage/purple-bg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin: 0 0 24px 0;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
  }
  img {
    width: 25px;
    height: auto;
    margin-right: 5px;
  }
}

.buttonWrap {
  display: flex;
  width: 250px;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2;
}

.stats {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.statsItem {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    color: #7074a4;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
  }
  h2 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
  }
  img {
    height: 30px;
    width: auto;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.statsItemReferral {
  opacity: 0;
  width: 1px !important;
}

.notAuthWrap {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.padding {
  padding: 16px;
}
.hidden {
  display: none;
}

.noShadow {
  box-shadow: none;
}

@media (max-width: 1024px) {
  .notAuthWrap {
    img {
      width: 100px;
    }
  }
}

@media (max-width: 860px) {
  .body {
    grid-template-columns: 1fr;
  }
  .pool {
    width: 100%;
  }
  .notAuthWrap {
    img {
      width: 200px;
    }
    min-height: 400px;
  }
}

@media (max-width: 640px) {
  .wrapper {
    min-width: 100%;
  }
  .earn {
    h2 {
      font-size: 16px;
    }
  }
  .earnLine {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    h6 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }

    h4 {
      font-size: 14px;
    }
  }
  // .linkWrap {
  //   flex-direction: column;
  //   h5 {
  //     width: 100%;
  //     text-align: left;
  //   }
  // }
  // .linkBodyButton {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row-reverse;
  //   img {
  //     position: unset;
  //     width: 16px;
  //     height: 16px;
  //   }
  // }
  .share {
    p {
      font-size: 12px;
    }
    img {
      width: 20px;
      margin-right: 3px;
    }
  }
  .buttonWrap {
    width: 100%;
  }
  .stats {
    flex-direction: column;
  }

  .statsItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
