.limboGameWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 15px 30px;
  min-height: 100vh;
}

.limboGameContentWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.limboGameContentWrap > div:nth-of-type(2) {
  margin: 0;
}

.limboGameContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  padding-right: 30px;

  width: calc(100% - 375px);
}

.limboGameContentHeader {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(200px, 100%) minmax(min-content, auto);
  grid-column-gap: 15px;
  margin-bottom: 15px;
}

.limboGameContentMain {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.limboGameContentDropdown {
  display: flex;
  width: 100%;
  margin-top: 5px;
}

.limboGameContentNavigation {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}
.limboGameContentNavigationSubmitWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 4;
  min-width: 190px;
}

.limboGameAutoSettings {
  display: grid;
  grid-template-columns: 1fr;
  width: calc(100% - 375px);
  margin-top: 15px;
}

.limboGameTableWrap {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
}

.statistics {
  top: 6px;
}

@media (max-width: 1260px) {
  .limboGameContent {
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
  .limboGameAutoSettings {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .limboGameWrap {
    padding: 15px;
  }
  .limboGameContentHeader {
    margin-bottom: 5px;
  }
  .limboGameContentDropdown {
    order: 3;
    margin-top: 0;
  }
  .limboGameContentNavigation {
    gap: 10px;
    margin-top: 10px;
  }
  .limboGameAutoSettings {
    width: 100%;
    min-width: 100%;
  }
}
