.footer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  background: #1103032b;
  padding-bottom: 40px;

  p,
  h3 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: white;
    margin: 0;
  }
  p a {
    text-decoration: none;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ed9700;
    cursor: url('../../assets/img/common/cursor-pointer.webp'), auto !important;
    padding-left: 5px;
  }
}

@media (max-width: 640px) {
  .footer {
    align-items: center;
  }
}

@media (max-width: 640px) {
  .footer {
    padding-bottom: 40px;
  }
}
