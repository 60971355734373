.contactsWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0 30px 30px;
  background: radial-gradient(100% 100% at 50% 0%, #34109a 0%, rgba(36, 44, 69, 1) 100%);
  min-height: 300px;
  font-family: 'Montserrat';
}

.contactContentTitleWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}
.contactContentTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
}

.contactContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contactContentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;

  img {
    display: inline-block;
    height: 116px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #888aa7;
    margin: -10px 0 0 0;
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin: 0 0 10px;
  }
}

@media (max-width: 768px) {
  .contactContentTitle {
    font-size: 18px;
  }
}

@media screen and (max-width: 540px) {
  .contactsWrap {
    padding: 0 15px 30px;
  }
  .contactContentItem {
    margin: 0 10px;

    img {
      height: 96px;
    }
    h5 {
      font-size: 14px;
      line-height: 18px;
    }
    h4 {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
