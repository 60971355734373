.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  padding: 0 0 0 5px;
  min-height: 30px;
  p {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin: 0;
  }
  h6 {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    color: #b83518;
    margin: 0;
  }
}