.authWrap {
  display: flex;
  margin-right: 36px;
  align-items: center;
  justify-content: space-around;
  z-index: 11;
}

@media (max-width: 768px) {
  .authWrap {
    width: 350px;
    justify-content: space-around;
  }
}
@media (max-width: 500px) {

  .authWrap {
    margin-right: 10px !important;
  }
}