.wrapper {
  display: flex;
  flex-direction: column;
  background: #202D4A;
  border-radius: 20px;
  min-height: 480px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.icon {
  display: inline-block;
  width: 100%;
  height: auto;
  border-radius: 16px;
}

.body {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 16px;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  p{
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    margin-right: 5px;
  }

  button {
    border: none;
    background: none;
  }
}

.info {
  fill: #ffffff;
  opacity: 0.5;

  cursor: pointer;
  width: 18px;
  height: auto;

  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;

  p{
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 12px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 30px;
    height: auto;
  }
}

.titleActive {
  color: #FCCD00;

  font-family: 'Montserrat';
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.titleInactive {
  color: #A2A5CC;
  
  font-family: 'Montserrat';
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.buttonWrap {
  width: 100%;
  margin-top: 8px;
}

.forbiddenRakeback {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  justify-content: flex-end;

  img {
    display: inline-block;
    width: 50%;
    height: auto;
    margin: 20px auto;
  }
}

.desc {
  margin: 0 auto 14px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.link {
  width: 100%;
  border-radius: 19px;
  // height: 38px;
  span {
    width: 100%;
    text-align: center;
    border-radius: 19px;
    line-height: 24px;
  }
}