.wrapper {
  display: flex;
  width: 100%;
  padding: 24px 16px 16px;
  flex-direction: column;
  background: #202D4A;

  
}

.title {
    color: #ffffff;
    font-family: 'Montserrat';
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    text-align: left;
}

.buttonWrap {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.listStyle {
  max-height: 100%;
}