.wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.title {
  width: auto;
  margin-bottom: 0;
}

@media (max-width: 640px) {
  .wrapper {
    margin-bottom: 24px;
    width: 100%;
    justify-content: center;
  }
}