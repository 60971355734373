.vaultConfirmTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.12px;

  color: #ffffff;
}

.vaultConfirmationImage {
  display: inline-block;
  width: 200px;
}

.vaultConfirmContent {
  display: flex;
  width: 100%;
  flex-grow: 3;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h5 {
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      display: inline-block;
      width: 35px;
      margin-left: -15px;
    }
  }
}

.vaultConfirmItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;

  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #888aa7;
    margin: 0;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;

    img {
      display: inline-block;
      width: 35px;
      margin-bottom: 3px;
    }
  }
}
.vaultConfirmItem:nth-of-type(1) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  p {
    img {
      border-radius: 50%;
      margin: 5px 5px 8px;
      width: 20px;
    }
  }
}

.h3 {
  font-weight: 400;
  font-size: 14px;
  color: #f02f41;
  margin: 10px 0;
}

.button {
  width: max-content;
  padding: 0 16px;

  text-transform: uppercase;
}
