.minesWinAnimation {
  display: none;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 200px;
  color: white;
  font-size: 22px;
  opacity: 0;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes appearResultMines {
  0% {
    bottom: 0;
    opacity: 0;
  }
  20% {
    bottom: 20%;
    opacity: 1;
  }
  99% {
    bottom: 20%;
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.minesWinAnimation.minesWinAnimationActive {
  display: flex;
  animation: appearResultMines 0.5s ease-in-out;
}

.minesWinAnimationContent, .minesWinAnimationContentBig {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.minesWinAnimationContentShadow {
  position: absolute;
  z-index: 0;
  left: -50%;
  bottom: -60%;
  width: 500px;
  height: 500px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.6) 61.91%, rgba(0, 0, 0, 0) 100%);
}

.minesWinAnimationContentItem1 {
  height: 50px;
  background: linear-gradient(180deg, #0E9628 0%, #075816 100%);
  border-radius: 11.2348px;
  position: absolute;
  right: 0;
  top: 15px;
  padding: 0 20px 0 15px;
  transform: skewX(-15deg);
  text-align: center;
  z-index: 1;
  p {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0.533654px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 5.61741px 5.61741px rgba(0, 0, 0, 0.35);
    transform: skewX(15deg);
  }
}

.minesWinAnimationContentItem2 {
  width: 100%;
  height: 90px;
  background: linear-gradient(180deg, #D1A123 0%, #D06325 100%);
  border-radius: 11.2348px;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 57px;
  line-height: 90px;
  letter-spacing: 0.533654px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0 5.61741px 5.61741px rgba(0, 0, 0, 0.35);
  z-index: 1;
}

.minesWinAnimationContentItem3 {
  width: 180px;
  height: 58px;
  background: linear-gradient(180deg, #4125BB 0%, #291778 100%);
  border-radius: 11.2348px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 15px;
  bottom: 10px;
  transform: skewX(-10deg);
  z-index: 1;
}

.minesWinAnimationContentImageSmall {
  width: 25px;
  height: 25px;
  transform: skewX(10deg);
}

.minesWinAnimationContentWinAmount {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 39px;
  letter-spacing: 0.224696px;
  color: #FFFFFF;
  text-shadow: 0px 5.61741px 5.61741px rgba(0, 0, 0, 0.35);
  margin: 0;
  transform: skewX(10deg);
  z-index: 2;
}

.minesWinAnimationContentImageBig {
  width: 55px;
  height: 50px;
  position: absolute;
  top: 15px;
  left: 40%;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
  z-index: 2;
}

.minesWinAnimationContentImageMiddle1,
.minesWinAnimationContentImageMiddle2,
.minesWinAnimationContentImageMiddle3 {
  width: 45px;
  height: 45px;
  position: absolute;
  animation: rotation 3s linear infinite ;
  z-index: 2;
}

.minesWinAnimationContentImageMiddle1 {
  top: 30px;
  left: -15px;
}
.minesWinAnimationContentImageMiddle2 {
  bottom: 30px;
  left: -20px;
  width: 40px;
  height: 40px;
}
.minesWinAnimationContentImageMiddle3 {
  top: -15px;
  right: -25px;
}

.minesWinAnimationContentImageSmall1,
.minesWinAnimationContentImageSmall2,
.minesWinAnimationContentImageSmall3 {
  position: absolute;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
  z-index: 2;
}

.minesWinAnimationContentImageSmall1{
  width: 35px;
  height: 35px;
  top: -20px;
  left: 30px;
}
.minesWinAnimationContentImageSmall2{
  width: 30px;
  height: 30px;
  top: 70px;
  right: -10px;
}
.minesWinAnimationContentImageSmall3{
  width: 25px;
  height: 25px;
  bottom: 20px;
  right: 25px;
}


.minesWinAnimationContentBigWin {
  width: 250px;
  height: 250px;
  position: absolute;
  left: -2%;
  top: -20%;
  z-index: 1;
}

.minesWinAnimationContentBigItem1 {
  position: absolute;
  bottom: -10px;
  left: 55px;
  z-index: 2;
  height: 60px;
  width: 130px;
  background: linear-gradient(180deg, #0E9628 0%, #075816 100%);
  border-radius: 11.2348px;
  padding: 0 20px 0 15px;
  text-align: center;
  p {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 22px;
    line-height: 60px;
    letter-spacing: 0.533654px;
    color: #FFFFFF;
    text-shadow: 0px 5.61741px 5.61741px rgba(0, 0, 0, 0.35);
    margin: 0;
  }
}

.minesWinAnimationContentBigLarge,
.minesWinAnimationContentBigMiddle1,
.minesWinAnimationContentBigMiddle2,
.minesWinAnimationContentBigMiddle3,
.minesWinAnimationContentBigSmall1,
.minesWinAnimationContentBigSmall2,
.minesWinAnimationContentBigSmall3,
.minesWinAnimationContentBigSmall4,
.minesWinAnimationContentBigTiny{
  position: absolute;
  z-index: 3;
}

.minesWinAnimationContentBigLarge {
  width: 60px;
  height: 60px;
  top: -50px;
  left: 40px;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
}
.minesWinAnimationContentBigMiddle1 {
  width: 45px;
  height: 45px;
  top: 10px;
  right: -10px;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
}
.minesWinAnimationContentBigMiddle2 {
  width: 45px;
  height: 45px;
  bottom: -25px;
  left: 25px;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
}
.minesWinAnimationContentBigMiddle3 {
  width: 45px;
  height: 45px;
  top: 50px;
  left: -20px;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
}
.minesWinAnimationContentBigSmall1 {
  width: 35px;
  height: 35px;
  top: -60px;
  right: 50px;
  animation: rotation 3s linear infinite ;
}
.minesWinAnimationContentBigSmall2 {
  width: 35px;
  height: 35px;
  top: 0;
  left: -20px;
  animation: rotation 3s linear infinite ;
}
.minesWinAnimationContentBigSmall3 {
  width: 35px;
  height: 35px;
  bottom: 20px;
  left: -30px;
  animation: rotation 3s linear infinite ;
}
.minesWinAnimationContentBigSmall4 {
  width: 35px;
  height: 35px;
  bottom: 10px;
  right: 0;
  animation: rotation 3s linear infinite ;
}
.minesWinAnimationContentBigTiny {
  width: 30px;
  height: 30px;
  top: 100px;
  right: -40px;
  -moz-animation: coinPumping 0.7s ease-in-out infinite alternate;
  -webkit-animation: coinPumping 0.7s ease-in-out infinite alternate;
  -ms-animation: coinPumping 0.7s ease-in-out infinite alternate;
  -o-animation: coinPumping 0.7s ease-in-out infinite alternate;
  animation: coinPumping 0.7s ease-in-out infinite alternate;
}

@keyframes coinPumping {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -moz-transform: scale(1.41);
    -webkit-transform: scale(1.41);
    -ms-transform: scale(1.41);
    -o-transform: scale(1.41);
    transform: scale(1.41);
  }
}
@keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .minesWinAnimation {
    bottom: 5%;
    left: 50%;
  }
  .minesWinAnimationContent, .minesWinAnimationContentBig {
    width: 200px;
    height: 150px;
  }
  .minesWinAnimationContentItem1{
    height: 30px;
    p {
      font-size: 12px;
      line-height: 30px;
    }
  }
  .minesWinAnimationContentItem2 {
    height: 60px;
    font-weight: 800;
    font-size: 37px;
    line-height: 60px;
  }
  .minesWinAnimationContentItem3 {
    width: 130px;
    height: 38px;
  }
  .minesWinAnimationContentImageSmall {
    width: 20px;
    height: 20px;
  }
  .minesWinAnimationContentWinAmount {
    font-size: 11px;
    line-height: 38px;
  }
  .minesWinAnimationContentImageBig {
    width: 45px;
    height: 40px;
  }

  .minesWinAnimationContentBigWin{
    width: 200px;
    height: 200px;
    position: absolute;
    left: -2%;
    top: -20%;
    z-index: 1;
  }
  .minesWinAnimationContentBigItem1 {
    bottom: -20px;
    left: 37px;
    z-index: 2;
    height: 50px;
    width: 120px;
    p {
      font-size: 18px;
      line-height: 50px;
    }
  }
  .minesWinAnimationContentBigLarge {
    width: 40px;
    height: 40px;
  }
  .minesWinAnimationContentBigMiddle1 {
    width: 35px;
    height: 35px;
  }
  .minesWinAnimationContentBigMiddle2 {
    width: 25px;
    height: 25px;
    bottom: -25px;
    left: 25px;
  }
  .minesWinAnimationContentBigMiddle3 {
    width: 35px;
    height: 35px;
  }
  .minesWinAnimationContentShadow {
    position: absolute;
    z-index: 0;
    left: -50%;
    bottom: -60%;
    width: 400px;
    height: 400px;
  }
}
