.sliderWrap {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.sliderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0 15px;
}

.sliderHeaderTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    display: inline-block;
    width: 26px;
    margin: 0 10px 0 0;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #ffffff;
  }
}

.sliderHeaderBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.sliderBody {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.button {
  text-transform: uppercase;
}

@media (max-width: 550px) {
  .sliderHeaderTitle {
    img {
      width: 22px;
      margin: 0 8px 0 0;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
