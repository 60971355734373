.burgerElemWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    outline: none;
  }
}

.burgerElemContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.burgerElemIcon {
  display: inline-block;
  width: 22px;
  margin-right: 10px;
}

.burgerElemTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;

  color: #ffffff;
  cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}