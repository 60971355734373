.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  width: 100%;
  padding: 0 24px 28px;

  text-decoration: none;
  color: white;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: white;
  }
}

.imageBonusWrap {
  display: flex;
  position: relative;
  h3 {
    position: absolute;
    top: -2px;
    right: 5px;
    width: 20px;
    height: 20px;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    font-family: 'Montserrat';
    font-weight: 500;
    border-radius: 50%;
    background: linear-gradient(180deg,#0E9628 0%,#7DB30A 100%);
    text-align: center;
    margin: 0;
  }
}

.image {
  margin: 0 10px 0 0;
  height: 49px;
  width: auto;

  &:last-child {
    margin: 0;
  }
}

@media (max-width: 472px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 350px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
