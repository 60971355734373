.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}

.title {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  color: #7074A4;
  margin: 0 0 20px 0;
}

.list {
  li {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    color: #7074A4;
    margin: 5px 0;
    text-decoration: none;
  }
}

.description {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin: 30px auto;
  text-align: center;
  opacity: 70%;
  max-width: 800px;
}