.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin: 32px 0;

  color: white;
}

.totalWrapper {
  display: flex;
  align-items: center;
  margin: 0 0 24px;
}

.total {
  margin-right: 20px;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.reset {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;

  & .tooltip {
    z-index: 3;
    position: absolute;
    bottom: 120%;

    display: none;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 6px 12px;

    color: white;
    font-size: 13px;
    text-align: center;

    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    backdrop-filter: blur(10px);
    animation-duration: 200ms;
  }

  &:hover .tooltip {
    display: flex;
  }

  svg {
    width: 24px;
    height: auto;

    fill: rgba(255, 255, 255, 1);
  }

  &:hover svg {
    fill: rgba(255, 255, 255, 0.7);
  }
}

.loader {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.articles {
  display: grid;
  width: 100%;
  height: auto;

  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 24px;
}
