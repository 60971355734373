.wrapper {
  position: relative;
  z-index: 0;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 550px;
  padding: 10px 30px 30px;

  background-color: #242c45;
  border-radius: 20px;
}

.info {
  z-index: 2;

  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
}

.actions {
  z-index: 2;

  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 4;
  grid-row-end: 6;
}

.rewards {
  z-index: 2;

  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 6;
  grid-row-end: 7;
}

.wheel {
  z-index: 2;

  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 7;
}

.statistics {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  border-radius: 20px;

  overflow: hidden;
}

@media (max-width: 1100px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    padding: 10px;
    height: auto;
  }

  .info {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .wheel {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 6;
  }

  .actions {
    z-index: 2;

    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 7;
  }

  .rewards {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 8;
  }
}

@media (max-width: 540px) {
  .wrapper {
    height: 750px;
  }
}
