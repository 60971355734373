.label {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelText {
  margin: 0 0 0.5rem;

  color: #7074a4;
  font-weight: 600;
  font-size: 14px;

  cursor: pointer;
}

.select {
  width: 100%;

  font-size: 14px;
  line-height: 18px;

  .control {
    padding: 0 16px;

    background-color: rgba(7, 11, 22, 0.72);
    border-color: rgba(7, 11, 22, 0.72);
    border-width: 2px;
    border-radius: 18px;
    outline: none;

    &:hover {
      border-width: 2px;
      border-color: rgba(122, 44, 255, 0.7);
      box-shadow: none;
    }

    &.active {
      border-width: 2px;
      border-color: #7a2cff;
      box-shadow: none;
    }
  }

  .value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: white;
  }

  .placeholder {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .menu {
    padding: 6px;

    border-radius: 18px;
    background-color: rgba(7, 11, 22, 0.72);
  }

  .option {
    margin: 0 0 0.25rem;
    padding: 4px 18px;

    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: white;

    border-width: 1px;
    border-radius: 14px;
    background-color: inherit;

    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &.selected {
      background: rgb(32, 45, 74);
    }

    &:hover {
      background: rgba(32, 45, 74, 0.7);
    }
  }
}
