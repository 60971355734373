.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mainImage {
  z-index: 2;
  height: 240px;

  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.image {
  height: 100%;
  width: auto;
}

.winInfo {
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 224px;
  padding: 14px 0;
  margin: -34px 0 0;

  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center top;
}

.text {
  margin: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #c5d5ff;
}

.amount {
  margin: 0;

  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}

@media (max-width: 1100px) {
  .mainImage {
    height: 200px;
  }

  .winInfo {
    z-index: 3;
    width: 174px;
    padding: 8px 0;
    margin: -54px 0 0;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
  }

  .amount {
    font-size: 20px;
    line-height: 20px;
  }
}
