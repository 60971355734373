.wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 30px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;

  h3 {
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 36px;
    color: #ffffff;
  }

  p {
    margin: 0 0 10px;

    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .hash {
    display: flex;
    width: 100%;
  }

  .hash .hashTitle {
    margin-right: 1rem;
    min-width: 155px;
  }

  .hash .hashTitle p {
    margin: 8px 0 0;
    padding: 0.3rem 0;
  }

  .hash .hashContent {
    flex-grow: 1;
    width: 50%;
  }

  div.hashText {
    box-sizing: border-box;
    display: flex;
    align-items: center;

    padding: 0.3rem 0.6rem;
    margin: 5px 0 0 0;
    width: 100%;

    border: 2px solid #3757dd;
    border-radius: 14px;
  }

  div.hashText p {
    margin: 0;

    color: #ffffff;
    font-size: 0.8rem;
    font-family: "Montserrat";
    font-weight: 600;
    line-height: 17px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div.hashText button {
    margin-left: auto;
  }

  .bold {
    font-weight: bold;
  }

  .lastParagraph {
    margin: 0 0 24px;
  }
}
