.wrapper {
  display: flex;
  flex-direction: column;
  background: #202D4A;
  border-radius: 20px;
  padding: 16px;
  position: relative;
  flex-grow: 3;
  min-width: 500px;
  min-height: 480px;
  overflow: hidden;
}

.icon {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: 100%;
  max-width: 605px;
}

.header {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 3;
  h3 {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
  }
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
  z-index: 1;
}

.pool {
  display: flex;
  padding: 16px;
  flex-direction: column;
  border: 4px solid #7074A4;
  border-radius: 16px;
  width: 100%;
  max-width: 580px;
  p{
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.poolRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0;

  img {
    width: 50px;
    height: auto;
    margin-right: 3px;
  }
  h2 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 700;
    font-size: 40px;
  }
}

.totalHeader {
  display: flex;
  align-items: center;
  gap: 16px;
  h4 {
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.totalTimer {
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: auto;
    margin-right: 5px;
  }
}

.currencyWrap{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 25px;
  margin-bottom: 30px;

  button {
    background: none;
    border: none;
    color: #FCCD00;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
}

.currenciesList {
  display: flex;
  align-items: center;
  margin-right: 4vw;

  img {
    display: inline-block;
    width: 45px;
    height: auto;
    margin-left: -12px;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
}

.paid {
  display: flex;
  width: 100%;
  max-width: 580px;
  background: #040C1B80;
  border-radius: 10px;
  padding: 16px;
  position: relative;
  flex-direction: column;
  margin-top: 25px;

  p{
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.paidAmount {
  display: flex;
  align-items: center;
  z-index: 2;

  img {
    width: 35px;
    height: auto;
  }
  h3 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 400;
    font-size: 24px;
  }
}

.paidIcon {
  position: absolute;
  right: -2%;
  bottom: -17%;
  z-index: 1;
  width: auto;
  height: 100%;
}


@media (max-width: 860px) {
  .body {
    grid-template-columns: 1fr;
  }
  .pool {
    width: 100%;
  }
  .poolRow {
    margin: 16px 0;
    img {
      width: 40px;
      margin-right: 0;
    }
    h2 {
      font-size: 26px;
    }
  }
  .currencyWrap{
    margin-top: 16px;
    margin-bottom: 0;
  }
}

@media (max-width: 640px) {
  .wrapper {
    min-width: 100%;
  }
  // .icon {
  //   display: none;
  // }
  .totalHeader{
    flex-wrap: wrap;
  }
  .totalTimer {
    img {
      width: 19px;
      margin-right: 3px;
    }
  }
  .currencyWrap{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .currenciesList {
    margin-right: 0;
  }
  .paid {
    min-height: 132px;
  }
  .paidIcon {
    position: absolute;
    right: -4%;
    bottom: -17%;
    z-index: 1;
    width: auto;
    height: 100%;
  }
}