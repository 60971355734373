.button {
  position: absolute;
  z-index: 3;
  right: 44px;
  top: 10px;

  border: none;
  background: none;

  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.icon {
  height: 14px;
  width: auto;
}
