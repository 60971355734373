.image {
  align-self: center;
  margin: 0 0 20px;

  max-width: 300px;
  width: 100%;
  height: auto;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #c1c5fb;
}

.game {
  ol,
  ul {
    padding: 0 1.5rem;
  }

  li {
    margin: 0 0 6px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #c1c5fb;

    &:last-child {
      margin: 0;
    }
  }
}
