.minesDiamondBombItemWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.minesDiamondBombItemImage {
  display: inline-block;
  width: 50%;
}

.minesDiamondBombItemDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h5, h6 {
    color: #ffffff;
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    line-height: 28px;

    text-align: center;
    letter-spacing: 0.12px;
    text-transform: uppercase;
  }

  h5 {
    font-weight: 400;
    font-size: 20px;
    margin: 20px 0 20px;
  }

  h6 {
    font-weight: 800;
    font-size: 28px;
  }
}



@media (max-width: 1300px) {
  .minesDiamondBombItemDescription {
    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 18px;
    }
  }
}
@media (max-width: 1150px) {

}
@media (max-width: 1100px) {

  .minesDiamondBombItemDescription {
    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 28px;
    }
  }
}
@media (max-width: 1024px) {
  //img {
  //  width: 108px;
  //  height: 108px;
  //}
  .minesDiamondBombItemDescription {
    h5 {
      font-size: 18px;
    }
    h6 {
      font-size: 28px;
    }
  }
}
@media (max-width: 900px) {
}
@media (max-width: 768px) {
  .minesDiamondBombItemWrap {
    display: none;
  }
}
@media (max-width: 640px) {
}
@media (max-width: 580px) {
}
@media (max-width: 500px) {
}
@media (max-width: 400px) {
}
