.signConfirmWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
}

.signConfirmTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
}

.signConfirmFormWrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.signConfirmForm {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 15px 0 0 0;
  }
}

.signConfirmFormText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #888aa7;
  margin: 20px 0 0;
  display: flex;
  width: 288px;
  text-align: left;
}

.signConfirmFormInputWrap {
  margin: 30px 0;
  position: relative;
  width: 100%;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.signConfirmFormInput {
  background: #00000033;
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid #313e57;
  uotline: none;
  border-radius: 9px;
  margin: 0 5px;
  text-align: center;
  &:focus {
    border: 2px solid #442A81;
    outline: none;
  }
  &:hover {
    border: 2px solid #442A81;
    outline: none;
  }
  input {
    outline: none;
  }
}

.signConfirmFooter {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px;
  position: relative;
  span{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0 10px 0 0;
  }
}

.signConfirmFooterTooltip {
  position: absolute;
  left: 18.7%;
  width: 288px;
  height: 67px;
  border-radius: 19px;
  padding: 0 15px;
  background: black;
  visibility: hidden;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
  transition-duration: 100ms;
  transition-delay: 100ms;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 11px;
  color: #ffff;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.signConfirmFooterTooltip.signConfirmFooterTooltipOpen {
  visibility: visible;
  transform: translateY(-100%);
}

.signConfirmBtnWrap {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffb22d;
  margin-top: auto;
}

.signConfirmBtn1 {
  color: #ffb22d;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ffb22d;
  margin-left: 5px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

@media (max-width: 640px) {
  .signConfirmFooterTooltip {
    left:10%;
  }
}
