.minesTablesWrap {
  max-width: 1339px;
  width: 100%;
  height: 100%;
  margin: 30px 0;
  position: relative;
  color: white;
}

.minesTableMyBetsWrap {
  display: flex;
  width: 100%;
  margin: 30px 0;
}
