.body {
  width: 100%;
}

.emptyRow {
  position: relative;
}

.emptyCell {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
  width: 100%;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6987c7;
}
