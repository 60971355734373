.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.gradient {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 330px;

  background: radial-gradient(100% 100% at 50% 0%, #6f492d 0%, rgba(111, 73, 45, 0) 100%);
}

.info {
  position: absolute;
  z-index: 8;
  top: 18px;
  right: 60px;

  width: 14px;
  height: 14px;

  fill: white;
  filter: brightness(2);
  transition: all 0.1s ease-in-out;

  cursor: pointer;

  &:hover {
    fill: rgba(255, 255, 255, 0.8);

    transition: all 0.1s ease-in-out;
  }
}

.content {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;

  padding: 30px 0;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 14px;
}

.title {
  margin: 0 12px 0 0;

  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.18px;
  color: #ffffff;
}

.percent {
  padding: 6px 12px;

  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 1.1px;
  color: #ffffff;

  background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
  border-radius: 19px;

  cursor: default;

  &::selection {
    pointer-events: none;
  }
}

.image {
  height: 300px;
  width: auto;
  margin: 0 0 14px;

  &::selection {
    pointer-events: none;
  }
}

.currencies {
  width: 100%;
  margin: 0 0 14px;
}

.error {
  margin-bottom: 14px;

  font-weight: 400;
  font-size: 12px;
  color: red;
}

.collect {
  width: 100%;
}

.desc {
  margin: 0 auto 14px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.button {
  text-transform: uppercase;
}

@media (max-width: 400px) {
  .content {
    width: 80%;
  }

  .title {
    font-size: 30px;
    line-height: 37px;
  }

  .percent {
    padding: 4px 8px;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .desc {
    font-size: 14px;
    line-height: 18px;
  }
}
