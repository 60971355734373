.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.row {
  display: flex;
  align-items: center;
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.combination {
  margin: 0 0 0 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fccd00;
}

.cards {
  position: relative;

  height: 100px;
}

.card {
  height: 100px;
  width: auto;
}

.kicker {
  padding: 0 2px;
  margin: 0 0 0 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  background: #5942ee;
}

@media (max-width: 540px) {
  .wrapper {
    margin: 0 0 15px;
  }
}
