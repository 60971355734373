.levelWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  background: linear-gradient(0deg, rgba(32, 45, 74, 1) 50%, rgba(96, 32, 161, 1) 100%);
  padding: 30px;

  font-family: 'Montserrat';
}

.levelUpTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 43px;
  letter-spacing: 0.18px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.levelSubtitle {
  width: 100%;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #a2a5cc;
  margin: 0;
}

.levelSubtitle:nth-last-of-type(1) {
  margin-bottom: 30px;
}

.levelContent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.levelImageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 50%;
  flex-direction: column;
}

.levelImage {
  display: inline-block;
  height: 200px;
  margin-bottom: 30px;
}

.levelImageRankWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #fccd00;
    padding: 5px 7px;
    background: linear-gradient(180deg, rgba(214, 145, 21, 0.4) 0%, rgba(214, 145, 21, 0.16) 100%), #424862;
    border-radius: 4px;
  }
  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #795620;
    margin: 0 0 0 3px;
    padding: 5px 7px;
    background: linear-gradient(90deg, #ffffb2 0%, #ebbb2e 100%), #424862;
    border-radius: 4px;
  }
}

.levelDescriptionWrap {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #c1c5fb;
    margin: 0 0 15px 0;
  }
}

.levelDescription1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 0 0 5px;
  padding: 8px 10px;
  background: #161e31;
  border-radius: 8px;
  img {
    display: inline-block;
    width: 19px;
    margin: 0;
  }
  h4 {
    margin: 0 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #c1c5fb;
  }
  h5 {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
}

.levelDescription2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 15px 0 0;
  h2 {
    margin: 0 !important;
  }
}

.button {
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .levelUpTitle {
    font-size: 22px;
    line-height: 26px;
  }
  .levelContent {
    flex-direction: column;
  }
  .levelImageWrap {
    width: 100%;
  }
  .levelDescriptionWrap {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}
