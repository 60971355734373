.lotteryMobileWrap {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 30px;
  background: #242C45;
  mix-blend-mode: normal;

  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.lotteryMobileImg {
  display: inline-block;
  width: 45px;
}

.lotteryMobilePrize {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  img {
    display: inline-block;
    width: 45px;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    margin: 0;

    color: #FFFFFF;
  }
}

.lotteryMobileButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  button {
    width: 140px;
    height: 40px;
    border: none;
    outline: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 1.1px;
    text-transform: uppercase;

    color: #FFFFFF;

    background: linear-gradient(180deg, #EAB62F 0%, #F7931A 100%);;
    box-shadow: -7.9602e-16px 0px 10px rgba(247, 147, 26, 0.75);
    border-radius: 19px;
    cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

    &:hover {
      cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
      box-shadow: -7.9602 e-16px 0px 15px rgba(247, 147, 26, 0.75);
    }

    &:active {
      box-shadow: 4px 4px 8px 0px rgba(63, 80, 34, 0.2);
    }
  }
}

@media (max-width: 1260px) {
  .lotteryMobileWrap {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .lotteryMobileWrap {
    padding: 0 15px;
    height: 30px;
  }
  .lotteryMobileImg {
    width: 25px;
  }
  .lotteryMobilePrize {
    img {
      width: 25px;
    }
    p {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .lotteryMobileButton {
    button {
      width: 100px;
      height: 24px;
      font-size: 11px;
    }
  }
}

@media (max-width: 400px) {
  .lotteryMobileImg {
    width: 20px;
  }
  .lotteryMobilePrize {
    img {
      width: 25px;
    }
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .lotteryMobileButton {
    button {
      width: 80px;
      height: 24px;
      font-size: 10px;
    }
  }
}
