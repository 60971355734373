.kenoTablesWrap {
  width: 100%;
  margin: 30px 0;
  position: relative;
  color: white;
}

.kenoTableMyBetsWrap {
  display: flex;
  width: 100%;
  margin: 30px 0;
}
