.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 30px 0;
  justify-content: center;
  align-items: center;
}

.bg {
  display: inline-block;
  width: 100%;
  object-fit: cover;
}

.para {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat;
  color: #ffffff;
  span {
    font-weight: 600;
    font-size: 14px;
    font-family: Montserrat;
    text-transform: uppercase;
    color: #888aa7;
  }
}

.winWrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 70%;
  background-image: url("../../../../../assets/img/Plinko/plinko-win.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  h3 {
    font-weight: 500;
    font-size: 18px;
    font-family: Montserrat;
    color: #ffffff;
    margin: 0 0 10px;
  }
  img {
    display: inline-block;
    width: 25px;
  }
  p {
    font-weight: 500;
    font-size: 10px;
    font-family: Montserrat;
    color: #ffffff;
    margin: 0;
  }
}

.amountWrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}