.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1264px;
  margin: 0 0 40px;
}

.title {
  margin: 0 0 32px;

  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
}

.nav {
  width: 80%;
  margin: 0 0 40px;
}

.categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.category {
  display: flex;
  align-items: center;
  padding: 12px 20px;

  border-radius: 8px;
  background: #202d4a;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &.active {
    background: rgb(56, 86, 221);
    background: linear-gradient(135deg, rgba(56, 86, 221, 1) 0%, rgba(122, 44, 255, 1) 100%);
  }
}

.icon {
  margin: 0 10px 0 0;

  height: 38px;
  width: auto;
}

.text {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  color: #ffffff;
}

.bonuses {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 80%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.bonus {
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  padding: 16px;

  border-radius: 16px;
  background: #202d4a;

  overflow: hidden;
}

.bright {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-image: url('../../../../../assets/img/Vip/bright-benefits.webp');
  background-position: top center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.content {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.bonusIcon {
  height: 180px;
  width: auto;
}

.bonusTitle {
  margin: 30px 0 10px;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  color: #ffffff;
}

.desc {
  margin: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;

  color: #c1c5fb;
}

.actionButton {
  display: flex;
  align-items: center;
  margin: 14px 0 0;
  padding: 4px 12px;

  color: #ffffff;
  font-size: 13px;
  font-weight: 500;

  border: none;
  border-radius: 19px;
  background: #1a92d1;

  &:hover {
    box-shadow: 0 0 8px rgba(26, 146, 209, 0.75);
  }

  &:active {
    opacity: 0.8;
  }
}

.actionIcon {
  width: 20px;
  height: auto;
  margin: 0 10px 0 0;

  fill: #ffffff;
}

@media (max-width: 1000px) {
  .bonusIcon {
    margin: 20px 0 0;

    height: 140px;
  }
}

@media (max-width: 950px) {
  .nav {
    width: 100%;
  }

  .bonuses {
    max-width: 100%;
  }
}

@media (max-width: 650px) {
  .icon {
    height: 28px;
    margin: 0 6px 0 0;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
  }

  .bonuses {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 550px) {
  .category {
    flex-direction: column;
    padding: 6px 8px;
  }

  .icon {
    height: 48px;
    margin: 0 0 10px;
  }
}

@media (max-width: 400px) {
  .text {
    font-size: 10px;
    line-height: 14px;
  }
}
