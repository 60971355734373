.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.block {
  height: 48px;
  padding: 16px 20px;
  background: #161E31;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: url("../../../../../../../assets/img/common/cursor-pointer.webp"), auto;
  img {
    display: inline-block;
    width: 100%;
  }
}

.block.active {
  background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);

}