.authUser {
  display: flex;
  width: 189px;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 700;
    font-size: 13px;
    color: white;
    margin-bottom: 5px;
    margin-left: 10px;
  }

  h3 {
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    color: #52c330;
    text-transform: uppercase;
    margin-top: -5px;
  }
}

.authUserDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 5px 0 0;

  h3 {
    text-align: end;
    width: 120px;
    position: relative;
  }
}

.authUserBorderImg {
  display: flex;
  width: 42px;
  height: 40px;
  background: linear-gradient(132.79deg, #4513b6 15.16%, #e01f48 85.97%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: ease-in-out;

  &:hover {
    box-shadow: 0 0 6px 1px rgba(233, 244, 36, 0.99);
    transform: scale(1.07);
  }
}

.authUserAvatar {
  width: 40px;
  height: 38px;
  cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  border-radius: 50%;
}

.authUserCoin {
  width: 12px;
  margin: 0 3px 3px 0;
}

@media (max-width: 1024px) {
  .authUser {
    display: none !important;
  }
}