.place {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.image {
  width: 24px;
  height: 24px;
}

.text {
  margin: 0 0 0 6px;
}
