.progressBarCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #FFFFFF;
  }
}
