.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.tableWrapper {
  position: relative;
  min-height: 350px;
  max-width: 100%;

  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #50566a;
    -webkit-border-radius: 1ex;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.table {
  width: 100%;
  min-width: 600px;

  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

.pagination {
  width: auto;
  margin-bottom: 0;
}
