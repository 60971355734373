.wrapper {
  display: flex;
  width: 100%;
  height: 38px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  background: #00000033 !important;
  border-radius: 24px;
  border: none;
  position: relative;
}
.wrapper.disabled {
  pointer-events: none;
}

.header {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 24px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  cursor: url("../../../../../assets/img/common/cursor-pointer.webp"), auto !important;

  &:hover {
    outline: none !important;
    border: 2px solid #6c5dd3;
    box-shadow: none !important;
  }
  &:focus {
    outline: none !important;
    border: 2px solid #6c5dd3;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    border: 2px solid #6c5dd3;
    box-shadow: none !important;
  }
  &:disabled {
    pointer-events: none;
  }
}

.body {
  visibility: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 0;
  background-color: #000000;
  transform: translateY(-100%);
  transition: all 0.1s ease-in-out;
  transition-duration: 000ms;
  transition-delay: 000ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  margin-top: 10px;
  border-radius: 24px;
  opacity: 0.9;

  position: absolute;
  top: 0;

  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 10px solid transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    background: #000;
  }

  &::-webkit-scrollbar-thumb {
    background: #50566a;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  &::-webkit-scrollbar-corner {
    background: #000;
  }
}

.body.open {
  visibility: visible;
  transform: translateY(-110%);
  transition: all 0.1s ease-in-out;
  transition-duration: 100ms;
  transition-delay: 100ms;
  z-index: 20;
  padding: 0 5px;

  height: auto;
  scrollbar-width: thin;
}

.arrowDrop {
  border: 1px solid #888aa7;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 3px;
  margin-right: 30px;
  position: absolute;
  top: 12px;
  right: -8px;
  cursor: url("../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  transition: all 0.3s ease-in-out;
}

.arrowDrop.up {
  margin-top: 3px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}
.arrowDrop.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border: 1px solid #888aa7;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  cursor: url("../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  margin-bottom: 3px;
}

.titleItem {
  margin: 5px 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  text-align: left;
  cursor: url("../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  &:hover {
    color: #ffa608;
  }
}

