
.dividendsTimer {
  left: 35px;
  top: 21%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  letter-spacing: 2.286px;

  color: #FFFFFF;
  min-width: 100px;
  text-align: end;
}

@media (max-width: 640px) {
  .dividendsTimer {
    font-size: 20px;
    min-width: 80px;
  }
}
