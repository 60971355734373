.wrapper {
  width: 100%;

  border-bottom: 1px solid #888aa7;

  &:first-child .titleWrapper {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
  }

  overflow: hidden;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;

  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;

  &::selection {
    pointer-events: none;
  }
}

.iconWrapper {
  padding: 0 12px;
}

.icon {
  width: 8px;
  height: auto;

  fill: white;

  transform: rotate(90deg);

  &.open {
    transform: rotate(270deg);
  }
}

.body {
  max-height: 0;

  &.open {
    max-height: 500px;
    margin: 0 0 12px;

    transition: max-height 0.5s ease-in-out;
  }

  & > * {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #c1c5fb;
  }
}
