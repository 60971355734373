.minesRiskAutoWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  grid-gap: 15px;
  align-items: center;
  min-height: 38px;
}

.minesRiskDropWrap {
  display: flex;
  width: auto;
  flex-grow: 1;
}

.minesCheckboxAutoWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  h4 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    color: #FFFFFF;
  }
}

@media (max-width: 640px) {
  .minesRiskAutoWrap {
    order: 2;
  }
}
