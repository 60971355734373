.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.gradient {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 330px;

  background: radial-gradient(
    100% 100% at 50% 0%,
    #6f492d 0%,
    rgba(111, 73, 45, 0) 100%
  );
}

.back {
  position: absolute;
  z-index: 8;
  top: 13px;
  left: 20px;

  width: 24px;
  height: 24px;

  fill: white;
  filter: brightness(2);
  transition: all 0.1s ease-in-out;

  cursor: pointer;

  &:hover {
    fill: rgba(255, 255, 255, 0.8);

    transition: all 0.1s ease-in-out;
  }
}

.content {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  padding: 30px 0 0;
}

.title {
  margin: 0 0 12px;

  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.18px;
  color: #ffffff;
}

.desc {
  margin: 0 0 12px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
}

.tableWrapper {
  padding: 0 0 30px;
}

.tableTitle {
  margin: 24px 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.ranks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0 10px;

  list-style-type: none;
}

.rank {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}

.percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3px;
  padding: 0;
  width: 100%;
  height: 34px;

  background: rgba(7, 11, 22, 0.72);
  border-radius: 8px;
}

.text {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #C1C5FB;
}