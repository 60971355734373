.wrapper {
  z-index: 10;

  position: absolute;
  top: 70px;
  right: 0;

  padding: 24px 16px;
  width: 438px;

  font-family: 'Montserrat';
  font-style: normal;
  color: white;

  background: rgba(7,11,22,0.92);
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.title {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.button {
  display: flex;
  align-items: center;

  border: none;
  background: none;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.buttonIcon {
  margin: 0 8px 0 0;
}

.buttonText {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: white;

  &::selection {
    pointer-events: none;
  }
}

.clearAllWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin: 10px 0;
}


.notificationsContainer {
  width: 100%;
  max-height: 300px;

  overflow: hidden;
  overflow-y: auto;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #50566a;
    border-radius: 1px;
    border-right: 13px transparent solid;
    border-top: 8px transparent solid;
    border-bottom: 8px transparent solid;
    background-clip: padding-box;
  }
}

.date {
  margin: 0;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #A2A5CC;
  text-transform: capitalize;
}

.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.empty {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #A2A5CC;
}
