.list {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 34px 24px;
  margin: 0;

  background: rgba(0, 0, 0, 0.7);

  list-style-type: none;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 32px;
}

.title {
  margin: 0 0 16px;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px;
}

.card {
  width: 17%;
  height: auto;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #c1c5fb;
}
