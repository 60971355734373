.pageWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 75px);

  font-family: "Montserrat";

  background: url("../../../assets/img/NotFound/404.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: max-content;
  width: max-content;
}

.planetWrapper {
  position: relative;
  width: 300px;
  height: 280px;
}

.asteroidsWrapper {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;

  width: 140%;
  height: auto;

  transform: translate(-50%, -50%);
}

.asteroids {
  width: 100%;

  animation: rotate 30s linear infinite;
}

.number {
  z-index: 2;
  position: absolute;
  top: 50%;

  width: 60%;
  height: auto;
  transform: translateY(-50%);
}

.firstNumber {
  left: -50%;
}

.lastNumber {
  right: -50%;
}

.planet {
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 50%;

  width: 100%;
  height: auto;

  transform: translate(-50%, -50%);
}

.text {
  margin-top: 6rem;
  width: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.38px;
  color: #ffffff;
}

.button {
  margin-top: 0.5rem;
  button {
    text-transform: uppercase;
  }
}

@media (max-width: 1260px) {
  .pageWrapper {
    height: calc(100vh - 135px);
  }
}

@media (max-width: 1250px) {
  .planetWrapper {
    width: 280px;
    height: 260px;
  }
}

@media (max-width: 1150px) {
  .planetWrapper {
    width: 260px;
    height: 240px;
  }
}

@media (max-width: 1050px) {
  .planetWrapper {
    width: 240px;
    height: 220px;
  }
}

@media (max-width: 850px) {
  .planetWrapper {
    width: 200px;
    height: 180px;
  }
}

@media (max-width: 750px) {
  .pageWrapper {
    background: url("../../../assets/img/NotFound/404-mobile.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .text {
    width: 300px;

    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 450px) {
  .planetWrapper {
    width: 150px;
    height: 130px;
  }

  .text {
    width: 240px;

    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 300px) {
  .planetWrapper {
    width: 120px;
    height: 100px;
  }

  .text {
    width: 240px;

    font-size: 18px;
    line-height: 28px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
