.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: #202D4A;
}

.icon {
  display: inline;
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.titleWrap {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 8px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px;

  h4 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
}

.bodyItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 25px;

  p {
    color: #ffffff;
    
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 400;
    font-size: 24px;
  }

  img {
    width: 50px;
    height: auto;
  }
}

.buttonWrap {
  display: flex;
  width: 100%;
  // height: 70px;
  align-items: flex-end;
  justify-content: flex-end;
}