.inputCustomWrapper {
  height: 38px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: url("../../../assets/img/common/cursor-pointer.webp") auto !important;

  input {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    line-height: 20px;
    box-sizing: border-box;
    background: #00000033;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
  }

  input:focus {
    outline: none;
    border: 2px solid #6c5dd3;
    border-radius: 19px;
    background-color: #00000033;
    color: #ffffff;
    box-shadow: none;
  }

  input::placeholder {
    color: #3c516a;
  }
}

.inputError {
  border: 2px solid #f02f41;
  filter: drop-shadow(0px 0px 1px rgba(240, 47, 65, 0.5));
  border-radius: 19px;
}