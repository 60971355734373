.bonusCollectWrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(179, 98, 3, 0.46) -25.56%, rgba(255, 212, 59, 0) 100%, #242c45 100%);
  padding: 30px;
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -1px;
    text-transform: uppercase;

    color: #eab52d;
  }
  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 10px 0 30px;
    color: #ffffff;
    text-align: center;
  }
  h4 {
    text-align: center;
    border: 1px solid #424862;
    padding: 16px 26px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 40px;

    color: #ffffff;

    img {
      width: 35px;
      margin-bottom: 2px;
    }
  }
}

.bonusCollectImg {
  display: inline-block;
  width: 50%;
  margin: 50px 0 30px;
}

.button {
  text-transform: uppercase;
}
