.wrapper {
  display: flex;
  flex-direction: column;
  width: 325px;
  padding: 18px 24px;

  background: rgba(7, 11, 22, 0.72);
  backdrop-filter: blur(4px);
  border-radius: 16px;
}

.mobile {
  z-index: 5;
  position: absolute;
  left: 20px;
  top: 20px;

  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 14px;
}

.title {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.tooltip {
  width: max-content;
  margin: 0 14px 0 0;

  &:last-child {
    margin: 0;
  }
}

.button {
  border: none;
  background: none;
  outline: none;

  svg {
    opacity: 1;
    transition: all 0.1s ease-in-out;
  }

  &:hover svg {
    opacity: 0.7;
    transition: all 0.1s ease-in-out;
  }
}

.icon {
  width: 14px;
  height: auto;

  fill: white;
}

.buttons {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .mobile {
    left: 4px;
    top: 4px;
  
    width: calc(100% - 8px);
    height: calc(100% - 8px);
  }
}