.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.withBonus {
  margin-bottom: 80px;
}

.select {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #ffffff;

  .control {
    position: relative;
    padding: 0 0 0 20px;

    background-color: rgba(7, 11, 22, 0.72);
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 2px;
    border-radius: 18px;
    outline: none;

    &:hover {
      border-width: 2px;
      border-color: rgba(122, 44, 255, 0.7);
      box-shadow: none;
    }

    &.active {
      border-width: 2px;
      border-color: #7a2cff;
      box-shadow: none;
    }
  }

  .value {
    width: 22px;

    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #ffffff;
  }

  .placeholder {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .menu {
    padding: 6px;

    border-radius: 18px;
    background-color: rgba(7, 11, 22, 0.72);
  }

  .option {
    display: flex;
    align-items: center;

    margin: 0 0 0.25rem;
    padding: 4px 18px 4px 25px;

    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: white;

    border-width: 1px;
    border-radius: 14px;
    background-color: inherit;

    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &.selected {
      background: rgb(32, 45, 74);
    }

    &:hover {
      background: rgba(32, 45, 74, 0.7);
    }
  }

  .indicatorsContainer {
    padding: 0 5px 0 0;
  }
}

.iconWrapper {
  position: absolute;
  left: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;

  border-radius: 100%;
  overflow: hidden;
}

.icon {
  height: 100%;
  width: auto;
}

@media (max-width: 1024px) {
  .withBonus {
    margin-bottom: 10px;
  }
}
