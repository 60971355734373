.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  overflow: hidden;

  span {
    display: block;

    font-weight: 700;
    font-size: 200px;
    line-height: 200px;
    letter-spacing: 0.09px;
    text-align: center;

    background: -webkit-linear-gradient(#ffffff 100%, #c4c4c4 100%, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #ffffff;
    -webkit-text-stroke-width: 2px;

    text-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);

    &.win {
      background: -webkit-linear-gradient(#78c613 100%, #49730a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-color: #97ec15;
      -webkit-text-stroke-width: 2px;
    }

    &.lose {
      background: -webkit-linear-gradient(#E41717 60%, #6C0707 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-color: #b32162;
      -webkit-text-stroke-width: 2px;
    }
  }
}

.hidden {
  visibility: hidden;
}

.beforeAnimation {
  transform: translateY(110%);
  transition: all 0s ease-in-out;
  transition-delay: 0ms;
  transition-duration: 200ms;
}

.animation {
  animation-name: roll;
  animation-timing-function: linear;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

.afterAnimation {
  transform: translateY(-300%);
}

.stop {
  transform: translateY(0%);
  transition: all 0s ease-in-out;
  transition-delay: 0ms;
  transition-duration: 200ms;
}

.withoutAnimation {
  transition: none;
}

@keyframes roll {
  0% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    transform: translateY(100%);
  }
  10% {
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    transform: translateY(-100%);
  }
  95% {
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    transform: translateY(-5000%);
  }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    transform: translateY(-6000%);
  }
}

@media (max-width: 700px) {
  .wrapper {
    span {
      font-size: 140px;
      line-height: 140px;
    }
  }
}

@media (max-width: 640px) {
  .wrapper {
    span {
      font-size: 70px;
      line-height: 70px;
    }
  }
}

@media (max-width: 400px) {

}
