.wrapper {
  position: relative;

  display: flex;
  align-items: center;
  margin: 0 0 0 2rem;
}

.divider {
  height: 16px;
  border-left: 2px solid #313e57;
}

.notifyWrapper {
  display: flex;
  padding: 1rem;
}

.button {
  position: relative;

  padding: 0;
  margin: 0;

  border: none;
  background: none;

  outline: none;
}

.icon {
  width: 26px;
}

.animation {
  animation: ring 2s 0s ease-in-out;
}

.dot {
  position: absolute;
  right: 2px;
  top: 2px;
  
  width: 8px;
  height: 8px;

  background: red;
  border-radius: 100%;

  &::before,
  &::after {
    content: '';

    display: block;
  }

  &:before {
    position: relative;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: red;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%;
    height: 100%;
    background-color: red;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@media (max-width: 1450px) {
  .icon {
    width: 24px;
  }
}

@media (max-width: 1380px) {
  .icon {
    width: 20px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    display: none;
  }
}

@keyframes ring {
  0% { transform: rotate(0); }
  9% { transform: rotate(50deg); }
  18% { transform: rotate(-45deg); }
  27% { transform: rotate(40deg); }
  36% { transform: rotate(-35deg); }
  45% { transform: rotate(30deg); }
  54% { transform: rotate(-25deg); }
  63% { transform: rotate(20deg); }
  72% { transform: rotate(-15deg); }
  81% { transform: rotate(10deg); }
  90% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}