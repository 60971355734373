.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;

  font-family: 'Montserrat';
}

.gameWrapper {
  display: flex;
  width: 100%;
}

.dice {
  display: flex;
  flex-direction: column;
  width: calc(100% - 375px);
  min-width: calc(100% - 375px);
  padding: 0 30px;
}

.gameHeader {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(160px, 100%) minmax(min-content, auto);
  grid-column-gap: 15px;
  margin: 15px 0;
}

.lottery {
  min-width: 345px;
  height: 100%;
  margin: 0 30px 0 0;
}

.autoSettings {
  width: calc(100% - 375px);
  padding: 0 30px;
}

@media (max-width: 1260px) {
  .dice {
    width: 100%;
    min-width: 100%;
  }

  .autoSettings {
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 640px) {
  .dice {
    padding: 0 15px;
  }
  .gameHeader {
    margin: 10px 0;
  }
  .autoSettings {
    padding: 0 15px;
  }
}
