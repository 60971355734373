.limboSubmitButtonWrap {
  display: flex;
  flex-grow: 4;
  min-width: 130px;
  height: 91px;
}

.limboSubmitButton{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: #FFFFFF;
}

.limboSubmitButtonText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.09px;
  color: #FFFFFF;
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 1400px) {
  .limboSubmitButtonText {
      font-size: 13px;
  }
}
@media (max-width: 1250px) {
  .limboSubmitButtonText {
      font-size: 11px;
  }
}
@media (max-width: 1100px) {
  .limboSubmitButtonText {
      font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .limboSubmitButtonText{
      font-size: 14px;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 768px) {
}
@media (max-width: 680px) {
  .limboSubmitButtonText {
      font-size: 12px;
  }
}

@media (max-width: 640px) {
  .limboSubmitButtonWrap {
    height: 60px;
    margin: 0 0 15px!important;
    //width: 100%;
  }
  .limboSubmitButtonText{
      font-size: 14px;
      line-height: 20px;
  }
}
