.wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 10px;
  margin: 5px 0 15px;

  color: #ffffff;

  background: rgba(7, 11, 22, 0.72);
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 12px;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.icon {
  height: 16px;
  width: auto;
  margin-right: 8px;
}

.title {
  margin: 0;

  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
}

.desc {
  margin: 10px 0;

  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
}