.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    margin: 0 10px 0 0;

    color: #888aa7;
    font-size: 18px;
    line-height: 20px;
    display: block;
  }

  p {
    margin: 0;

    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
  }
}

.div {
  height: 20px;

  border-left: 1px solid #424862;
}

.payout {
  padding: 0;
  margin: 0;

  p {
    text-align: right;
    width: 190px;
  }
}

@media (max-width: 1400px) {
  .wrapper {
    & > div {
      flex-direction: column;
    }

    span {
      margin: 0;
      font-size: 14px;
    }

    p {
      height: 22px;
      vertical-align: middle;
      font-size: 14px;
    }
  }

  .payout {
    width: 150px;

    p {
      text-align: center;
      width: max-content;
    }
  }
}

@media (max-width: 640px) {
  .wrapper {
    margin: 0 0 5px;
  }
}

@media (max-width: 570px) {
  .wrapper {
    span {
      font-size: 11px;
    }

    p {
      font-size: 11px;
    }
  }

  .payout {
    width: 120px;
  }
}

@media (max-width: 420px) {
  .wrapper {
    span {
      font-size: 9px;
    }

    p {
      font-size: 9px;
    }
  }

  .payout {
    width: 100px;
  }
}
