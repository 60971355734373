.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  margin: 32px 0;
  padding: 40px 32px 28px;

  color: white;

  background: #202d4a;
  border-radius: 16px;
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);

  list-style-type: none;
}
