.provablyFairWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 350px;
}

.provablyFairTitle {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 36px;
  color: rgb(255, 255, 255);
  margin-top: 10px;
}

.provablyFairBody {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px;
  font-family: 'Montserrat';
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  p{
    margin: 0 0 8px 0;
    font-weight: 300;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .provablyFairBody {
    padding: 0 20px;
    p{
      font-size: 12px;
      margin-bottom: 4px;
    }
  }
}
