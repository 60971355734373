.wrapper {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: auto;

  border-radius: 100%;
  transition: ease-in-out;
}

.border {
  padding: 2px;

  background: linear-gradient(132.79deg, #4513b6 15.16%, #e01f48 85.97%);
  filter: drop-shadow(0px 0px 20px #1a0965);
  backdrop-filter: blur(5px);
}

.pointer {
  cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.avatar {
  width: 100%;
  height: 100%;

  border-radius: 100%;
}

.badge {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 52%;

  width: 103%;

  transform: translate(-50%, -50%);
  pointer-events: none;
}
