.cashbackTableWrap {
  max-width: 1339px;
  width: 100%;
  height: 100%;
  margin: 30px 0 0 0;
  position: relative;
  color: white;
}

.cashbackTableBody {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 30px;
}

@media (max-width: 1024px) {
  .cashbackTableWrap {
    margin-top: 0;
  }
}
