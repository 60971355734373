.wrapper {
  position: relative;

  padding: 0 30px;
  width: 100%;
  height: 100%;
  margin: 30px 0;

  color: white;
}

.table {
  display: flex;
  width: 100%;
  margin: 30px 0;
}

@media (max-width: 640px) {
  .wrapper {
    padding: 0 15px;
  }
}
