.userBonusFrontWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;

  background: #202d4a;
  border-radius: 16px;
  overflow: hidden;
}

.userBonusFrontImg {
  display: inline-block;
  width: 100%;

  aspect-ratio: 300/180;
  object-fit: cover;
}

.titleWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 10px;
  gap: 8px;
}

.userBonusFrontName {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FCCD00;

  margin: 0 15px 0;
  text-align: center;
}

.userBonusFrontFlipBtn {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  background: #424862;
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  margin: 0 16px;

  color: #ffffff;

  z-index: 1;
  padding: 8px 12px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: url('../../../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.userBonusFrontContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  // min-height: 270px;
}

.userBonusFrontContentInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
.userBonusFrontContentInfoMinMaxItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h3 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin: 0;
  }
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7074a4;
    margin: 0;
  }
}

.userBonusFrontContentInfoAmount {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  h3 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin: 0;
  }
  h4 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7074a4;
    margin: 0;
  }
  h5 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;
  }
  h6 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #99d290;
    margin: 0 0 0 10px;
    img {
      width: 17px;
      margin: 0 7px 5px 0;
    }
  }
}

.userBonusFrontContentInfoTimer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7074a4;
    margin: 0 0 7px;
  }
}
.userBonusFrontContentInfoTimerContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;

  img{
    display: block;
    width: 19px;
    height: auto;
    margin: 0 2px 4px 0;
  }
}
.userBonusFrontContentInfoTimerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin: 0 2px;

  p {
    margin: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
}

.userBonusFrontContentButton {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
  h6 {
    position: absolute;
    top: 11px;
    right: 20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: black;
    text-align: center;
    background: #ffffff;
    cursor: url('../../../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }
  button {
    text-transform: uppercase;
  }
}

.userBonusFrontContentProgress {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  border-radius: 8px;
  height: 60px;
  padding: 10px 10px 0;
}
.userBonusFrontContentProgressAmount {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 8px;
  h2,
  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
  h2 {
    color: #99d290;
    margin-right: 6px;
  }
  h3 {
    color: #ffffff;
  }
}
.userBonusFrontContentProgressLine {
  display: flex;
  width: 100%;
  position: relative;
  background: #424862;
  border-radius: 4px;
  height: 8px;
  overflow: hidden;
}
.userBonusFrontContentProgressLineInner {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 4px;
  opacity: 0;
  background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
  animation: userBonusProgress 2s ease-in-out forwards;
  animation-delay: 1s;
}
@keyframes userBonusProgress {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.userBonusFrontContentGame {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #161e31;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  img {
    width: 71px;
    height: 48px;
    display: inline-block;
    border-radius: 2px;
  }
  .spinsContent {
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    margin-left: 10px;
    h4 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 12px;
      color: #7074a4;
      margin: 0;
    }
    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      margin: 0;
    }
    button {
      border: none;
      background: transparent;
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      cursor: url('../../../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
      &:disabled {
        pointer-events: none;
      }
    }
  }
}

.userBonusFrontContentSpinAmount {
  display: flex;
  width: 100%;
  margin: 15px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;
  }
  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fccd00;
    margin: 0;
  }
}

@media (max-width: 400px) {
  .userBonusFrontContentInfoMinMaxItem {
    h3 {
      font-size: 14px;
    }
  }
}
