.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1340px;
  padding: 0 30px 30px;

  font-family: 'Montserrat';
}