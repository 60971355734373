.content {
  width: 860px;
  margin: 0 auto 20px;
}

.title {
  margin: 0 0 10px;

  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #ffffff;
  text-transform: uppercase;
}

.articles {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-gap: 2%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

@media (max-width: 920px) {
  .content {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .articles {
    grid-template-columns: repeat(2, 48%);
  }
}

@media (max-width: 500px) {
  .content {
    padding: 0 30px;
  }

  .articles {
    grid-template-columns: repeat(1, 100%);
    grid-gap: 20px;
  }
}

@media (max-width: 370px) {
  .title {
    font-size: 1.5rem;
  }
}