.wrapper {
  position: relative;
  z-index: 2;

  display: inline-block;
  width: 100%;

  aspect-ratio: 2.1;

  font-size: 10px;

  background: #1f2d4b;
  border-radius: 16px;
  overflow: hidden !important;
}

.content {
  display: flex;
  align-items: center;
}

.img {
  width: 100%;
}

.title {
  position: absolute;
  z-index: 2;
  top: 11%;
  left: 5%;

  margin: 0;
  padding: 5px;

  font-weight: 700;
  font-size: 2em;
  line-height: 28px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: #ffffff;

  border-radius: 4px;
}

.subtitle {
  position: absolute;
  z-index: 2;
  top: 45%;
  left: 5%;
  margin: 0;
  padding: 2px 5px;

  font-weight: 800;
  font-size: 1.6em;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: black;
  background-color: #DC9300;
}

.label {
  position: absolute;
  z-index: 2;
  top: 65%;
  left: 5%;

  margin: 0;
  padding: 5px;

  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.16px;
  color: #ffffff;
}

@media (max-width: 500px) {
  .subtitle {
    padding: 2px 5px 0;
  }
}
