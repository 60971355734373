.wrapper {
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  background: #070B16B8;
  border-radius: 8px;
  margin: 0;
  max-height: 160px;
  min-height: 160px;
  overflow-y: auto;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  width: none;
}

.wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0;

  p{
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.rowCurrency {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 35px;
    height: auto;
    margin-right: 3px;
  }
  h5 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    min-width: 100px;
    text-align: end;
  }
}




