.cell {
  padding: 15px 0;

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
}

.content {
  display: flex;
  align-items: center;
  max-width: 100%;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.even {
  background: rgba(22, 30, 49, 0.4);

  &:first-child {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  &:last-child {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
