.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 24px 0 24px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48.5%;
  height: 74px;

  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;

  border: none;
  border-radius: 16px;
  mix-blend-mode: normal;
  background: linear-gradient(180deg, #E81717 0%, #B30A0A 100%);

  transition: 0.1s ease-in-out;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:active{
    border: 4px solid #19203C;
  }

  &:hover {
    filter: brightness(120%);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.active {
  background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
}

@media (max-width: 700px) {
  .button {
    height: 60px;
  }
}

@media (max-width: 640px) {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 5px 0 15px;
  }
}
@media (max-width: 570px) {
  .button {
    height: 54px;

    font-size: 14px;
  }
}

@media (max-width: 570px) {
  .button {
    height: 48px;

    font-size: 12px;
  }
}
