.limboScreenWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 435px;
  max-height: 435px;
  overflow: hidden;
  margin: 5px 0 0;
  position: relative;
  border-radius: 22px;
}

.limboCanvasWrap {
  height: 100%;
  width: inherit;
  min-height: 435px;
  max-height: 435px;
  margin: 0;
  overflow: hidden;
  background: #100B2F;
  border-radius: 22px;
  position: relative;
}

.limboImagesBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 22px;
  overflow: hidden;
}

.limboImagesWrap {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 22px;
  overflow: hidden;
}

.limboImagesMoon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}

.limboImagesSaturn {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
}

.limboImagesBrickRight {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 7%;
}

.limboImagesBrickLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 7%;
}

.limboImagesMoonBottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90%;
  transform: translateX(-50%) scale(1);
}

.limboImagesShip {
  position: absolute;
  bottom: 6.3%;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
  visibility: hidden;
}

.limboImagesShipFly {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
  visibility: hidden;
  transform-origin: top;
}

.limboImagesShip.shipVisible,
.limboImagesShipFly.shipVisible {
  visibility: visible;
}

@keyframes moonAnimate {
  0% {
    top: 0;
    right: 0;
  }
  100% {
    top: -100%;
    right: -100%;
  }
}

.limboImagesMoon.limboAnim {
  animation: moonAnimate 10s ease-in-out;
}

@keyframes saturnAnimate {
  0% {
    top: 0;
    left: 0;
  }
  100% {
    top: -100%;
    left: -100%;
  }
}
.limboImagesSaturn.limboAnim {
  animation: saturnAnimate 10s ease-in-out;
}

@keyframes bricksRightAnimate {
  0% {
    bottom: 0;
    right: 0;
  }
  100% {
    bottom: -100%;
    right: -100%;
  }
}
.limboImagesBrickRight.limboAnim {
  animation: bricksRightAnimate 10s ease-in-out;
}

@keyframes bricksLeftAnimate {
  0% {
    bottom: 0;
    left: 0;
  }
  100% {
    bottom: -100%;
    left: -100%;
  }
}
.limboImagesBrickLeft.limboAnim {
  animation: bricksLeftAnimate 10s ease-in-out;
}

@keyframes moonBottomAnimate {
  0% {
    bottom: 0;
  }
  30% {
    bottom: -105%;
  }
  100% {
    bottom: -105%;
  }
}
.limboImagesMoonBottom.limboAnim {
  animation: moonBottomAnimate 10s ease-in-out;
}

@keyframes shipFlyAnimate {
  0% {
    bottom: 0;
    transform: translateX(-50%) scale(1);
  }
  20% {
    bottom: 3%;
    transform: translateX(-50%) scale(0.7);
  }
  100% {
    bottom: 3%;
    transform: translateX(-50%) scale(0.7);
  }
}

.limboImagesShipFly.shipVisible {
  animation: shipFlyAnimate 10s ease-in-out;
}



@media (max-width: 1300px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 900px) {
  .limboScreenWrap {
    min-height: 400px;
    max-height: 400px;
  }
  .limboCanvasWrap {
    min-height: 400px;
    max-height: 400px;
  }
}
@media (max-width: 768px) {
  .limboScreenWrap {
    min-height: 300px;
    max-height: 300px;
  }
  .limboCanvasWrap {
    min-height: 300px;
    max-height: 300px;
  }
}
@media (max-width: 640px) {
}
@media (max-width: 580px) {
}
@media (max-width: 500px) {
  .limboScreenWrap {
    min-height: 250px;
    max-height: 250px;
  }
  .limboCanvasWrap {
    min-height: 250px;
    max-height: 250px;
  }
}
@media (max-width: 460px) {
  .limboImagesShip {
    bottom: 5%;
    width: 35%;
  }

  .limboImagesShipFly {
    width: 35%;
  }
}
@media (max-width: 400px) {
  .limboImagesShip {
    bottom: 5.7%;
  }
}
@media (max-width: 370px) {
  .limboImagesShip {
    bottom: 5.5%;
  }
}
