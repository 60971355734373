.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.title {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0 0 0;
  color: #ffffff;
  text-align: center;
}

.bodyImage {
  display: inline-block;
  width: 70%;
  margin: 30px 0 0 0;
}

.dropWrap {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.dropHeader {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  color: #FCCD00;
  text-decoration: underline;
  cursor: url("../../../../../../../assets/img/common/cursor-pointer.webp"), auto;
}

.dropBody {
  visibility: hidden;
  flex-direction: column;
  width: 100%;
  height: 0;
  background: rgba(7, 11, 22, 1);
  transition: all 0.1s ease-in-out;
  transition-duration: 000ms;
  transition-delay: 000ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  margin-top: 10px;
  border-radius: 24px;
  overflow: hidden;
  opacity: 0;


  position: absolute;
  top: 20px;
  left: 0;
  max-height: 0;
}

.dropBodyOpen {
  visibility: visible;
  opacity: 0.94;
  transition: all 0.3s ease-in-out;
  transition-duration: 100ms;
  transition-delay: 100ms;
  z-index: 5;
  padding: 10px 15px 20px 0;

  height: auto;
  max-height: 300px;
}

.dropBodyContent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 260px;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: #424862 transparent;
}
.dropBodyContent::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

.dropBodyContent::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.dropBodyContent::-webkit-scrollbar-thumb {
  background-color: #424862;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid transparent;  /* creates padding around scroll thumb */
}

.dropBodyItem {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 0 0 5px 0;
  color: #ffffff;
  padding: 0 0 0 15px;
}

