.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 60px 0;
  margin: -20px 0 0;

  background: radial-gradient(100% 100% at 50% 0%, #184e8d 0%, rgba(36, 44, 69, 0) 100%);
}

.avatar {
  width: 106px;
}

.alias {
  margin: 24px 0 0;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.rank {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 13px 0 70px;
}

.rankIcon {
  margin: 0 10px 0 0;
  width: 30px;
}

.rankDesc {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #54b652;
}

.buttons {
  position: relative;
}

.button {
  padding: 0 0 0 10px;

  text-transform: uppercase;
}

.buttonIcon {
  position: absolute;
  top: 12px;
  left: 30px;
  width: 14px;
}
