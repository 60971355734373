.addressItemWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 5px 0;
  padding: 5px 10px;

  background: #00000033;
  mix-blend-mode: normal;
  border-radius: 19px;
}

.addressItemImg {
  display: inline-block;
  width: 30px;
}

.addressItemName {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.addressItemTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #888aa7;
}

.addressItemButton {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  width: 20px;
  height: 20px;
  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  position: relative;

  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }

  img {
    width: 20px;
    height: 20px;
    display: inline-block;
  }
}
.addressItemButton img:hover {
  transform: scale(1.1);
}

.addressItemButtonWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
}

.addressBtnCopyTooltip {
  position: absolute;
  display: inline-block;
  text-align: center;
  width: 50px;
  height: 20px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  right: 0;
  background: #808080;
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.1s ease-in-out;
  border-radius: 19px;
}
.addressBtnCopyTooltip.open {
  visibility: visible;
  transform: translateY(-200%);
}