.wrapper {
  display: flex;
  width: 100%;
  min-height: 100px;
  height: 100%;
  max-height: 100%;

  & > div {
    flex-grow: 1;
 
    pointer-events: none;
  }
}