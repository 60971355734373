.footerSocialItem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  img{
    width: 30px;
    height: 30px;
    cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    margin-bottom: 5px;
    &:hover{
      transform: scale(1.2);
    }
  }
}

@media (max-width: 900px) {
  .footerSocialItem {
    width: 30%;
    margin: 0 5px 15px;
    justify-content: center;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 640px) {
  .footerSocialItem{
    margin: 0 0 10px;
    img {
      width: 30px;
      height: 30px;
    }
    p {
      font-size: 12px;
    }
  }
}
