.wrapper {
  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  height: 800px;
  width: 100%;
  max-width: 1264px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: url('../../../../../assets/img/Vip/banner-bg.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.defaultIcon {
  position: absolute;
  z-index: 2;
}

.coinStarship {
  left: 5%;
  top: 5%;

  width: 27vw;
  max-width: 450px;
}

.starship {
  right: 5%;
  bottom: 5%;

  width: 22vw;
  max-width: 350px;
}

.coinCasino {
  right: 5%;
  top: 5%;

  width: 27vw;
  max-width: 450px;
}

.casino {
  left: 5%;
  bottom: 5%;

  width: 22vw;
  max-width: 350px;
}

.vipWrapper {
  position: relative;

  width: 100%;
  height: 100%;

  background-image: url('../../../../../assets/img/Vip/bright.webp');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.vip {
  position: absolute;
  z-index: 3;
  top: 7%;
  left: 50%;

  width: 195px;
  height: auto;

  translate: -50%;
}

.content {
  position: absolute;
  z-index: 3;

  left: 50%;
  top: calc(7% + 300px);

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50%;

  transform: translateX(-50%);
}

.title {
  margin: 0 0 10px;

  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: -0.5px;
  text-align: center;

  color: #ffffff;
}

.subtitle {
  margin: 0 0 40px;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  color: #ffffff;
}

.description {
  margin: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;

  color: #ffffff;
}

@media (max-width: 1200px) {
  .content {
    max-width: 70%;
  }

  .coinStarship,
  .coinCasino {
    top: 10%;
  }
}

@media (max-width: 1100px) {
  .content {
    max-width: 80%;
  }

  .coinStarship,
  .coinCasino {
    top: 15%;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    height: 600px;
  }

  .starship,
  .casino {
    display: none;
  }
}

@media (max-width: 900px) {
  .defaultIcon {
    display: none;
  }
}

@media (max-width: 600px) {
  .wrapper {
    height: 700px;
  }

  .content {
    padding: 0 20px;
    max-width: unset;
  }

  .title {
    font-size: 30px;
    line-height: 37px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 18px;
  }

  .description {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 550px) {
  .title {
    font-size: 28px;
    line-height: 35px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    background-size: auto 50%;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 24px;
    line-height: 31px;
  }
}
