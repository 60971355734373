.burgerUserWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 20px;
}

.burgerUserBg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  padding: 13px;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 25px;
}

.burgerUserFirstBlock {
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-items: center;
}

.burgerUserAvaWrap {
  display: flex;
  min-width: 42px;
  height: 40px;
  background: linear-gradient(132.79deg, #4513b6 15.16%, #e01f48 85.97%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.burgerUserAva {
  display: inline-block;
  width: 40px;
  height: 38px;
  border-radius: 50%;
}

.burgerUserInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  h4 {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
  }
}

.burgerUserWallet {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }
  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #52c330;
    margin: 0;
  }
}

.burgerUserAdditionalBlock {
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;
}


@media (max-width: 360px) {
  .burgerUserWallet {
    h5 {
      font-size: 14px;
    }
  }
}