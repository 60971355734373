* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 700;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background: radial-gradient(
      44.11% 51.33% at 10.08% 47.02%,
      #442a81 0%,
      rgba(22, 30, 49, 0) 100%
    ),
    radial-gradient(
      58.77% 68.38% at 100% 52.05%,
      #2654a9 0%,
      rgba(26, 61, 122, 0) 100%
    ),
    #161e31;
  width: 100vw;
}
body::-webkit-scrollbar {
  display: none;
}

html {
  background: radial-gradient(
      44.11% 51.33% at 10.08% 47.02%,
      #442a81 0%,
      rgba(22, 30, 49, 0) 100%
    ),
    radial-gradient(
      58.77% 68.38% at 100% 52.05%,
      #2654a9 0%,
      rgba(26, 61, 122, 0) 100%
    ),
    #161e31;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  outline: 0;
  outline-offset: 0;
  border: 0;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

#root {
  /*background: radial-gradient(44.11% 51.33% at 10.08% 47.02%, #442A81 0%, rgba(22, 30, 49, 0) 100%),radial-gradient(58.77% 68.38% at 100% 52.05%, #2654A9 0%, rgba(26, 61, 122, 0) 100%),#161E31;*/
  background: #161e31;
  width: 100vw;
}

.wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
.wrapper.blur {
  filter: blur(5px);
}

.radial-bg-1 {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
}

.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.games {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.basic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .home {
    width: 100vw;
  }
}

@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}
