.wrapper {
  width: 44px;
  height: 44px;

  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

.link {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  overflow: hidden;

  border-radius: 8px;
}

.image {
  height: 44px;
  width: auto;

  border-radius: 8px;
}

.hovered {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.hidden {
  
  &::after {
    content: '';

    position: absolute;
    left: 0;
    right: 0;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.6);
  }
}