.forgotWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 440px;
}

.forgotTitle {
  max-width: 95%;
  margin: 10px 0 0;

  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  color: #ffffff;
}

.forgotForm {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 30px;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0;
  }
}
.forgotFormEmailIcon {
  position: absolute;
  height: 12px;
  width: 14px;
  left: 25px;
  top: 35%;
  z-index: 1;
}

.forgotCheckBoxError {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  color: #f02f41;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 50px;
}

.forgotBtn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70px;
  position: relative;
  margin-top: -20px;
  span {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0 10px 0 0;
  }
}

.forgotBtn1 {
  color: #888aa7;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #888aa7;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.button {
  text-transform: uppercase;
}
