.wrapper  {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 14px 16px;
  margin: 0 0 10px;

  background: rgba(22, 30, 49, 0);
  border-radius: 8px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  -webkit-transition:background-color 1s;
  -moz-transition:background-color 1s;
  transition:background-color 1s;

  &:last-child {
    margin: 0;
  }
}

.background {
  background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);
}

.imageWrapper {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: 8px;

  background: rgba(255, 203, 153, 0.24);
  border-radius: 100%;
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;

  width: 24px;
  height: auto;

  transform: translate(-50%, -50%);
}

.title {
  margin-bottom: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #99D290;
}

.description {
  margin-bottom: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;

  color: #ffffff;

  img {
    width: 30px;
    height: auto;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    vertical-align: middle;
  }
}

.date {
  margin-bottom: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;

  color: #C1C5FB;
}

.red {
  color: #EE4D6A;
}

.new {
  position: absolute;
  right: 40px;
  top: 15px;

  padding: 2.5px 5px;

  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;

  background: #EE4D6A;
  border-radius: 8px;
}

.removeButton {
  position: absolute;
  right: 16px;
  top: 10px;

  border: none;
  background: none;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.removeIcon {
  width: 18px;
  height: auto;

  fill: white;
}
