.footerDate {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  margin: 0;

  p {
    margin: 0;
  }
}

.rights {
  margin-left: 0.25rem !important;
}

@media (max-width: 900px) {
  .footerDate {
    order: 5;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .footerDate {
    flex-direction: column;
  }
}
