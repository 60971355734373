.blockedIpWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 440px;
}

.blockedIpTitle {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 36px;
  color: rgb(255, 255, 255);
  margin-top: 10px;
}

.blockedIpBody {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 40px;
  font-family: 'Montserrat';
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 18px;
    padding-left: 15px;
    margin: 0 auto;
  }
}

.blockedIpImage {
  display: inline-block;
  width: 200px;
}

.button {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .blockedIpBody {
    padding: 0 20px;
    h3 {
      font-size: 14px;
    }
  }
}
