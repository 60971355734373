.homeTablesWrap {
  width: 100%;
  height: 100%;
  margin: 0 0 30px;
  position: relative;
  color: white;
}

.homeTablesNavigation {
  display: flex;
  width: auto;
  margin-top: 30px;
}

.homeTablesBody {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  flex-direction: column;
}
