.wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  max-width: 164px;
  margin: 0 14px 0 0;
  padding: 0 0 0 8px;

  background: linear-gradient(270deg, rgba(111, 73, 45, 0.27) -0.32%, rgba(111, 73, 45, 0) 100%), #202D4A;
  background-image: url('../../../../../../../assets/img/Activities/bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  border-radius: 8px;

  &:last-child {
    margin: 0;
  }
}

.title {
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}

.iconWrapper {
  position: relative;

  width: 70px;
  height: 48px;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  overflow: hidden;
}

.icon {
  position: absolute;
  right: 0;

  height: 48px;
  width: auto;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.customWheel {
  right: -25%;
}

.count {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;

  background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
  border-radius: 100%;

  transform: translate(30%, -30%);
}