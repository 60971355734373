.wrapper {
  flex-grow: 1;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  width: 100%;
  height: auto;
  min-height: 500px;
  margin: 32px 0;

  color: white;
}
