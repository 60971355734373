.gamesNavWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background: #202D4A;
  mix-blend-mode: normal;

  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);

  border-radius: 20px;
  margin: 0;
  padding: 5px 30px 5px 5px;
}

.gamesNavItemWrap {
  display: flex;
  justify-content: space-between;
  width: 70%;
  height: 100%;
  padding: 0 26% 0 0;
}

.gamesNavSearchWrap {
  display: flex;
  width: 288px;
}

@media (max-width: 1300px) {
  .gamesNavItemWrap {
    padding: 0 10px 0 0;
  }
}

@media (max-width: 1024px) {
  .gamesNavItemWrap {
    width: 100%;
  }
  .gamesNavSearchWrap {
    display: none;
    max-width: 0;
    overflow: hidden;
  }
}

@media (max-width: 800px) {
  .gamesNavWrap {
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .gamesNavWrap {
    height: 60px;
  }
}
