.betAmountWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 91px;
  background: #202D4A;
  mix-blend-mode: normal;
  border-radius: 16px;
  padding: 7px;
  min-width: 275px;
}

.betAmountButtonWrap {
  display: flex;
  min-width: 48px;
  max-width: 68px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.betAmountInputWrap {
  display: flex;
  width: 100%;
  max-width: 280px;
  min-width: 170px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: #888aa7;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
  }
  input {
    background-color: transparent;
    border: none;
    outline: none;
    color: #ffffff;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    width: 140px;
    cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.betAmountInputContent {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 35px;
  }
}

@media (max-width: 1200px) {
  .betAmountInputWrap {
    min-width: auto;
    p {
      font-size: 16px;
    }
    input {
      font-size: 14px;
      width: 100px;
    }
  }
}

@media (max-width: 1024px) {
  .betAmountInputWrap {
    p {
      font-size: 16px;
    }
    input {
      font-size: 14px;
      width: 100px;
    }
  }
}
