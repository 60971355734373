.wrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.icon {
  width: 24px;
  height: 24px;
}

.wager {
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
