.limboSwitchAuto {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 90px;
  margin: 0 0 0 15px;

  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0 10px 0 0;
  }
}

@media (max-width: 640px) {
  .limboSwitchAuto {
    height: 60px;
    max-width: 123px;
  }
}
