.status {
  z-index: 3;
  position: absolute;
  top: 5%;
  left: 3%;

  display: flex;
  align-items: center;
  padding: 2px 8px;
  width: max-content;
  height: 14%;

  color: white;

  border-radius: 6px;
  background: rgb(29, 42, 71);
  background: linear-gradient(
    0deg,
    rgba(29, 42, 71, 1) 0%,
    rgba(71, 94, 149, 1) 100%
  );
}

.dotWrapper {
  position: relative;
  margin-right: 2px;

  width: 10px;
  min-width: 10px;
  height: 10px;
}

.dot {
  position: absolute;
  left: calc(50% - 3px);
  top: calc(50% - 3px);

  width: 6px;
  height: 6px;

  animation: pulse 2s ease-in-out infinite;

  &.open {
    fill: green;
  }

  &.closed {
    fill: red;
  }
}

.players {
  z-index: 3;
  position: absolute;
  top: 22%;
  left: 3%;

  display: flex;
  align-items: center;
  padding: 2px 8px;
  width: max-content;
  height: 14%;

  color: white;

  border-radius: 6px;
  background: rgb(29, 42, 71);
  background: linear-gradient(
    0deg,
    rgba(29, 42, 71, 1) 0%,
    rgba(71, 94, 149, 1) 100%
  );
}

.playerIcon {
  margin-right: 2px;

  width: 10px;
  height: auto;

  fill: currentColor;
}

.playersCount {
  flex-grow: 1;

  text-align: center;
}

.limits {
  z-index: 3;
  position: absolute;
  top: 39%;
  left: 3%;

  display: flex;
  align-items: center;
  padding: 2px 8px;
  width: max-content;
  height: 14%;

  color: white;

  border-radius: 6px;
  background: rgb(29, 42, 71);
  background: linear-gradient(
    0deg,
    rgba(29, 42, 71, 1) 0%,
    rgba(71, 94, 149, 1) 100%
  );

  &.withoutPlayers {
    top: 22%;
  }
}

.symbol {
  margin-right: 2px;

  font-weight: 500;
}

.amount {
  flex-grow: 1;

  text-align: center;

  white-space: nowrap;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.5);
  }
}
