.btcRatesSliderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  color: #ffffff;
  height: 36px;
}
.btcRateWrap {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: rgba(136, 138, 167, 0.15);
  border-radius: 6px;
  width: 192px;
  color: #ffffff;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  p {
    margin: 0;
    font-size: 14px;
  }
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btcRateImg {
  display: inline-block;
  width: 35px !important;
  height: auto;
  margin-left: -5px;
}

.ratesSlider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .btcRateWrap {
    margin: 0 0 30px;
  }
}
