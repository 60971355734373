.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px;

  font-family: Montserrat;
  color: #ffffff;

  background-image: url("../../../../../assets/img/Missions/popup_background.webp");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 0 40px;

  background-image: url("../../../../../assets/img/Missions/popup-gradient.webp");
  background-position: center top;
  background-repeat: repeat-x;
  background-size: contain;
}

.image {
  width: 240px;
  height: auto;
}

.title {
  margin: 0 0 30px;

  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.11999999731779099px;
  text-align: center;
}

.description {
  margin: 0 0 30px;
  padding: 0 32px;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}
