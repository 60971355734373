.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.para {
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

.monster {
  display: inline-block;
  width: 60%;
  min-height: 250px;
}

@media (max-width: 540px) {
  .para {
    font-size: 18px;
  }
  .monster {
    min-height: 200px;
  }
}