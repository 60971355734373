.rankRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rankRowWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  background: #161E31;
  border-radius: 8px;
  padding: 8px;
}

.rankRowWrap.rankNoReached {
  opacity: 0.2;
}

.rankRowCheckmark {
  display: inline-block;
  width: 19px;
  opacity: 0;
  animation: showRankDescriptionResult 0.5s ease-in-out forwards;
  margin-left: 5px;
}

@keyframes showRankDescriptionResult {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rankRowImg {
  display: inline-block;
  width: 19px;
  margin: 0 5px;
}

.rankRowTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #C1C5FB;
  margin: 0;
}
