.logoWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 270px;
  margin-left: 32px;

  img {
    width: 128px;
    cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    transition: 0.06s ease-in-out;
    margin-right: 10px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  a {
    color: white;
    font-family: 'Montserrat';
    font-weight: 500;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
      color: white;
    }
  }
}

.logoImage {
  width: 128px;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.logoImageWrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    position: relative;
    border: none;
    outline: none;
    background: transparent;
    text-decoration: none;
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      img {
        transform: scale(1);
      }
    }
  }
}


.bonusButton {
  &:hover {
  transform: scale(1) !important;
  .bonusWrap {
    background: linear-gradient(336deg, #E9415E 8.55%, #F98C27 91.45%);
     border: 1px solid #FFE551;
    }
  }
}

.bonusWrap {
  display: flex;
  padding: 0 10px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(336deg, #A6103A 8.55%, #ED9700 91.45%);
  border: 1px solid rgba(255, 229, 81, 0.58);
  border-radius: 20px;
  margin-right: 5px;

  img {
    width: 20px;
    height: auto;
    margin-right: 3px;
  }
  p {
    font-size: 11px;
    color: #FFC99A;
    font-family: 'Montserrat';
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }
}

.logoGift {
  width: 45px !important;
  height: 45px !important;
  -webkit-filter: drop-shadow(0px 0px 10px #ed9700);
  filter: drop-shadow(0px 0px 10px #ed9700);
}

.logoGiftCount {
  position: absolute;
  top: -2px;
  right: 5px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff;
  font-family: 'Montserrat';
  font-weight: 500;
  border-radius: 50%;
  background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
  text-align: center;
  margin: 0;
}

.logoWheel {
  width: 40px !important;
  height: 40px !important;
  display: inline-block;
  animation: spinning 9s linear infinite;
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .logoWrap {
    display: none !important;
  }
}