.wrapper {
  display: flex;
  align-items: center;
}

.gamesOptionsBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}

.gamesOptionsBtnDkt {
  display: flex;
  width: 100%;
}

.gamesOptionsBtnMbl {
  display: none;
}

.gamesOptionsBtnNav {
  display: flex;
  width: 40px;
  min-height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 2px solid #888AA7;
  border-radius: 10px;
  padding-left: 8px;
  position: relative;

  div {
    width: 21px;
    height: 2px;
    background: #ffffff;
  }

  div:nth-of-type(2) {
    margin: 4px 0;
  }

  i {
    position: absolute;
    right: 0;
    top: 14px;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}

.gamesOptionsBtnNavWrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 54px;
  min-height: 54px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  padding: 7px 0;
  transition: height 0.1s ease-in-out;
  overflow: hidden;
  border: none;
  cursor: url("../../../../assets/img/common/cursor-pointer.webp"), auto !important;

  &:active,
  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.gamesOptionsBtnNavBody {
  visibility: hidden;
  max-height: 0;
}

.gamesOptionsBtnNavBody.gamesOptionsBtnNavBodyOpen {
  visibility: visible;
  max-height: 600px;
  padding-top: 5px;
}


.gamesOptionsBtnElem {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: url("../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  background: #202D4A;
  border-radius: 4px;
  margin: 0 2px;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.gamesOptionsBtnElemImg {
  width: 25px;
}

.gamesOptionsBtnElem.gamesOptionsBtnElemActive {
  background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);
  box-shadow: 0 0 20px #6E32F0;
  transition: 0.1s ease-in-out;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 1350px) {
  .gamesOptionsBtnWrap {
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    width: 54px;
    height: 54px;
    background: transparent;
  }
  .gamesOptionsBtnDkt {
    display: none;
  }
  .gamesOptionsBtnMbl {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;
  }
  .gamesOptionsBtnElem {
    margin: 5px 0;
  }
}
