.telWrap {
  width: auto;
  height: auto;
  max-height: 400px;
  background: #202D4A !important;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  opacity: 1!important;
  padding: 0!important;

  //font-size: 20px !important;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
.footerSocialItem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  img{
    width: 30px;
    height: 30px;
    cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    margin-bottom: 5px;
    &:hover{
      transform: scale(1.2);
    }
  }
}
.telContent {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #7074A4 !important;
    margin: 0 0 8px 0 !important;
  }
}

.telOfficialItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 6px;
  border-radius: 4px;
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    outline: none;
    cursor: url("../../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  }
  img {
    width: 20px;
    display: inline-block;
    margin-right: 10px;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin: 0;
  }
  &:hover {
    background: rgba(7, 11, 22, 0.72);
  }

}

.telFlagsWrap {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.telFlagItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  outline: none;
  cursor: url("../../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  padding: 6px;
  border-radius: 4px;
  &:hover {
    background: rgba(7, 11, 22, 0.72);
  }
  img {
    display: inline-block;
    width: 28px;
    height: 20px;
    margin-right: 10px;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .footerSocialItem {
    width: 30%;
    margin: 0 5px 15px;
    justify-content: center;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 640px) {
  .footerSocialItem{
    margin: 0 0 10px;
    img {
      width: 30px;
      height: 30px;
    }
    p {
      font-size: 12px;
    }
  }
}
