.wrapper {
  // position: absolute;
  top: -93px;
  right: 0;
}

@media (max-width: 1024px) {
  .wrapper {
    position: unset;
    // margin-bottom: 30px;
  }
}