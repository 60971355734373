.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.list {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  list-style-type: none;
}

.item {
  margin: 0 16px 0 0;

  &:last-child {
    margin: 0;
  }
}

.button {
  padding: 0 0 2px;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7074a4;

  border: none;
  border-bottom: 2px solid transparent;
  background: none;

  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &.active {
    color: white;

    border-bottom: 2px solid #5942ee;
  }
}
