.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.sort {
  width: 150px;
}

.searchWrapper {
  position: relative;
  margin-left: auto;
  width: max-content;
  height: max-content;

  &.expanded {
    padding-left: 24px;
  }
}

.search {
  height: 40px;
  width: 250px;
  padding: 0 32px;

  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  background-color: rgba(7, 11, 22, 0.72);
  border: 2px solid rgba(7, 11, 22, 0.72);
  border-radius: 18px;

  outline: none;

  &:hover {
    border: 2px solid rgba(122, 44, 255, 0.7);
  }

  &:focus {
    border: 2px solid rgba(122, 44, 255, 1);
  }
}

.reset {
  position: absolute;
  left: 0;
  top: 50%;

  transform: translateY(-50%);
  
  background: none;
  border: none;

  svg {
    width: 10px;
    height: auto;

    fill: rgba(255, 255, 255, 1);
  }

  &:hover svg {
    fill: rgba(255, 255, 255, 0.7);
  }
}

.searchIcon {
  position: absolute;
  right: 16px;
  top: 50%;

  transform: translateY(-50%);
}


@media (max-width: 520px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }

  .sort,
  .searchWrapper,
  .search {
    width: 100%;
  }

  .label {
    margin: 0 0 20px;
  }

  .labelText {
    text-align: center;
  }
}