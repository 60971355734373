.minesSubmitButtonWrap {
  display: flex;
  flex-grow: 4;
  height: 91px;
}

.minesSubmitButton{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.09px;
  color: #FFFFFF;
  h5, h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin: 0;
  }
}

@media (max-width: 1400px) {
  .minesSubmitButton {
    h5, h6 {
      font-size: 13px;
    }
  }
}
@media (max-width: 1200px) {
  .minesSubmitButton {
    h5, h6 {
      font-size: 11px;
    }
  }
}
@media (max-width: 1100px) {
  .minesSubmitButton {
    h5, h6 {
      font-size: 14px;
    }
  }
}
@media (max-width: 1024px) {
  .minesSubmitButton {
    h5,h6 {
      font-size: 14px;
    }
  }
}
@media (max-width: 900px) {
}
@media (max-width: 768px) {
}
@media (max-width: 680px) {
  .minesSubmitButton {
    h5,h6 {
      font-size: 12px;
    }
  }
}

@media (max-width: 640px) {
  .minesSubmitButtonWrap {
    height: 60px;
    order: 3;
  }
  .minesSubmitButton{
    h5, h6 {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
