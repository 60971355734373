.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  max-height: 100%;

  overflow: hidden;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  overflow: hidden;
  overflow-y: scroll;
}
