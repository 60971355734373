.bonusLoginWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 500px;

  background-image: url('../../../../../assets/img/BonusPage/bonus-pop-up-bg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
}

.bonusLoginMainTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.12px;

  color: #ffffff;
}

.bonusLoginSubtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #c1c5fb;
}

.bonusLoginTitleWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
  flex-direction: column;
}

.bonusLoginTitleBlurImg {
  position: absolute;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.bonusLoginTitleImg {
  display: inline-block;
  height: 120px;
  z-index: 2;
}

.bonusLoginTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.12px;

  color: #ffffff;
  margin: 50px 0 10px;
  z-index: 2;
}

.bonusLoginTimerWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  h4 {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;

    color: #ffffff !important;
  }
}

.bonusLoginBtn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.wrapperSlider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bonusSlide {
  display: flex !important;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.button {
  position: absolute;
  bottom: 0;

  background: none;
  border: none;

  transition: all 200ms ease-in-out;
  width: 40px;
  height: 40px;
  top: 199px;

  background: rgba(255, 255, 255, 0.24);
  border-radius: 8px;
  z-index: 2;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:hover {
    opacity: 0.7;
  }

  &.left {
    margin: 0 0 0 -60px;

    left: 13%;
    top: 20%;
  }

  &.right {
    margin: 0 -60px 0 0;

    right: 13%;
    top: 20%;
  }
}

.arrow {
  width: 10px;
  height: auto;
}

.arrowLeft {
  transform: rotate(180deg);
}

.viewAll {
  text-transform: uppercase;
}

@media (max-width: 580px) {
  .bonusLoginTitle {
    bottom: -30px;
    p {
      font-size: 14px;
      line-height: 24px;
      padding: 10px 20px;
    }
  }
  .bonusLoginTitleBlurImg {
    position: absolute;
    top: -35%;
    width: 100%;
  }
}

@media (max-width: 540px) {
  .bonusLoginTitle {
    font-size: 18px;
  }
  .bonusLoginBtn {
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .button {
    &.left {
      margin: 0 0 0 -60px;

      left: 50%;
      top: 95%;
    }

    &.right {
      margin: 0 -60px 0 0;

      right: 50%;
      top: 95%;
    }
  }
}
