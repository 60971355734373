.wrapper {
  position: absolute;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 156px;
  height: 100%;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: none;
  background: transparent;
  box-shadow: none !important;
  outline: none !important;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &::after {
    content: '';

    position: absolute;
    right: 0;
    top: 50%;

    width: 12px;
    height: 12px;

    background: url('../../../../../../assets/img/common/arrow-gray.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%) rotate(90deg);

    transition: all 0.2s ease-in-out;
  }

  &.open::after {
    transform: translateY(-50%) rotate(270deg);
  }
}

.icon {
  width: 30px;
  height: 30px;

  &.selected {
    width: 40px;
    height: 40px;
  }
}

.text {
  margin: 0;

  font-weight: 400;
  font-size: 13px;
  color: #ffffff;

  &.title {
    font-weight: 600;
  }

  &.gray {
    color: #7074a4;
  }

  &.amount {
    margin: 3px 0 0;

    font-weight: 600;
  }
}

.currenciesWrapper {
  position: absolute;
  z-index: 20;
  bottom: -10px;

  display: flex;
  flex-direction: column;
  padding: 16px 0 0;

  border-radius: 8px;
  background: rgba(7, 11, 22, 0.92);
  backdrop-filter: blur(10px);
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);

  transition: all 0.2s ease-in-out;

  transform: translateY(100%);

  overflow: hidden;
  opacity: 0;
  list-style-type: none;

  &.open {
    opacity: 1;
  }
}

.currencies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 12px;
  margin: 0;
  width: 276px;
  row-gap: 2px;
  max-height: 50vh;

  overflow: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.currency {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 8px;

  &.selectable {
    cursor: pointer;

    &:hover {
      scale: 1.02;
      transition: all 0.2s ease-in-out;
    }
  }
}

.row {
  display: flex;
  align-items: flex-start;
}

.col {
  display: flex;
  flex-direction: column;
  margin: 3px 0 0;
}

.totalWrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;

  background: #161e31;
}

.totalRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 14px;

  &:last-child {
    margin: 0;
  }
}

.totalText {
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  color: #7074a4;
}

.totalAmountWrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.usdIcon {
  width: 24px;
  height: 24px;
}

.totalAmount {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
