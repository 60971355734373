.commonButton {
  height: 38px;
  min-height: 38px;
  color: #ffffff;
  border-radius: 19px;
  background-clip: padding-box;
  cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 13px;
  transition: 0.1s ease-in-out;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-clip: padding-box;
}

.secondary {
  background: transparent;
  border: 3px solid #5942ee;
  display: inline-block;
  box-shadow: -7.9602e-16px 0px 8px rgba(27, 14, 175, 0.75);

  &:hover {
    background: linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
    border: none;
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
  }

  &:active {
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)),
      linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    box-shadow: none;
  }
}

.secondaryInverted {
  display: inline-block;

  border: none;
  background: linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  box-shadow: -7.9602e-16px 0px 8px rgba(27, 14, 175, 0.75);

  &:hover {
    border: 3px solid #5942ee;
    background: transparent;
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
  }

  &:active {
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)),
      linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    box-shadow: none;
  }
}

.primary {
  background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
  border: none;
  box-shadow: -7.9602e-16px 0px 8px rgba(110, 175, 14, 0.75);

  &:hover {
    box-shadow: -7.9602e-16px 0px 24px rgba(110, 175, 14, 0.72);
    background: linear-gradient(0deg, rgba(147, 255, 138, 0.4), rgba(147, 255, 138, 0.4)),
      linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),
      linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    box-shadow: -7.9602e-16px 0px 24px rgba(110, 175, 14, 0.72);
  }

  &:disabled {
    background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    box-shadow: none;
    pointer-events: none;
    opacity: 0.7;
  }
}

.secondaryBold {
  background: linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  border: none;
  box-shadow: -7.9602e-16px 0px 8px rgba(27, 14, 175, 0.75);

  &:hover {
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
    background: linear-gradient(0deg, rgba(182, 159, 249, 0.4), rgba(182, 159, 249, 0.4)),
      linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  }

  &:active {
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)),
      linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  }

  &:disabled {
    box-shadow: none;
    pointer-events: none;
    opacity: 0.7;
    background: linear-gradient(90deg, #162152 3.45%, #371570 98.28%);
  }
}

.secondaryTiny {
  background: transparent;
  border: 2px solid #5942ee;
  display: inline-block;
  box-shadow: -7.9602e-16px 0px 8px rgba(27, 14, 175, 0.75);

  &:hover {
    background: linear-gradient(0deg, rgba(182, 159, 249, 0.4), rgba(182, 159, 249, 0.4)),
      linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
    border: none;
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
  }

  &:active {
    box-shadow: -7.9602e-16px 0px 24px rgba(27, 14, 175, 0.72);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)),
      linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    box-shadow: none;
  }
}

.primaryTiny,
.secondaryGreen {
  background: transparent;
  border: 2px solid #0e9628;
  display: inline-block;
  box-shadow: -7.9602e-16px 0px 8px rgba(110, 175, 14, 0.75);

  &:hover {
    background: linear-gradient(0deg, rgba(147, 255, 138, 0.4), rgba(147, 255, 138, 0.4)),
      linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    border: none;
    box-shadow: -7.9602e-16px 0px 24px rgba(110, 175, 14, 0.72);
  }

  &:active {
    box-shadow: -7.9602e-16px 0px 24px rgba(110, 175, 14, 0.72);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),
      linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    box-shadow: none;
  }
}

.secondaryRed {
  background: transparent;
  border: 2px solid #b30a0a;
  display: inline-block;
  box-shadow: -7.9602e-16px 0px 8px rgba(179, 10, 10, 0.75);

  &:hover {
    background: linear-gradient(180deg, #e81717 0%, #b30a0a 100%);
    border: none;
    box-shadow: -7.9602e-16px 0px 8px rgba(179, 10, 10, 0.75);
  }

  &:active {
    box-shadow: -7.9602e-16px 0px 8px rgba(179, 10, 10, 0.75);
    background: linear-gradient(180deg, #e81717 0%, #b30a0a 100%);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    box-shadow: none;
  }
}
