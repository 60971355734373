.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 30px 30px;
  max-width: 1400px;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main {
  display: grid;
  grid-template-columns: 3fr 323px;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
}

@media (max-width: 1090px) {
  .main {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 640px) {
  .wrapper {
    padding: 0 15px 30px;
  }
}
