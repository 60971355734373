.button {
  flex-grow: 4;
  height: 91px;


  h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    color: #ffffff;
    margin: 0;
  }

}

@media (max-width: 1200px) {

}

@media (max-width: 640px) {
  .button {
    min-height: 60px;
    height: 60px;
    h4 {
      font-size: 14px;
    }
  }
}
