.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  position: relative;
  padding: 10px 24px;

  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  transition: all 0.2s ease-in-out;

  border: none;
  border-radius: 8px;
  background-color: #0e9628;
  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  overflow: hidden;

  &:enabled:hover {
    transition: all 0.2s ease-in-out;
    opacity: 0.7;
  }

  &:disabled {
    background: rgb(95, 98, 110);
    pointer-events: none;
  }
}

.notAvailable {
  margin: 0px 0px 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.timer {
  display: flex;
  align-items: center;
}

.time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 56px;
  margin: 0 10px 0 0;

  border: none;
  border-radius: 8px;
  background-color: #0e9628;

  &:last-child {
    margin: 0;
  }
}

.unit {
  margin: 0px 0px 5px;

  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.label {
  margin: 0px;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  color: #c5d5ff;
}

.div {
  margin: 0 10px 0 0;

  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.12px;
  color: #ffffff;
}

.history {
  position: relative;
  margin: 10px 0 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;

  transition: all 0.2s ease-in-out;

  border: none;
  background: none;

  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  overflow: hidden;

  &:enabled:hover {
    transition: all 0.2s ease-in-out;
    opacity: 0.7;
  }
}

.wheelDisabled {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
