.loginWrap {
  height: 700px;
  width: 100%;
  display: flex;
}

.loginImage {
  width: 400px;
  height: 100%;
  background-image: url('../../../../../assets/img/PopUps/login-side-icon-1.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.loginCaptchaWrap {
  height: 270px;
}

.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: 30px 0;
}

.loginTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
}

.loginCaptcha {
  margin: 0 auto 10px;
  height: auto;
  display: flex;
  width: 100%;
  justify-content: center;
}

.loginForm {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 140px;
  width: 100%;
  margin-bottom: 10px;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0;
  }
}
.loginFormEmailIcon {
  position: absolute;
  height: 12px;
  width: 14px;
  left: 25px;
  top: 35%;
  z-index: 1;
}
.loginFormLockIcon {
  position: absolute;
  height: 15px;
  width: 14px;
  left: 25px;
  top: 30%;
  z-index: 1;
}
.loginFormAdditionalIcon {
  position: absolute;
  height: 14px;
  width: 14px;
  right: 25px;
  top: 30%;
  z-index: 1;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.loginErrorMessage {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  color: #f02f41;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 30px;
}

.loginFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px;
  position: relative;
  margin-top: -10px;
  span {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0 10px 0 0;
  }
}

.loginBtn1 {
  color: #ffb22d;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ffb22d;
  margin-left: 5px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.loginBtn2 {
  color: #888aa7;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #888aa7;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.button {
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .loginWrap {
    height: 490px;
  }
  .loginImage {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    white-space: nowrap;

    outline: 0;
    outline-offset: 0;
    border: 0;

    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
  .loginCaptchaWrap {
    height: auto;
  }
}
