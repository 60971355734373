.successWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 400px;
}

.successTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
  margin-top: 10px;
}

.successForm {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 170px;
  width: 100%;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 15px 0 0 0;
  }
}

.successImg {
  width: 332px;
  height: 193px;
}
