.wrapper {
  position: relative;

  width: 100%;
  height: 100%;
}

.wheelWrapper {
  position: absolute;
  left: 100%;
  top: 50%;

  width: 400px;

  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  transform: translate(-50%, -50%);
}

.wheel {
  z-index: 1;

  width: 380px;
  height: auto;

  &.active {
    transform: rotate(0deg);

    -moz-animation: spin both 6s cubic-bezier(0.68, 0.38, 0.3, 1);
    -webkit-animation: spin both 6s cubic-bezier(0.68, 0.38, 0.3, 1);
    -ms-animation: spin both 6s cubic-bezier(0.68, 0.38, 0.3, 1);
    -o-animation: spin both 6s cubic-bezier(0.68, 0.38, 0.3, 1);
    animation: spin both 6s cubic-bezier(0.68, 0.38, 0.3, 1);
  }
}

.pointer {
  position: absolute;
  z-index: 2;
  top: -26px;
  left: 50%;

  width: 158px;
  height: auto;

  transform: translateX(-50%);
}

.button {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;

  width: 90px;
  height: auto;

  transform: translate(-50%, -50%);

  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.text {
  position: absolute;
  z-index: 4;
  left: 50%;
  top: 50%;

  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;

  transform: translate(-50%, -50%);
  pointer-events: none;
}

.result {
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  transform: translate(-50%, -50%);
  pointer-events: none;
}

.winText {
  z-index: 6;

  margin: 0;

  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: -0.563976px;
  color: #ffffff;
}

@media (max-width: 1100px) {
  .wheelWrapper {
    left: 50%;

    width: 65%;
  }

  .wheel {
    width: 100%;
  }

  .pointer {
    top: -16px;

    width: 120px;
  }

  .button {
    width: 60px;
  }

  .text {
    font-size: 14px;
  }

  .winText {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 540px) {
  .pointer {
    top: -20px;
    width: 100px;
  }

  .button {
    width: 58px;
  }
}

@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(var(--wheel-rotate));
    -webkit-transform: rotate(var(--wheel-rotate));
    -ms-transform: rotate(var(--wheel-rotate));
    -o-transform: rotate(var(--wheel-rotate));
    transform: rotate(var(--wheel-rotate));
  }
}
