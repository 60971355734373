.kenoRiskAutoWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  min-width: 80px;
}

.kenoRiskDropWrap {
  display: flex;
  width: auto;
  flex-grow: 1;
}

@media (max-width: 640px) {
  .kenoRiskAutoWrap {
    height: auto;
    width: 100%;
    margin: 0;
  }
}
