.wrapper {
  width: 100%;
  position: relative;
  z-index: 2;

  width: 100%;

  font-size: 10px;

  border-radius: 16px;
  overflow: hidden !important;
}

.title {
  position: absolute;
  z-index: 2;
  top: 11%;
  left: 5%;

  margin: 0;
  padding: 5px;

  font-weight: 700;
  font-size: 3em;
  line-height: 28px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: #ffffff;

  border-radius: 4px;
}

.subtitle {
  position: absolute;
  z-index: 2;
  top: 40%;
  left: 5%;
  margin: 0;
  padding: 5px;

  font-weight: 400;
  font-size: 2.4em;
  line-height: 28px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: #ffffff;
}

h5.subtitle {
  top: 58%;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;
}
.bg.gamesBg {
  background: linear-gradient(270deg, rgba(60, 177, 64, 0) 0%, rgba(27, 110, 30, 0.4) 100%);
}
.dividendsBg {
  background: linear-gradient(270deg, rgba(21, 112, 195, 0) 0%, rgba(21, 112, 195, 0.4) 100%);
}
.referralBg {
  background: linear-gradient(270deg, rgba(40, 213, 193, 0) 0%, rgba(40, 213, 193, 0.4) 100%);
}
.cashbackBg {
  background: linear-gradient(270deg, rgba(202, 82, 30, 0) 0%, rgba(202, 82, 30, 0.4) 100%);
}

.wrapper {
  &:hover {
    overflow: hidden !important;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 16px 50px rgba(23, 196, 41, 0.4);
    .gamesBg {
      background: linear-gradient(270deg, rgba(60, 177, 64, 0) 0%, rgba(27, 110, 30, 0.7) 100%);
    }
    .gamesRoulette {
      transition: all 0.2s ease-in-out;
      bottom: -25%;
      right: -5%;
    }
    .gamesCherry {
      transition: all 0.2s ease-in-out;
      bottom: 66%;
      right: 37%;
    }
    .gamesFirstStar {
      transition: all 0.2s ease-in-out;
      right: 48%;
    }
    .gamesSecondStar {
      transition: all 0.2s ease-in-out;
      bottom: 69%;
    }
  }
}
.gamesRoulette {
  position: absolute;
  bottom: -30%;
  right: -10%;
  width: 50%;
  z-index: 1;
}
.gamesCherry {
  position: absolute;
  bottom: 46%;
  right: 27%;
  width: 10%;
  z-index: 1;
}
.gamesFirstStar {
  position: absolute;
  bottom: 10%;
  right: 34%;
  width: 10%;
  z-index: 1;
}
.gamesSecondStar {
  position: absolute;
  bottom: 47%;
  right: 8%;
  width: 8%;
  z-index: 1;
}

.wrapper {
  &:hover {
    overflow: hidden !important;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 16px 50px rgba(14, 199, 186, 0.4);
    .referralPeople {
      transition: all 0.2s ease-in-out;
      bottom: -52%;
      right: -16%;
    }
    .referralBg {
      background: linear-gradient(270deg, rgba(40, 213, 193, 0) 0%, rgba(40, 213, 193, 0.7) 100%);
    }
    .referralUsd {
      transition: all 0.2s ease-in-out;
      bottom: 63%;
      right: 23%;
    }
    .referralStar {
      transition: all 0.2s ease-in-out;
      right: 43%;
    }
  }
}
.referralPeople {
  position: absolute;
  bottom: -55%;
  right: -20%;
  width: 55%;
  z-index: 1;
}
.referralUsd {
  position: absolute;
  bottom: 43%;
  right: 13%;
  width: 15%;
  z-index: 1;
}
.referralStar {
  position: absolute;
  bottom: 4%;
  right: 26%;
  width: 10%;
  z-index: 1;
}

.wrapper {
  &:hover {
    overflow: hidden !important;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 16px 50px rgba(196, 116, 23, 0.4);
    .cashbackBg {
      background: linear-gradient(270deg, rgba(202, 82, 30, 0) 0%, rgba(202, 82, 30, 0.7) 100%);
    }
    .cashbackWallet {
      transition: all 0.2s ease-in-out;
      bottom: -10%;
      right: -5%;
    }
    .cashbackChart {
      transition: all 0.2s ease-in-out;
      bottom: 66%;
      right: 35%;
    }
    .cashbackStar {
      transition: all 0.2s ease-in-out;
      right: 40%;
    }
  }
}

.cashbackWallet {
  position: absolute;
  bottom: -15%;
  right: -10%;
  width: 45%;
  z-index: 1;
}

.cashbackChart {
  position: absolute;
  bottom: 46%;
  right: 15%;
  width: 14%;
  z-index: 1;
}

.cashbackStar {
  position: absolute;
  bottom: 6%;
  right: 25%;
  width: 11%;
  z-index: 1;
}

.wrapper {
  &:hover {
    overflow: hidden !important;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 16px 50px rgba(23, 103, 196, 0.4);
    .homeNavigationItemWrapDividendsBg {
      background: linear-gradient(270deg, rgba(21, 112, 195, 0) 0%, rgba(21, 112, 195, 0.7) 100%);
    }
    .dividendChart {
      transition: all 0.2s ease-in-out;
      bottom: -25%;
      right: -5%;
    }
    .dividendPercent1 {
      transition: all 0.2s ease-in-out;
      bottom: 66%;
      right: 32%;
    }
    .dividendPercent2 {
      transition: all 0.2s ease-in-out;
      right: 46%;
    }
    .dividendPercent3 {
      transition: all 0.2s ease-in-out;
      bottom: 66%;
    }
  }
}

.dividendChart {
  position: absolute;
  bottom: -30%;
  right: -10%;
  width: 45%;
  z-index: 1;
}
.dividendPercent1 {
  position: absolute;
  bottom: 46%;
  right: 22%;
  width: 11%;
  z-index: 1;
}
.dividendPercent2 {
  position: absolute;
  bottom: 6%;
  right: 26%;
  width: 13%;
  z-index: 1;
}
.dividendPercent3 {
  position: absolute;
  bottom: 45%;
  right: 3%;
  width: 8%;
  z-index: 1;
}

@media (max-width: 780px) {
  .wrapper {
    font-size: 1.2vw;
  }
}

@media (max-width: 500px) {
  .wrapper {
    font-size: 1.6vw;
  }
}
