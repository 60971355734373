.kenoBetContentWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #ffffff;
}

.kenoBetSquaresContent {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  margin: 15px auto;
  grid-gap: 4px;

  max-width: 450px;
  height: 250px;
}

.kenoSquareItemDetail {
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  justify-content: center;
  align-items: center;

  background: rgba(136, 138, 167, 0.15);
  mix-blend-mode: normal;
  border-radius: 8px;

  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.12px;
    color: #FFFFFF;
    margin: 0;
  }
  img {
    display: none;
  }
}

.kenoSquareItemDetail.kenoSquareItemDetailLose {
  background: #302267;
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.kenoSquareItemDetail.kenoSquareItemDetailWin {
  position: relative;
  background: transparent;
  border: 2px solid #791FED;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #232323;
    font-weight: 600;
  }
  img {
    display: inline-block;
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}


.kenoSquareItemDetail.kenoSquareItemDetailPick {
  background: linear-gradient(135.09deg, #791FED 0%, #AE5EFF 44.25%, #7720E7 99.96%);
  box-shadow: 0 5px 10px #450E8B;
}

@media (max-width: 540px) {
  .kenoBetSquaresContent {
    height: 200px;
  }
  .kenoSquareItemDetail {
    border-radius: 8px;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
