.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  font-family: "Montserrat";
  color: #ffffff;

  background-image: url("../../../assets/img/ErrorPage/stars.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.textWrapper,
.buttons {
  position: relative;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0 0 14px;

  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 88px;
  text-align: center;
  letter-spacing: -1px;
}

.desc {
  margin: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: -1px;
}

.imageWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 550px;
  min-height: 550px;
}

.planetWrapper {
  position: relative;
  z-index: 2;

  width: 300px;
  height: 300px;

  animation: planet 10s linear infinite;
}

.planet {
  position: absolute;

  width: 300px;
  height: auto;

  animation: planet 60s linear infinite;

  &::selection {
    pointer-events: none;
  }
}

.air {
  position: absolute;
  left: 50%;

  width: 600px;
  height: auto;

  transform: translateX(-50%);
}

.rocket {
  position: absolute;

  right: -50%;
  top: 50%;

  width: 100px;
  height: auto;

  transform: translateY(-50%);
}

.emoji {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: auto;

  opacity: 0;

  right: -50%;
  top: 50%;

  animation: emoji 10s ease-out infinite;

  transform: translateX(-200%);
}

.buttonsWrapper {
  display: flex;
  align-items: center;
}

.button {
  position: relative;
  margin-left: 1rem;

  & .tooltip {
    z-index: 3;
    position: absolute;
    bottom: 120%;
    left: calc(50% - 120px);

    display: none;
    align-items: center;
    justify-content: center;
    width: 240px;
    padding: 6px 12px;

    color: white;
    font-size: 13px;
    text-align: center;

    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    backdrop-filter: blur(10px);
    animation-duration: 200ms;
  }

  &:hover .tooltip {
    display: flex;
  }
}


@media (max-width: 768px) {
  .title {
    font-size: 62px;
    line-height: 78px;
  }

  .desc {
    font-size: 30px;
    line-height: 39px;
  }

  .imageWrapper {
    height: 400px;
    min-height: 400px;
  }

  .planetWrapper {
    width: 200px;
    height: 200px;
  }

  .planet {
    width: 200px;
  }

  .air {
    width: 400px;
  }

  .rocket {
    width: 70px;
  }

  .emoji {
    width: 18px;
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 42px;
    line-height: 28px;
  }

  .desc {
    font-size: 20px;
    line-height: 29px;
  }

  .planetWrapper {
    width: 150px;
    height: 150px;
  }

  .planet {
    width: 150px;
  }

  .air {
    width: 300px;
  }

  .rocket {
    width: 60px;
  }

  .emoji {
    width: 16px;
  }
}

@media (max-width: 375px) {
  .imageWrapper {
    height: 300px;
    min-height: 300px;
  }

  .buttonsWrapper {
    flex-direction: column;
  }

  .button {
    margin: 1rem 0 0;
  }
}

@keyframes planet {
  0%{
    transform:rotateZ(-0deg);
  }
  100%{
    transform:rotateZ(-360deg);
  }
}

@keyframes emoji {
  0%{
    opacity: 1;
    rotate: 0;
  }
  50% {
    rotate: 90deg;
    width: 0px;
    opacity: 0;
  }
  100%{
  }
}
