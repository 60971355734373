.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: #202D4A;
  overflow: hidden;
}

.icon {
  display: inline;
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.titleWrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  gap: 8px;
}

.titleItem {
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    margin: 0 5px 0 0 !important;
  }
}

.discount {
  background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
  border-radius: 19px;
  box-shadow: -0px 0px 8px 0px #6EAF0EBF;
  display: flex;
  border: 2px solid transparent;

  p {
    background: #202D4A;
    padding: 4px 8px;
    border-radius: 19px;
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 700;
    font-size: 11px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  padding: 16px;

  h4 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }
}

.bodyItem {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  p {
    color: #888AA7;
    
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }

  h5 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    width: 35px;
    height: auto;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}