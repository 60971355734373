.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 640px) {
  .wrapper {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}