.kenoAdditionalButtonsWrap {
  display: grid;
  width: 48.5%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  height: 50px;
}

.kenoAdditionalButtonPick,
.kenoAdditionalButtonTable {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;

  mix-blend-mode: normal;
  border-radius: 16px;

  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;

  cursor: url("../../../../../assets/img/common/cursor-pointer.webp"),auto !important;


  &:active,&:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.kenoAdditionalButtonPick {
  background: #424862;
  &:active, &:focus {
    background: linear-gradient(135.09deg, #791FED 0%, #AE5EFF 44.25%, #7720E7 99.96%);
  }
}

.kenoAdditionalButtonTable {
  background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
}

@media (max-width: 640px) {
  .kenoAdditionalButtonsWrap {
    height: 60px;
    margin: 0;
  }
  .kenoAdditionalButtonPick,
  .kenoAdditionalButtonTable {
    font-size: 12px;
    padding: 0 5px;
    font-weight: 400;
    min-width: 63px;
  }
}
