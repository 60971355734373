.wrapper {
  z-index: 20;
  position: fixed;
  left: 0;
  top: 74px;

  display: none;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 74px);
  height: -webkit-fill-available;
  padding: 24px 0 0;

  font-family: 'Montserrat';
  font-style: normal;
  color: white;

  background: #161E31;
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;

  width: 100%;
}

.title {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.button {
  display: flex;
  align-items: center;

  border: none;
  background: none;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.buttonIcon {
  margin: 0 8px 0 0;
  width: 24px;
  height: auto;

  fill: white;
}

.buttonText {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: white;

  &::selection {
    pointer-events: none;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 22px;
  margin: 18px 0 14px;

  svg {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
}

.notificationsContainer {
  flex-grow: 1;
  width: 100%;
  padding: 0 22px 12px;

  overflow: hidden;
  overflow-y: auto;
}

.date {
  margin: 0;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #A2A5CC;
  text-transform: capitalize;
}

.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.empty {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #A2A5CC;
}

@media (max-width: 1024px) {
  .wrapper {
    display: flex;
  }
}
