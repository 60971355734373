.buyTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .buyTitle {
    font-size: 18px;
  }
}
