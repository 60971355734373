.wrapper {
  width: 100%;

  overflow: unset;
}

.slide {
  width: 100%;
}

.card {
  position: relative;
  display: block;

  padding: 15px;
}
