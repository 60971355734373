.contentGamesPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contentGamesPageFilter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin: 30px 0 0;
}

.contentGamesPageTabs {
  display: flex;
  width: auto;
  color: #ffffff;
}

.contentGamesPageItem {
  width: 288px;
  height: 30px;
  position: relative;
}
.contentGamesPageItemLabel {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 14px;
  height: 14px;
}

.contentGamesPageItem:nth-of-type(2) {
  display: none;
}

.contentGamesPageItemInput {
  width: 288px;
  height: 38px;
  padding: 6px 50px 6px 20px;
  box-sizing: border-box;
  background: #00000033;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border: none;
  border-radius: 19px;
  &:focus {
    outline: none;
    border: 2px solid #6c5dd3;
    border-radius: 19px;
    background-color: #00000033;
    color: #ffffff;
    box-shadow: none;
  }
  &::placeholder {
    color: #ffffff;
  }
}

.contentGamesPageResult{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 50px;
}

@media (max-width: 1300px) {
  .contentGamesPage {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .contentGamesPageItem:nth-of-type(2) {
    display: flex;
    position: relative;
    margin: 20px 0;
  }
}

@media (max-width: 640px) {
  .contentGamesPageFilter {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0 0;
  }
}

@media (max-width: 500px) {
  .contentGamesPageItem {
    width: 100%;
    margin: 0;
  }
  .contentGamesPageItemInput {
    width: 100%;
  }
}
