.bonusCancelWrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(360deg, #202d4a 60%, #e3aa1a 100%);
  padding: 30px;
}

.bonusCancelImg {
  display: inline-block;
  width: 40%;
}

.bonusCancelTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.12px;

  color: #ffffff;
  margin: 30px 0 16px;
}

.bonusCancelDescription {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  color: #c1c5fb;
  margin: 0;
}

.error {
  font-family: 'Montserrat';

  font-weight: 500;
  font-size: 14px;
  color: red;
  min-height: 30px;
  margin: 16px 0 0 0 ;
}


.bonusCancelBtn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 0;
}

.bonusCancelBtnWrap {
  display: flex;
  width: 100%;
  &:nth-of-type(1) {
    margin-bottom: 20px;
  }
}

.button {
  text-transform: uppercase;
}
