.wrapper {
  z-index: 10;
  position: fixed;
  right: 1rem;
  top: 85px;

  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  list-style-type: none;
}