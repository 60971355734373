.depositBonusWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
}

.depositBonusSelection {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #888AA7 !important;
    margin: 0 0 6px;
  }
}

.depositBonusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: url("../../../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
  border-radius: 50%;
  background: #ffffff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: black;
  text-align: center;
  margin: 0 0 6px 5px;
}

.depositBonusInfo {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  background: rgba(7, 11, 22, 0.72);
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 16px 10px;
  color: #ffffff;
  z-index: 2;

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    img {
      height: 16px;
      margin-right: 8px;
    }
  }
  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 10px 0;
    text-align: left;
  }
}
