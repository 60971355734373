.cancelWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cancelTitle {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  text-transform: uppercase;
}

.cancelFormWrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.cancelForm {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 15px 0 0 0;
  }
}

.cancelFormText {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #888aa7;
  margin: 20px 0 0;
  display: flex;
  width: 288px;
  text-align: left;
}

.cancelBtnWrap {
  display: flex;
  margin-top: 30px;

  button:first-child {
    margin-right: 20px;
  }
}

.button {
  text-transform: uppercase;
}
