.kenoOddsWrap {
  height: 58px;
  margin: 5px 0 0;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  justify-content: space-between;
  grid-gap: 5px;
}

.kenoOddsEmpty {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #202D4A;
  border-radius: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  h4 {
    color: #FFFFFF;
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  h5 {
    text-align: center;
    width: 32px;
    height: 32px;
    background: linear-gradient(135.09deg, #791FED 0%, #AE5EFF 44.25%, #7720E7 99.96%);
    box-shadow: 0 5px 10px #450E8B;
    border-radius: 8px;
    margin: 0 10px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
  }
  h6 {
    text-align: center;
    width: 32px;
    height: 32px;

    background: rgba(136, 138, 167, 0.15);
    mix-blend-mode: normal;
    border-radius: 8px;
    margin: 0 0 0 10px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #FFFFFF;
  }
}

.kenoOddsItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  border: 2px solid rgba(255, 255, 255, 0.09);
  border-radius: 16px;

  height: 100%;
  h4 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
  }
  h5 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin: 0;
  }
}


.kenoOddsItem.kenoOddsItemActive {
  border: 2px solid #FFFFFF;
}

@media (max-width: 640px) {
  .kenoOddsWrap {
    height: 43px;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
    grid-gap: 2px;
  }
  .kenoOddsEmpty {
    color: #202D4A;
    h5, h6 {
     display: none;
    }
    h4 {
      font-size: 12px;
      width: 100%;
      text-align: center;
      line-height: 12px;
    }
  }
  .kenoOddsItem {
    border-radius: 8px;
    h4 {
      font-weight: 500;
      font-size: 7px;
      line-height: 14px;
    }
    h5 {
      font-size: 6px;
      line-height: 18px;
    }
  }
  .kenoOddsItem.kenoOddsItemActive {
    border-radius: 8px;
  }
}
