.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  background: #202D4A;
  border-radius: 20px;
  min-height: 437px;
  padding: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  overflow: hidden;
}

.pool {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
}

.poolHeader {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 8px;
  position: relative;
  h4 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }
}

.poolAmount {
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  margin: 21px auto;
  p{
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 45px;
    height: auto;
  }
  h3 {
    color: #ffffff;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 700;
    font-size: 40px;
  }
}


.poolPlanet {
  position: absolute;
  z-index: 1;
  max-width: 900px;
  min-width: 805px;
  width: 100%;
  bottom: -110%;
  left: -10%;
  animation: rotatePlanet 40s linear infinite;
  z-index: 0;
}
.poolStars {
  position: absolute;
  bottom: -100%;
  left: 0;
  z-index: 1;
  max-width: 650px;
  min-width: 600px;
  width: 100%;
  animation: rotatePlanet 40s linear infinite;
  z-index: 0;
}

@keyframes rotatePlanet {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.historyWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 1;
  h2{
    color: #888AA7;
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.history {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 333px;
  height: auto;
  border-radius: 8px;

  overflow: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #161E3180;
}

.history::-webkit-scrollbar {
  display: none;
}

.historyHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  // border-radius: 10px;
  min-height: 32px;
  background: #6D9DFB14;


  p{
    display: flex;
    width: 50%;
    padding: 0 0 0 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #888AA7;
    margin: 0;
  }
}

.historyContent {
  display: flex;
  width: 100%;
  min-height: 42px;
  // border-radius: 10px;

  &:nth-of-type(odd) {
    background: #070B1680;
  }
  &:nth-of-type(even) {
    background: #161E3180;
  }

  h4 {
    display: flex;
    width: 50%;
    padding: 0 0 0 20px;
    justify-content: flex-start;
    align-items: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0;


    color: #FFFFFF;

    img {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: -3px;
    }
  }
}

@media (max-width: 1100px) {
  .poolAmount {
    margin: 16px auto;
    img {
      width: 40px;
    }
    h3 {
      font-size: 30px;
    }
  }
}
@media (max-width: 900px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
  .poolAmount {  
    img {
      width: 35px;
    }
    h3 {
      font-size: 24px;
    }
  }
  .poolPlanet {
    bottom: -70%;
    left: -15%;
  }
  .poolStars {
    bottom: -60%;
    left: 5%;
  }
  .historyWrap {
    h2{
      color: #ffffff;
    }
  }
}


@media (max-width: 470px) {
  .historyHeader {
    p{
      width: 50%;
      padding: 0 0 0 10px;
      font-size: 14px;
    }
  }
  .historyContent {
    h4 {
      font-size: 12px;
      padding: 0 0 0 10px;
    }
  }
  .poolPlanet {
    bottom: -80%;
    left: -55%;
  }
  .poolStars {
    bottom: -70%;
    left: -45%;
  }
}