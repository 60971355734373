.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;
}

.label {
  margin: 0 0 7px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #888aa7;

  &.wm {
    margin: 0;
  }
}

.maxAmount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount {
  margin: 0 0 0 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  min-height: 50px;

  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: red;
}

.button {
  text-transform: uppercase;
}

.forbidden {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 60px 30px 30px;
}

.forbiddenText {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
}

.image {
  display: inline-block;
  width: 200px;
  margin: 30px 0 50px;
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .forbiddenText {
    font-size: 18px;
  }
}
