.allGamesHistoryWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 54px;
  padding: 0 15px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  overflow: hidden;
}

.allGamesHistoryTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;

  display: flex;
  align-items: center;
  color: #ffffff;
  margin-right: 25px;
}

.allGamesHistoryContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100%;
  overflow: hidden;
}

.allGamesHistoryContentElem {
  padding: 0 5px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(136, 138, 167, 0.15);
  mix-blend-mode: normal;
  margin: 0 3px;

  text-align: center;
}

.result {
  margin: 0;
  min-width: 30px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.win {
  color: #0e9628;
}

.loss {
  color: #e01f48;
}

.tie {
  color: #fccd00;
}

@media (max-width: 1300px) {
}
@media (max-width: 1250px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
  .allGamesHistoryWrap {
    padding: 0 10px;
  }
  .allGamesHistoryTitle {
    font-size: 16px;
    margin-right: 15px;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 768px) {
}
@media (max-width: 680px) {
}
@media (max-width: 580px) {
  .allGamesHistoryTitle {
    display: none;
  }
}
@media (max-width: 500px) {
}
@media (max-width: 400px) {
}
