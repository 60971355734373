.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  height: auto;

  aspect-ratio: 1340 / 343;
  object-fit: cover;

  overflow: hidden;
  border: none;

  &:hover {
    filter: brightness(1.2);
  }
}

@media (max-width: 1024px) {
  .wrapper {
    aspect-ratio: 963 / 333;
  }
}

@media (max-width: 700px) {
  .wrapper {
    aspect-ratio: 639 / 315;
  }
}
