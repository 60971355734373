.wallet {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 280px;
  min-width: 280px;
  z-index: 3;
  position: relative;
  height: 40px;

  background: transparent;
  border: 2px solid #202D4A;
  border-radius: 26px;
}

.walletCurrency {
  display: flex;
  justify-content: center;
  align-items: center;
}

.walletIcon {
  width: 16px;
  z-index: 1;
  cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.walletButton{
  cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  min-width: 116px;
  background: #202D4A;
  height: 40px;
  border-radius: 19px;
  transition: 0.2s ease-in-out;

  p {
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
    margin-left: 10px;
    text-transform: uppercase;
  }

  &:focus {
    border: 3px solid #313e57;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.walletMob {
  display: none;

  img {
    width: 24px;
    height: 24px;
    z-index: 1;
    cursor: url('../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }
}

@media (max-width: 500px) {

  //.wallet {
  //  width: 250px;
  //}

  //.walletMob {
  //  display: flex;
  //}
  //
  //.walletButton {
  //  display: none;
  //}
}
