.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.title {
  margin: 25px 0 10px 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #888aa7;
}

.amount {
  margin: 10px 0;

  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
}
