.footerScroll {
  position: absolute;
     bottom: 10px;
     right: 30px;
     img {
       width: 50px;
       height: 50px;
       cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
       transition: 0.2s ease-in-out;
       &:hover {
         transform: scale(1.2);
       }
     }
}

@media (max-width: 900px) {
  .footerScroll {
    display: none;
  }
}