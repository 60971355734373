@font-face {
  font-family: Montserrat;
  src: url(./Montserrat-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url(./Montserrat-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url(./Montserrat-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: Montserrat;
  src: url(./Montserrat-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: Montserrat;
  src:  url(./Montserrat-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: Montserrat;
  src: url(./Montserrat-Light.ttf);
  font-style: normal;
  font-weight: 300;
}


@font-face {
  font-family: OpenSans;
  src: url(./OpenSans.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: ViaodaLibre;
  src: url(./ViaodaLibre-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

