.dividendsWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 30px 30px;
  max-width: 1400px;
}

.pageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.block1 {
  display: grid;
  grid-template-columns: 3fr 323px;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
}

.block1NotAuth {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
}

.block2 {
  display: grid;
  width: 100%;
  margin: 0 0 30px 0;

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  z-index: 1;
}

@media (max-width: 900px) {
  .block1 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .block2 {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 640px) {
  .dividendsWrap {
    padding: 0 15px 30px;
  }
}
