.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 84px;
  background: linear-gradient(90deg, #8742EE 0%, #220A5B 100%);
  box-shadow: 0px 2.88px 72px 0px #6C59EE;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapperTitle {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 16px;
}

.wrapperTitleImage {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
}

.wrapperButton {
  display: flex;
  height: 100%;
  position: relative;
  padding: 0 16px 0 16px;
  align-items: center;
  justify-content: center;
}

.giftHandImage {
  height: 50%;
  width: auto;
  z-index: 1;
  margin-left: 16px;
}

.giftImage {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
  z-index: 0;
}

.confetiImage {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
  object-fit: cover;
  z-index: 0;
}

.title {
  margin: 0;
  z-index: 1;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}

.descriptionDesc {
  margin: 0;
  z-index: 1;
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
}

.descriptionMob {
  margin: 0;
  display: none;
  z-index: 1;
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
}

.button {
  padding: 0 24px;
  z-index: 1;
  color: #ffffff;
  background: linear-gradient(180deg, #eab62f 0%, #f7931a 100%);
  box-shadow: 0px 0px 10px rgba(247, 147, 26, 0.75);
  border-radius: 19px;
  border: none;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .descriptionDesc {
    display: none;
  }
  .descriptionMob {
    display: block;
  }
}

@media (max-width: 500px) {
  .wrapperTitle {
    padding: 0 0 0 8px;
  }
  .giftHandImage {
    margin-left: 4px;
  }
  .title {
    font-size: 14px;
  }
}
