.collectWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  background: radial-gradient(100% 60% at 50% 0%, rgba(179, 98, 3, 0.46) 0%, rgba(255, 212, 59, 0) 100%);
  margin-top: -20px;
  padding: 40px 30px 30px;
}

.collectImg {
  width: 180.05px;
  height: 190px;
  display: inline-block;
}

.collectTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: 30px 0;
  color: #eab52d;
}

.collectSlider {
  width: 100%;
  display: flex;
  border: 1px solid #424862;
}

.collectSliderScroll {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  height: 100%;
  max-height: 150px;

  overflow-y: auto;
  border: 8px solid transparent;
  scrollbar-width: thin;
  scrollbar-color: #424862 transparent;
}

/* Chrome, Edge, and Safari */
.collectSliderScroll::-webkit-scrollbar {
  width: 6px;
}

.collectSliderScroll::-webkit-scrollbar-track {
  background: transparent;
}

.collectSliderScroll::-webkit-scrollbar-thumb {
  background-color: #424862;
  border-radius: 5px;
}

.collectSliderItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 6px;
}

.collectSliderItemCurrency {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    display: inline-block;
    width: 35px;
  }
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #ffffff;
  }
}

.collectSliderItemFlag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #424862;
  border-radius: 10px;
  padding: 4px 8px;
  img {
    display: inline-block;
    width: 10px;
    margin: 0 4px 0 0;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    margin: 0;
    color: #99d290;
  }
}

.collectPara {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  margin: 20px 0;

  color: #ffffff;
}

.button {
  text-transform: uppercase;
}

@media screen and (max-width: 540px) {
  .collectSliderScroll {
    max-height: 130px;
  }
  .collectSliderItemCurrency {
    img {
      width: 30px;
      margin: 0 4px -2px 0;
    }
    h4 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
