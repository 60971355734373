.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #7074a4;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  list-style-type: none;

  &::selection {
    pointer-events: none;
  }
}

.break {
  margin: 0 0.25rem;
}

.page {
  margin: 0 0.25rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.pageLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;

  color: #7074a4;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-decoration: none;

  border: 1px solid transparent;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

.active {
  border: 1px solid #ffffff;
  border-radius: 10000px;

  a {
    color: #ffffff;
  }
}

.previous {
  height: auto;
  width: 8px;
  margin-right: 0.5rem;

  fill: currentColor;

  transform: rotate(180deg);

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

.next {
  height: auto;
  width: 8px;
  margin-left: 0.5rem;

  fill: currentColor;

  &:hover {
    fill: rgba(255, 255, 255, 0.8);
  }
}