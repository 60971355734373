.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  background: none;
  border: none;

  & .tooltip {
    z-index: 5;
    position: absolute;
    top: -10px;

    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: min-content;
    padding: 6px 12px;

    color: white;
    font-weight: 500;
    font-size: 13px;
    text-align: center;

    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    transform: translateY(-100%);
    backdrop-filter: blur(10px);
    animation-duration: 200ms;
  }

  &:hover .tooltip {
    display: flex;
  }
}
