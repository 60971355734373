.slider {
  border-radius: 20px;
}

.welcomeNavWrap {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin: 30px 0 0 0;
}

@media (max-width: 1200px) {
  .welcomeNavWrap {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}

@media (max-width: 1024px) {
  .welcomeSlideImgDkt {
    display: none !important;
  }

  .welcomeSlideImgTab {
    display: inline-block !important;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 700px) {
  .welcomeSlide {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .welcomeSlideImgTab {
    display: none !important;
  }
  .welcomeSlideImgMob {
    display: inline-block !important;
  }
}

@media (max-width: 650px) {
  .welcomeNavWrap {
    grid-gap: 15px;
  }
}
@media (max-width: 550px) {
  .welcomeNavWrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
