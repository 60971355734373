.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 8px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 28px;
  color: #ffffff;

  &:last-child {
    margin: 0;
  }
}

.alias,
.date {
  display: flex;
  align-items: center;
  width: 48.5%;
}

.avatar {
  margin: 0 10px 0 0;
  width: 26px;
  height: 26px;
}

.prize {
  display: flex;
  align-items: center;
  width: 48.5%;
}

.prizeIcon {
  width: 30px;
  height: auto;
}

.prizeAmount {
  display: flex;
  align-items: center;

  & span:first-child {
    margin: 0 10px 0 0;
  }
}
