.homePage {
  max-width: 1400px;
  width: 100%;
  padding: 30px;
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 23px;
  color: #ffff;
}

@media (max-width: 640px) {
  .homePage {
    padding: 30px 15px;
  }
}
