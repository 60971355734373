.button {
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: 11px;
  color: white;

  border: 3.5px solid transparent;
  border-radius: 20px;
  background-clip: padding-box;

  cursor: url("../../../assets/img/common/cursor-pointer.webp"), auto !important;
  user-select: none;
  touch-action: manipulation;

  transition: 0.1s ease-in-out;

  &:disabled{
    touch-action: none;
    pointer-events: none;
  }
}

.secondary {
  background-color: #2e245c;

  background-origin: padding-box, border-box;

  &:hover {
    box-shadow: -7.9602e-16px 0px 15px #7a2cff;
  }

  &:active {
    box-shadow: 4px 2px 8px 4px rgb(144 31 219 / 20%);
  }

  &:disabled {
    box-shadow: none;
    border: 3px solid #5f626e;
    pointer-events: none;
    touch-action: none;
  }
}

.bgSecondary {
  width: 100%;
  min-height: 30px;

  background: linear-gradient(to right, #7a2cff 25%, #3856dd 100%),
    linear-gradient(to right, #7a2cff 25%, #3856dd 100%);
  border-radius: 20px;
}
