.headerWrap {
  height: 75px;
  width: 100%;
  background: linear-gradient(0deg, #0F1522, #0F1522), rgba(0, 0, 0, 0.3);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 21;
}

@media (max-width: 858px) {
  .headerWrap {
    z-index: 6;
  }
}

@media (max-width: 768px) {
  .headerWrap {
    justify-content: space-between;
  }
}