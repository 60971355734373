.minesBetAmountBlockWrap {
  display: flex;
  flex-grow: 3;
}

@media (max-width: 640px) {
  .minesBetAmountBlockWrap {
    width: 100%;
  }
}
