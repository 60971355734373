.minesAutoSwitchWrap {
  display: flex;
  min-width: 60px;
  height: 90px;
  margin: 0 0 0 15px;
}

@media (max-width: 640px) {
  .minesAutoSwitchWrap {
    height: 60px;
    order: 4;
    max-width: 123px;
  }
}
