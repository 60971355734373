.toggleMfa {
  display: flex;

  input:checked + .toggleMfaSlider:before {
    transform: translateX(20px);
    background-color: white;
  }

  input:checked + .toggleMfaSlider {
    box-shadow: 0 0 0 2px #3C55DF, 0 0 8px #7A2DFE;
  }
}

.toggleMfaSwitch input {
  display: none;
}

.toggleMfaSwitch {
  position: relative;

  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggleMfaSlider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border: 4px solid transparent;
  border-radius: 30px;
  transition: 0.2s;
  box-shadow: 0 0 0 2px #888AA7, 0 0 4px #888AA7;

  cursor: pointer;
  overflow: hidden;
}

.toggleMfaSlider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #888AA7;
  border-radius: 30px;
  transform: translateX(-20px);
  transition: 0.2s;
}
