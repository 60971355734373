.link {
  display: flex;
  text-decoration: none;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  padding: 8px 12px;
  border-radius: 8px;
  background: #283041;
  cursor: url("../../../assets/img/common/cursor-pointer.webp"), auto;
  img {
    display: inline-block;
    width: 32px;
    margin-right: 15px;
  }
}

.title {
  font-family: 'Montserrat';
  color: #78839E;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
}

.subtitle {
  font-family: 'Montserrat';
  color: #FFF8E8;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
}