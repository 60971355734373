.wrapper {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  display: flex;
  align-items: center;

  margin: 0 0 12px;

  img {
    width: 18px;
    height: auto;
    
    margin-right: 12px;
  }

  ::selection {
    pointer-events: none;
  }
}

.title {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}

.games {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;

  width: 154px;
  max-height: 144px;
  padding: 5px;
  margin: 0;

  list-style-type: none;
  overflow: hidden;
  overflow-y: auto;
}

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    margin: 0 0 12px;

    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: #7074A4;
  }

  a {
    padding: 8px 16px;

    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    white-space: nowrap;
    color: #FFFFFF;

    background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
    border-radius: 19px;

    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: -7.9602e-16px 0px 15px rgba(110, 175, 14, 0.75);
      transition: all 0.2s ease-in-out;
    }
  }
}