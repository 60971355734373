.routWrap {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.routBg {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
}

.routBg.active {
  display: inline-block;
}