.minesSquaresWrap {

  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  margin: 0 auto;
  grid-gap: 5px;

  max-width: 375px;
  min-height: 295px;
}

.minesSquaresItemWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  min-width: 65px;
  border: none;
  outline: none;
  box-shadow: none;

  border-radius: 16px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  //margin: 5px;
  background: transparent;

  &:disabled {
    pointer-events: none;
  }
  &:focus, &:active {
    outline: none;
    box-shadow: none;
  }

  animation: appear 0.3s ease-in-out;
}

@keyframes appear {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

.square__active,
.square__win,
.square__default,
.square__lose,
.square__single,
.auto__selection {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  mix-blend-mode: normal;
  box-shadow: 0 5px 9px 5px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 16px;
}

.square__active {
  background: rgba(136, 138, 167, 0.15);
  animation: pumping 0.2s ease-in-out infinite alternate;
  img {
    width: 25px;
    height: 35px;
    opacity: 0.5;
    margin-bottom: -3px;
  }
}
@keyframes pumping {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.square__win {
  background: radial-gradient(78.2% 77.86% at 48.56% 48.93%, rgba(0, 94, 18, 0) 41.63%, rgba(14, 150, 40, 0.5) 100%);
  border: 3px solid #0E9628;
  animation: appear 0.3s ease-in-out;
  img {
    width: 50px;
    height: 50px;
    opacity: 1;
    margin-bottom: -3px;
  }
}

.square__lose, .square__single {
  background: rgba(22, 32, 53, 1);
  border: 2px solid rgba(22, 32, 53, 1);
  animation: appear 0.3s ease-in-out;
  img {
    width: 61px;
    height: 56px;
    opacity: 1;
  }
}
.square__single {
  border: 3px solid #ed5b12;
  img {
    width: 59px;
    height: 54px;
    opacity: 1;
  }
}

.square__finished {
  opacity: 0.3;
}

.square__default {
  background: rgba(136, 138, 167, 0.15);
  animation: appear 0.3s ease-in-out;
  img {
    width: 25px;
    height: 35px;
    opacity: 0.5;
    margin-bottom: -3px;
  }
  &:hover {
    background: rgba(136, 138, 167, 0.55);
    .square__default {
      img {
        opacity: 1;
      }
    }
  }
}
.auto__selection {
  background: #0E9628;
  transform: scale(0.92);
  transition: transform 0.1s ease-in-out;
  img {
    width: 25px;
    height: 35px;
    opacity: 1;
    margin-bottom: -3px;
  }
}




@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 900px) {
}
@media (max-width: 768px) {
}
@media (max-width: 640px) {
}
@media (max-width: 580px) {
}
@media (max-width: 500px) {
  .minesSquaresWrap {
    height: auto;
    max-width: 308px;
    min-width: 308px;
    min-height: 266px;
    grid-gap: 3px;
  }
  .minesSquaresItemWrap {
    min-width: 50px;
    height: 50px;
  }
  .square__default, .square__active, .auto__selection {
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    img {
      width: 20px;
      height: 27px;
    }
  }
  .square__win {
    border: 2px solid #0E9628;
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    img {
      width: 39px;
      height: 39px;
    }
  }
  .square__lose, .square__single {
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    img {
      width: 51px;
      height: 46px;
    }
  }
}
@media (max-width: 400px) {
}
