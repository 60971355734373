.tableWrapper {
  width: 100%;
  z-index: 3;
  margin: 30px 0 0;
  position: relative;
}

.tableWrapperItem {
  width: 100%;
  margin-top: 30px;
}
