.wrapper {
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40%;
  min-width: 350px;
  padding: 54px 24px;

  font-family: 'Montserrat';
  font-style: normal;
  color: #ffffff;

  transform: translateX(-100%);
  transition: transform 0.5s ease;

  background: rgba(7, 11, 22, 0.72);
  border-radius: 20px 0px 0px 20px;

  overflow: hidden;
  overflow-y: auto;
}

.wrapper.visible {
  transform: translateX(0);
}

.closeIcon {
  width: 14px;
  height: auto;

  fill: white;
}

.close {
  position: absolute;
  right: 24px;
  top: 14px;

  background: none;
  border: none;
  outline: none;
}

.header {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px;
}

.title {
  margin: 0;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.12px;
}

.select {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  padding: 10px 16px;

  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  border: none;
  background: rgba(7, 11, 22, 0.8);
  border-radius: 18px;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  outline: none;

  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.selectIcon {
  box-sizing: border-box;
  display: inline-block;
  width: 0;
  height: 0;
  margin: 7px;
  padding: 0;

  vertical-align: middle;
  color: #ffffff;

  border: 3px solid transparent;
  border-top: 3px solid;
  border-right: 3px solid;

  transform: rotate(135deg);

  &:after,
  &:before {
    content: '';
    box-sizing: border-box;
  }
}

.selectIcon.active {
  transform: rotate(-45deg);
}

.historyTypeList {
  position: absolute;
  right: 0;
  bottom: calc(-26px - 100%);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120px;
  margin: 0;
  padding: 10px 16px;

  list-style-type: none;
  border: none;
  background: rgba(7, 11, 22, 0.8);
  border-radius: 18px;
}

.historyTypeItem {
  width: 100%;

  margin: 0 0 6px;

  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: left;

  cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:hover {
    opacity: 0.8;
  }

  &:last-child {
    margin: 0;
  }
}

.historyWrapper {
  flex-grow: 1;
}

@media (max-width: 1100px) {
  .wrapper {
    top: unset;
    bottom: 0;

    width: 100%;
    height: 70%;
    padding: 34px 24px;

    transform: translateY(100%);
    border-radius: 0;
  }

  .wrapper.visible {
    transform: translateY(0);
  }

  .header {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .title {
    margin: 0 0 10px;
  }

  .select {
    width: 100%;
  }

  .historyTypeList {
    bottom: calc(10px - 100%);

    width: 100%;
  }
}
