.title {
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0 0;

  button:first-child {
    margin: 0 8px 0 0;
  }
}

.content {
  flex-grow: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 0 0 30px;
}

.error {
  margin: 10px 0;
  width: 100%;

  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: red;
}

.forbidden {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.forbiddenText {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
}

.image {
  display: inline-block;
  width: 200px;
  margin: 30px 0 50px;
}

.button {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 540px) {
  .forbiddenText {
    font-size: 18px;
  }
}
