.hotWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 300px;
  padding: 10px 30px 30px 30px;

  font-family: 'Montserrat';
  color: #ffffff;
}

.hotTitle {
  width: 100%;

  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.12px;
}

.hotImg {
  display: inline-block;
  width: 100%;
}

.hotContent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 15px 0;
}

.hotContentItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 50%;
  margin: 5px 0;

  h6 {
    border: 1px solid #7a2dfe;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 10px;
    border-radius: 8px;
    margin: 0 10px 0 0;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
}

.hotFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  p {
    margin: 0 15px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

.hotCheckboxWrap {
  position: relative;
  margin-top: -5px;
  height: 100%;
  input {
    opacity: 0;
    z-index: 1;
    border-radius: 19px;
    width: 42px;
    height: 21px;
    &:checked + .hotCheckboxLabel {
      border: 3px solid #7a2dfe;
      background: transparent;
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        margin-left: 25px;
        transition: 0.2s;
        background: #ffffff;
      }
    }
    &:disabled + .hotCheckboxLabel {
      pointer-events: none;
    }
  }
}
.hotCheckboxLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 30px;
  border-radius: 25px;
  background: transparent;
  border: 3px solid #888aa7;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    background: #888aa7;
    margin: 1px 3px 3px 5px;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
}

.hotClose {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
}

.button {
  text-transform: uppercase;
}

@media screen and (max-width: 540px) {
  .hotWrap {
    padding: 15px;
  }
  .hotContentItem {
    h6 {
      font-size: 12px;
      line-height: 18px;
      padding: 5px 10px;
    }

    h5 {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
