.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #c1c5fb;
}

.alias {
  width: 48.5%;
}

.prize {
  width: 48.5%;
}

.noWins {
  margin: auto 0;
}