.wrapper {
  width: 100%;
  height: 100%;
}

.gameBetButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  height: 100%;

  background: linear-gradient(356.38deg, #0E4D96 2.57%, #2B79D5 93.2%);
  border-radius: 16px;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  color: #ffffff;

  touch-action: manipulation;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    touch-action: none;
  }
  &:active {
    transform: scale(0.98);
  }
  &:hover {
    filter: brightness(110%);
  }
}
