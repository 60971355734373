.wrapper {
  position: relative;

  opacity: 1;
  display: flex;
  align-items: center;
  width: 355px;
  height: auto;
  padding: 14px 16px;
  margin-bottom: 10px;

  font-family: 'Montserrat';

  background: rgba(22, 32, 54, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 8px;

  transform: translateX(0);
  transition: all 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }
}

.transform {
  transform: translateX(400px);
  transition: all 0.2s ease-in-out;
}

.hidden {
  opacity: 0;
  transition: all 2s ease-in-out;
}


.border, .borderGold {
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  width: 6px;
  height: 100%;

  background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.borderGold {
  width: 3px;
  background: #E8AD17;
}

.imageWrapper {
  position: relative;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: 8px;

  background: rgba(255, 203, 153, 0.24);
  border-radius: 100%;
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;

  width: 24px;
  height: auto;

  transform: translate(-50%, -50%);
}
.bonusCustomImage {
  display: inline-block;
  width: 50px;
  margin-right: 15px;
}

.contentWrapper {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  height: max-content;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title, .customBonusTitle {
  margin-bottom: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #99D290;
}
.customBonusTitle {
  text-transform: uppercase;
}

.description {
  margin-bottom: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;

  color: #ffffff;

  img {
    width: 30px;
    height: auto;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    vertical-align: middle;
  }
}

.date {
  margin-bottom: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;

  color: #7074A4;
}

.red {
  color: #EE4D6A;
}

.removeButton {
  position: absolute;
  right: 16px;
  top: 10px;

  border: none;
  background: none;

  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.removeIcon {
  width: 18px;
  height: auto;

  fill: white;
}
