.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  width: 54px;
  min-height: 54px;
  height: 54px;
  margin: 0 14px 0 0;

  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 20px;

  transition: height 0.1s ease-in-out;

  cursor: pointer;
}

.icon {
  width: 24px;
  height: auto;
}

.active {
  transition: height 0.1s ease-in-out;
  background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);
}

@media (max-width: 370px) {
  .wrapper {
    margin: 0 7px 0 0;
  }
}
