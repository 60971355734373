.gamesRulesWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 220px;
  padding: 10px 30px 30px 30px;

  font-family: 'Montserrat';
}

.rulesTitle {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.rulesTitleItem {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.12px;
  position: relative;

  color: #ffffff;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.rulesWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
}

.rulesSlider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 239px;
    border-radius: 16px;
    object-fit: cover;
  }
}

.ruleDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 80px 0 30px;
}

.ruleDescriptionTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
}

.ruleDescriptionTitle.active {
  opacity: 1;
  visibility: visible;
  max-height: 300px;
  transition: opacity 0.7s ease-in-out;
  min-height: 80px;
}

.button {
  text-transform: uppercase;
}

@media screen and (max-width: 540px) {
  .gamesRulesWrap {
    padding: 15px 15px 40px;
  }
  .rulesSlider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 180px;
    }
  }
}
