.footerInfo {
  display: grid;
  height: auto;
  width: 60%;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  padding: 0 15px 0 30px;
}

.footerInfoBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footerInfoBlockTitle {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin: 0;
  }
  h3 {
    display: none;
  }
}

.footerInfoBlockItemWrap {
  display: flex;
  flex-direction: column;
  height: auto;
}

.footerInfoBlockItem {
  width: max-content;
  margin-bottom: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #888aa7;
  text-decoration: none;
  text-align: left;

  background: none;
  border: none;

  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  img {
    width: 12px;
    opacity: 0.5;
    margin-left: 5px;
  }
}

@media (max-width: 900px) {
  .footerInfo {
    width: 100%;
    grid-template-columns: 1fr;
  }
  .footerInfoBlock {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #888aa7;
    &:last-of-type {
      border-bottom: 1px solid #888aa7;
    }
  }
  .footerInfoBlockTitle {
    margin: 10px 0;
    h3 {
      width: 26px;
      height: 26px;
      display: flex;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 26px;
      color: #ffffff;
      margin: 0;
      cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
      align-items: center;
      justify-content: center;
    }
  }
  .footerInfoBlockItemWrap {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  .footerInfoBlockItemWrap.open {
    max-height: 300px;
  }
}
