.bonusAmountInfoWrap {
  display: flex;
}

.bonusAmountInfoAvailable {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #FFFFFF;
  background: #424862;
  border-radius: 8px;
  padding: 2px 4px;
  margin: 0 2px;
}

.bonusAmountInfoWrong {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #EE4D6A;

  background: rgba(238, 77, 106, 0.16);
  border-radius: 8px;
  padding: 2px 4px;
  margin: 0 2px;
}
