.footerSupport {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  flex-wrap: nowrap;
  padding: 30px 0;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
}

.providerSlide {
  display: flex;
  align-items: center;
}

.providerBlock {
  width: 200px;
}

.providerImage {
  width: 120px !important;
  height: auto;
}

@media (max-width: 900px) {
  .slider {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .slider {
    width: 90%;
  }
}
