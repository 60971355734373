.dropSearchWrap {
  display: flex;
  width: 100%;
  position: relative;
}

.dropSearchWrap.disable {
  opacity: 0.5;
  pointer-events: none;
}

.dropSearchHeaderPara {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  color: #ffffff;
}

.dropSearchHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 38px;
  background: #00000033;
  border-radius: 19px;
  cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
  padding-left: 20px;
  &:hover {
    transition: 0.2s ease-in-out;
    border: 2px solid #6c5dd3;
  }
}

.dropSearchI {
  border: 1px solid #888aa7;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 3px;
  margin-right: 30px;
  position: absolute;
  top: 12px;
  right: 0;
  cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
  transition: all 0.3s ease-in-out;
}

.dropSearchI.iUp {
  margin-top: 3px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}
.dropSearchI.iDown {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 3px;
}

.dropSearchBody {
  visibility: hidden;
  flex-direction: column;
  width: 100%;
  height: 0;
  background-color: #000000;
  transition: all 0.1s ease-in-out;
  transition-duration: 000ms;
  transition-delay: 000ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  margin-top: 10px;
  border-radius: 24px;
  overflow: hidden;
  opacity: 0;


  position: absolute;
  top: 40px;
  max-height: 0;
}
.dropSearchBodyOpen {
  visibility: visible;
  opacity: 0.94;
  transition: all 0.3s ease-in-out;
  transition-duration: 100ms;
  transition-delay: 100ms;
  z-index: 5;
  padding: 10px 15px 20px 0;

  height: auto;
  max-height: 400px;
}

.dropSearchBodyPara {
  width: 100%;
  cursor: url('../../../assets/img/common/cursor-pointer.webp'), auto !important;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #ffffff;
  &:hover {
    color: #ffa608;
  }
}

.dropSearchBodyInputWrap {
  display: flex;
  width: 100%;
  position: relative;
  margin: 5px 0 10px;
  padding: 0 0 0 10px;
  img {
    position: absolute;
    top: 50% !important;
    right: 10px !important;
    transform: translateY(-50%) !important;
    width: 16px !important;
    height: auto !important;
  }
}

.dropSearchBodyInput {
  width: 100%;
  background: #202D4A;
  border-radius: 19px;
  height: 38px;
  border: none;
  outline: none;
  box-shadow: none;
  color: #ffffff;

  padding: 0 0 0 15px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.dropSearchBodyInput::placeholder {
  color: #7074A4;
}
