.launcher {
  position: fixed;
  bottom: 3rem;
  right: 0;
  z-index: 30;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 9999px;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);
  background: linear-gradient(to bottom right, #3856dd, #7a2cff);
  padding: 0.25rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-in-out;
}

.hidden {
  display: none;
}

.launcher:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.launcher:active {
  transform: translate(-50%, -50%) scale(0.75);
}

@media (min-width: 768px) {
  .launcher {
    bottom: 0;
  }
}
