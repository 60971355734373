.wrapper {
  padding: 18px 16px !important;
  font-family: 'Montserrat', sans-serif;;
  color: #FFFFFF;
  background: #202D4A !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.25);

  opacity: 1!important;

  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
