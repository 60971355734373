.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.title {
  color: #888AA7;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin: 0;
}

.total {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 40px;
    height: auto;
    display: block;
  }

  p {
    color: #ffffff;
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 400;
    margin: 0;
  }
}

.totalCurrency {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0;
}

.totalCurrencyTitle {
  color: #888AA7;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin: 0;
}

.currency {
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: auto;
    margin-right: 3px;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    margin: 0;
    min-width: 100px;
  }
}

.currencyList {
  background: #070B16B8;
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
}

.currencyListItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.currencyListItemTitle{
  color: #888AA7;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  margin: 0;
}