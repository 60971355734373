.gamesNavInput {
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.gamesNavInputArea {
  width: 100%;
  background: #151E31;
  border-radius: 20px;
  border: none;
  box-shadow: none;
  height: 40px;
  z-index: 2;

  padding: 0 0 0 15px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.gamesNavInputArea:active, .gamesNavInputArea:focus {
  border: none;
  outline: none;
}

.gamesNavInputArea::placeholder {
  color: #ffffff;
}

.searchIcon {
  position: absolute;
  width: 15px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  z-index: 1;
}

.xButton {
  position: absolute;
  width: 10px;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  z-index: 1;
}

.xButton.hidden {
  display: none;
}

@media (max-width: 800px) {
  .searchIcon {
    display: none !important;
  }
}
