.kenoCheckboxAutoWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 60px;
  height: 90px;
  margin: 0 0 0 15px;
}

@media (max-width: 640px) {
  .kenoCheckboxAutoWrap {
    height: 60px;
  }
}

