.bonusTab {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  padding: 10px 20px;
  background: #161E31;
  border-radius: 8px;

  img {
    display: inline-block;
    width: 15px;
    margin: 0 3px 3px 0;
  }
  p {
    cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    transition: 0.2s ease-in-out;
    margin: 0;
    &:hover {
      color: #ED9700;
    }
  }

}

.bonusTabActive {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
  border-radius: 20px;
  padding: 10px 20px;
  p {
    cursor: url('../../../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    margin: 0;
    &:hover {
      color: #ffffff;
    }
  }
}

@media (max-width: 900px) {
  .bonusTab,
  .bonusTabActive{
    p {
      font-size: 14px;
      margin-right: 2px;
    }
    img {
      width: 13px;
    }
  }
}
