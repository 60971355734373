.depositTitle {
  margin: 0 0 20px 0;

  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-transform: uppercase;
}

.depositCurrencyWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.depositCurrencyItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  p {
    color: #888aa7 !important;
  }
}

.depositCurrencyTitle {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #888aa7;
  margin: 0 0 6px 0;
}

.depositContentWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.depositContentCopyWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.depositAddressWrap {
  display: flex;
  width: 100%;
  background-color: #00000033;
  border-radius: 19px;
  height: 38px;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: 0 0 15px;
  p {
    width: 288px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    overflow: hidden;
    margin: 0;
  }
}

.depositTooltip {
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 10px;

  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;

  visibility: hidden;
  background: #808080;
  border-radius: 19px;
  transform: translateY(0);
  transition: all 0.1s ease-in-out;
  transition-duration: 200ms;
  transition-delay: 200ms;
}
.depositTooltip.depositTooltipOpen {
  visibility: visible;
  transform: translateY(-170%);
}
.depositTooltipIcon {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  &:active {
    border: 2px solid #00000033;
  }
}

.depositContentMinimumAmount {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #888aa7;
  margin: 0;
  p {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    margin: 0 5px 0 5px;
  }
}

.depositQr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #00000033;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}
.depositQrBorder {
  min-width: 110px;
  min-height: 110px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
}
.depositQrCode {
  z-index: 2;
  height: 110px !important;
  width: 110px !important;
  border-radius: 8px !important;
}
.depositQrContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 4%;
  flex-grow: 2;
  margin-left: 10px;
  border-radius: 8px;
  height: 100%;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid #424862;
  font-family: 'Montserrat';
  h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
    margin: 0;
    img {
      height: 16px;
      margin-right: 5px;
    }
  }
  h6 {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    margin: 0;
    text-align: left;
  }
}

.depositLink {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 10px 0;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

@media (max-width: 800px) {
  .depositCurrencyWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }

  .depositCurrencyItem {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 48.5%;
  }

  .depositCurrencyTitle {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    margin: 0 0 6px 0;
  }
}

@media (max-width: 768px) {
  .depositTitle {
    font-size: 18px;
  }
}

@media (max-width: 640px) {
  .depositAddressWrap {
    p {
      width: 208px;
    }
  }
}
