.avatarsWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 500px;
}

.avatarsTitle {
  margin: 10px 0 30px;

  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
}

.avatarsMode {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px;
}

.avatarsModeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  width: auto;
  border: none;
  box-shadow: none;
  outline: none;
  background: #161e31;
  border-radius: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  color: #ffffff;
  &:nth-of-type(2) {
    margin: 0 0 0 15px;
  }
}

.avatarsModeBtn.avatarsModeDefault {
  background: linear-gradient(90deg, #3856dd 3.45%, #7a2cff 98.28%);
}
.avatarsModeBtn.avatarsModePremium {
  background: linear-gradient(90deg, #b38f30 3.45%, #ddc153 98.28%);
}

.avatarsBody {
  display: flex;
  width: 100%;
  max-width: 410px;
  max-height: 360px;
  flex-wrap: wrap;
  position: relative;
  padding-right: 15px;
  margin-left: 20px;
  scrollbar-color: #50566a transparent;
  scrollbar-width: thin !important;
}

.avatarsBodyElem {
  width: 25%;
  height: 98px;

  .avatarsBodyElemImg {
    width: 100%;
    height: 100%;
    cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }
  .focus {
    border: 4px solid #ffffff;
    outline: none;
  }
}

.avatarsBodyShadow {
  position: sticky;
  bottom: -10px;
  width: 100%;
  box-shadow: 10px 10px 50px 70px #242c45;
}

.avatarsButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0;
}

.disableScroll {
  pointer-events: none;
}

.avatarsBodyClose {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    text-align: center;
    letter-spacing: 0.12px;

    background: linear-gradient(180deg, #e1c559 0%, #b28e32 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    text-align: center;

    color: #ffffff;
  }
  img {
    display: inline-block;
    height: 40%;
    margin: 30px 0;
  }
}

.button {
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .avatarsBody {
    max-width: 300px;
  }
  .avatarsBodyElem {
    height: 68px;
  }
}
