.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px;

  text-decoration: none;

  background: linear-gradient(90deg, rgba(122,44,255,1) 35%, rgba(56,86,221,1) 100%);
  border-radius: 14px;

  span {
    padding: 6px 12px;

    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    white-space: nowrap;
    color: white;

    background: #202d4a;
    border-radius: 13px;
  }

  &:hover {
    filter: drop-shadow(-7.9602e-16px 0px 8px rgba(27, 14, 175, 0.75));

    span {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}