.homeGamesTitle {
  margin: 50px auto 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 30%;
    transform: translateX(-50%);
    height: 2px;
    background: #ED9700;
    border-radius: 2px;
  }
}

.homeGamesCategories {
  display: none;
  width: 100%;
  margin: 15px 0;
  position: relative;
  img {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 14px;
    height: 14px;
  }
}

.homeGamesInput {
  width: 100%;
  height: 38px;
  padding: 6px 50px 6px 20px;
  box-sizing: border-box;
  background: #00000033;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border: none;
  border-radius: 19px;
  &:focus {
    outline: none;
    border: 2px solid #6c5dd3;
    border-radius: 19px;
    background-color: #00000033;
    color: #ffffff;
    box-shadow: none;
  }
  &::placeholder {
    color: #ffffff;
  }
}

.homeGamesSliders {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 60vh;
}

@media (max-width: 1024px) {
  .homeGamesCategories {
    display: flex;
    margin: 30px 0 0;
  }
}

@media (max-width: 900px) {
  .homeGamesTitle {
    font-size: 24px;
    margin: 30px auto 10px;
  }
}
