.currencyWrap {
  display: flex;
  border: 1px solid #424862;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  max-height: 162px;
}

.currencyScrollWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  max-height: 160px;
  overflow-y: auto;
  border: 8px solid transparent;
  scrollbar-width: thin;
  scrollbar-color: #424862 transparent;
  width: 100%;
  height: 100%;
}

/* Chrome, Edge, and Safari */
.currencyScrollWrap::-webkit-scrollbar {
  width: 6px;
}

.currencyScrollWrap::-webkit-scrollbar-track {
  background: transparent;
}

.currencyScrollWrap::-webkit-scrollbar-thumb {
  background-color: #424862;
  border-radius: 5px;
}

.currencyItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 40px;
  }
  .currencyToken {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 0;
  }
}
.currencyItem.currencyItemSmall {
  img {
    width: 28px;
  }
  .currencyToken {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
  }
}

@media (max-width: 1500px) {
  .currencyWrap {
    max-height: 130px;
  }
  .currencyScrollWrap {
    max-height: 126px;
  }
  .currencyItem {
    img {
      width: 30px;
    }
    .currencyToken {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

@media (max-width: 1350px) {
  .currencyScrollWrap {
    max-height: 126px;
  }
  .currencyItem {
    img {
      width: 30px;
    }
    .currencyToken {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media (max-width: 640px) {
  .currencyWrap {
    max-height: 240px;
  }
  .currencyScrollWrap {
    grid-template-columns: repeat(1, 1fr);
    max-height: 238px;
    grid-gap: 5px;
  }
  .currencyItem {
    img {
      width: 30px;
    }
    .currencyToken {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
