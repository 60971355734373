.head {
  position: sticky;
  z-index: 10;
  top: 0;

  width: 100%;

  background: #263658;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.headCell {
  padding: 18px 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #6987c7;

  &:first-child {
    padding-left: 24px;

    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  &:last-child {
    padding-right: 24px;

    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  & div {
    width: max-content;
  }
}
