.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 24px;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 18px;
}

.avatar {
  width: 66px;
  height: 66px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.alias {
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #ffffff;
}

.rank {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.rankTitle {
  padding: 4px 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 15.4719px;
  line-height: 19px;
  color: #fccd00;

  background: linear-gradient(180deg, rgba(214, 145, 21, 0.4) 0%, rgba(214, 145, 21, 0.16) 100%), #424862;
  border-radius: 4px;
}

.rankVip {
  padding: 4px 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 15.4719px;
  line-height: 19px;
  color: #795620;

  background: linear-gradient(90deg, #ffffb2 0%, #ebbb2e 100%), #424862;
  border-radius: 4px;
}

.result {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  column-gap: 8px;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.resultItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  width: 100%;
  max-width: 170px;

  border-radius: 8px;
  background: #161e31;
}

.subtitle {
  margin: 0 0 4px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7074a4;
}

.subcontent {
  display: flex;
  align-items: center;
  column-gap: 4px;

  & img {
    width: 20px;
    height: 20px;
  }
}

.text {
  margin: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #ffffff;
}

@media (max-width: 850px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .result {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 660px) {
  .wrapper {
    align-items: flex-start;
  }

  .result {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .resultItem {
    flex-grow: 1;
    max-width: calc(50% - 4px);
  }

  .resultItem:first-child {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-grow: 0;
    max-width: unset;

    & p.subtitle {
      margin: 0;
    }
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 340px) {
  .resultItem {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: unset;

    & p.subtitle {
      margin: 0;
    }
  }
}
