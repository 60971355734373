.player {
  display: flex;
  align-items: center;
  column-gap: 6px;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.avatar {
  width: 24px;
  height: 24px;
}

.alias {
  margin: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
