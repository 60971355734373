.kenoGameWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 15px 30px;
  min-height: 100vh;
}

.kenoGameContentWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.kenoGameContentWrap > div:nth-of-type(2) {
  margin: 0;
}

.kenoGameContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  padding-right: 30px;
}

.kenoGameContentHeader {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(200px, 100%) minmax(min-content, auto);
  grid-column-gap: 15px;
  margin-bottom: 15px;
}
.kenoGameContentHeader > div:nth-of-type(2) {
  margin: 0;
}

.kenoGameContentMain {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
.kenoGameContentOdds {
  display: flex;
  width: 100%;
  margin-top: 10px;
}
.kenoGameContentNavigation {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}
.kenoGameContentNavigationSubmitWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 48.5%;
}
.kenoGameContentNavigationFooterWrap {
  display: inline-flex;
  width: 100%;
  gap: 15px;
}

.kenoGameAutoSettings {
  display: grid;
  grid-template-columns: 1fr;
  width: calc(100% - 375px);
}

.kenoGameTableWrap {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
}

@media (max-width: 1260px) {
  .kenoGameContent {
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
  .kenoGameAutoSettings {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .kenoGameWrap {
    padding: 15px;
  }
  .kenoGameContentHeader {
    margin-bottom: 10px;
  }
  .kenoGameContentOdds {
    margin-top: 6px;
  }
  .kenoGameContentNavigation {
    gap: 10px;
    margin-top: 10px;
  }
  .kenoGameContentNavigationSubmitWrap {
    width: 100%;
  }
  .kenoGameAutoSettings {
    width: 100%;
    min-width: 100%;
  }
}
