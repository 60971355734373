.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 38px;
  width: 100%;
  max-width: 1400px;

  color: #ffffff;
}
