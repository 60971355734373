.changePassSuccess {
  font-family: 'Montserrat';
  padding: 25px 0;
  color: #fff;
  margin: auto;
  max-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.changePassSuccessTitle {
  margin-bottom: 35px;
  font-weight: 800;
  font-size: 36px;
  text-transform: uppercase;
}
.changePassSuccessSubTitle {
  margin-bottom: 30px;
  max-width: 270px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
}
.changePassSuccessImg {
  max-width: 300px;
  max-height: 300px;
}

.button {
  text-transform: uppercase;
}

@media screen and (max-width: 540px) {
  .changePassSuccess {
    padding: 25px 25px 15px 25px;
  }
}
