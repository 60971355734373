.wrapper {
  width: 100%;
  height: max-content;
}

.range {
  display: flex;
  height: 72px;
  width: 100%;

  border-radius: 20px;
  cursor: auto !important;
}

.rangeLine {
  display: flex;
  height: 18px;
  width: 100%;

  border-radius: 20px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &.disabled {
    opacity: 0.7;
  }
}

.rangeThumb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 72px;

  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 36px;

  border-radius: 50%;
  background-color: #000000;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  border: 2px solid #344876 !important;
  box-shadow: none !important;
  outline: none !important;

  &:focus{
    border: 2px solid #344876 !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &:active{
    border: 2px solid #344876 !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.disabled {
  pointer-events: none;
}

@media (max-width: 700px) {
  .rangeLine {
    height: 14px;
  }

  .rangeThumb {
    height: 56px;
    width: 56px;

    font-size: 24px;
  }
}

@media (max-width: 700px) {
  .range {
    height: 62px;
  }
}
