.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 300px;

  color: #ffffff;

  background: #202d4a;
  border-radius: 16px;
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  max-width: 100%;

  overflow: hidden;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.image {
  height: 100%;
  width: auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;

  overflow: hidden;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  margin: 0 0 8px;

  font-size: 0.9rem;
  overflow: hidden;
}

.titleLink {
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    opacity: .8;
  }
}


.desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  font-weight: 500;
  font-size: 0.83rem;
  line-height: 1rem;
  color: #c1c5fb;

  overflow: hidden;
}

.link {
  position: relative;

  display: flex;
  align-items: center;
  width: max-content;
  margin-top: auto;

  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;

  span {
    position: relative;
    z-index: 2;

    padding: 3px 13px;
    width: 100%;
    height: 100%;

    background: #202d4a;
    border-radius: 16px;
  }

  &:hover {
    color: white;
    box-shadow: -7.9602e-16px 0px 15px #7a2cff;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: -3px;
    top: -3px;

    padding: 3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);

    background: rgb(56, 86, 221);
    background: linear-gradient(
      90deg,
      rgba(56, 86, 221, 1) 0%,
      rgba(122, 44, 255, 1) 100%
    );
    border-radius: 16px;
  }
}

@media (max-width: 700px) {
  .wrapper.last {
    display: none;
  }
}

@media (max-width: 500px) {
  .wrapper.last {
    display: flex;
  }
}