.wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.item {
  margin-right: 0.25rem;

  &::after {
    content: " /";

    color: #7074a4;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      content: '';
    }
  }
}

.link {
  text-decoration: none;

  &:hover .title {
    color: rgba(255, 255, 255, 0.7);
  }
}

.title {
  color: #7074a4;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
