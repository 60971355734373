.addressWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 400px;
  padding: 30px;
}

.addressTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
}

.addressHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
}

.addressHeaderItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  margin-bottom: 20px;

  h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #888aa7;
  }
}

.addressWallets {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  min-height: 150px;
}

.addressWalletsTitle {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 7px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #888aa7;
}

.addressError {
  display: flex;
  width: 100%;
  min-height: 20px;
  justify-content: center;
  margin: 5px 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 10px;
  color: #ff274b;
}

.addressWalletsEmpty {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 17px;

  color: #888aa7;
}

.button {
  padding: 0 12px;

  text-transform: uppercase;
}

@media (max-width: 768px) {
  .addressTitle {
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .addressWrap {
    padding: 15px 15px 30px;
  }
}
