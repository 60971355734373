.footerSocialWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  padding: 30px;
}

@media (max-width: 900px) {
  .footerSocialWrap {
    justify-content: center;
    width: 100%;
  }
}