.minesGameWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 15px 30px;
  min-height: 100vh;
}

.minesGameContentWrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.minesGameContentWrap > div:nth-of-type(2) {
  margin: 0;
}

.minesGameContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  padding-right: 30px;

  width: calc(100% - 375px);
}

.minesGameContentHeader {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(200px, 100%) minmax(min-content, auto);
  grid-column-gap: 15px;
  margin-bottom: 15px;
}

.minesGameContentMain {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.minesGameContentSlider {
  display: flex;
  width: 100%;
  margin-top: 15px;
}

.minesGameContentDropdown {
  display: flex;
  width: 100%;
  margin-top: 5px;
}

.minesGameContentNavigation {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
  margin: 15px 0 0;
}
.minesGameContentNavigationSubmitWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 4;
  min-width: 190px;
  max-width: 406px;
}

.minesGameAutoSettings {
  display: grid;
  grid-template-columns: 1fr;
  width: calc(100% - 375px);
  margin-top: 15px;
}

.minesGameTableWrap {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
}




@media (max-width: 1260px) {
  .minesGameContent {
    width: 100%;
    min-width: 100%;
    padding: 0;
  }
  .minesGameAutoSettings {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .minesGameWrap {
    padding: 15px;
  }
  .minesGameContentHeader {
    margin-bottom: 10px;
  }
  .minesGameContentDropdown {
    order: 3;
    margin-top: 0;
  }
  .minesGameContentNavigation {
    gap: 10px;
    margin: 10px 0 0;
  }
  .minesGameContentNavigationSubmitWrap{
    max-width: 100%;
  }
  .minesGameAutoSettings {
    width: 100%;
    min-width: 100%;
  }
  .minesGameContentSlider {
    margin-top: 10px;
  }
}
