.autoSwitchWrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  border: none;
  outline: none;
  box-shadow: none;
  cursor: url("../../../../assets/img/common/cursor-pointer.webp"), auto !important;

  background: #202D4A;
  mix-blend-mode: normal;
  border-radius: 16px;

  padding: 10px;

  .autoSwitchCircle {
    margin: 0;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 2px solid #424862;
    background: transparent;
  }

  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    margin: 0 0 0 10px;
  }

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    filter: brightness(110%);
  }
  &:disabled {
    opacity: 0.7;
    cursor: auto !important;
  }
}

.autoSwitchWrap.autoSwitchWrapActive {
  background: linear-gradient(180deg, #0E9628 0%, #7DB30A 100%);
  .autoSwitchCircle {
    border: none;
    background: #ffffff;
  }
}

@media (max-width: 1300px) {
  .autoSwitchWrap {
    h5 {
      font-size: 16px;
    }
  }
}
@media (max-width: 1200px) {
  .limboSubmitButtonText {
    font-size: 14px;
  }
}
//@media (max-width: 1100px) {
//  .limboSubmitButtonText {
//    font-size: 14px;
//  }
//}
//@media (max-width: 1024px) {
//  .limboSubmitButtonText{
//    font-size: 14px;
//  }
//}
