.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 960px;
  padding: 24px;
  max-height: 90vh;
}

.title {
  margin: 0 0 24px;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.loader {
  position: absolute;
  z-index: 12;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  min-height: 100%;

  background: #242c45;
}

@media (max-width: 1020px) {
  .wrapper {
    max-width: 90vw;
  }
}
