.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0 30px 30px;
  max-width: 1400px;
}

.pageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.block1 {
  display: grid;
  grid-template-columns: 3fr 323px;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
}

.block1NotAuth {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
}



.cardWrapper {
  display: grid;
  width: 100%;

  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  grid-gap: 30px;
  margin-bottom: 30px;
  z-index: 5;
}

@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 1100px) {
  .cardWrapper {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 900px) {
  .block1 {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 640px) {
  .wrapper {
    padding: 0 15px 30px;
  }
}
@media (max-width: 580px) {
}
@media (max-width: 500px) {
}
@media (max-width: 400px) {
}
