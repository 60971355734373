.homeStatisticsWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  max-width: 1340px;
  font-size: 10px;
  width: 100%;
}

.homeStatisticsTitle {
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeStatisticsTitleLine {
  width: 90px;
  height: 2px;
  border-radius: 1px;
  background-color: #ed9700;
}

.homeStatisticsCards {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-top: 30px;
}

.homeStatisticsCardsItem {
  height: 102px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px 0 0 32px;

  width: 100%;
}

.homeStatisticsCardsItemTitle {
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
  h3 {
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 500;
    color: #ffffff;
    margin: 0;
    z-index: 2;
    font-size: 16px;
    opacity: 0.7;
  }
}

.homeStatisticsCardsItemImg {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 500;
    color: #ffffff;
    margin: -10px 0 0 0;
    z-index: 2;
    font-size: 22px;
  }
}

.currencyStatistic {
  width: 48px;
  height: 48px;
  margin: -10px 0 0 -10px;
}

.currencyStatisticAccount {
  width: 30px;
  height: 30px;
  margin: 0 10px 10px 0;
}

.statisticsImg {
  position: absolute;
  width: 55%;
  height: 67%;
  right: -14px;
  bottom: -9px;
  z-index: 0;
}
.statisticsAccount {
  width: 50%;
  height: 57%;
  position: absolute;
  bottom: -3px;
  right: -3px;
  z-index: 0;
}

@media (max-width: 1350px) {
  .homeStatisticsCardsItem {
    padding: 10px 0 0 10px;
  }
}

@media (max-width: 1240px) {
  .currencyStatistic {
    width: 42px;
    height: 42px;
  }

  .currencyStatisticAccount {
    width: 24px;
    height: 24px;
  }

  .homeStatisticsCardsItemImg p {
    font-size: 18px;
  }

  .homeStatisticsCardsItemTitle h3 {
    font-size: 14px;
  }

  .statisticsImg {
    width: 60%;
  }

  .statisticsAccount {
    right: -5px;
    width: 55%;
  }
}

@media (max-width: 950px) {
  .homeStatisticsCards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .homeStatisticsCardsItem {
    padding: 10px 0 0 32px;
  }

  .currencyStatistic {
    width: 48px;
    height: 48px;
  }

  .currencyStatisticAccount {
    width: 30px;
    height: 30px;
  }

  .homeStatisticsCardsItemImg p {
    font-size: 22px;
  }

  .homeStatisticsCardsItemTitle h3 {
    font-size: 16px;
  }

  .statisticsImg {
    width: 55%;
  }

  .statisticsAccount {
    right: -3px;
    width: 50%;
  }
}

@media (max-width: 640px) {
  .homeStatisticsWrap {
    margin-top: 0;
  }
}
