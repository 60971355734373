.diceAutoSwitchWrap {
  display: flex;
  min-width: 60px;
  height: 91px;
  margin: 0 0 0 15px;
}

@media (max-width: 640px) {
  .diceAutoSwitchWrap {
    height: 60px;
    order: 4;
  }
}
