.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;

  & .tooltip {
    z-index: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(110%);

    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: min-content;
    padding: 6px 12px;

    color: white;
    font-weight: 500;
    font-size: 13px;
    text-align: center;

    background: #070b16b8;

    border-radius: 20px;

    backdrop-filter: blur(10px);
    animation-duration: 200ms;
  }

  &:hover .tooltip {
    display: flex;
  }
}

.tooltip {
  width: auto;
}

.tooltipText {
  width: auto;
}

.info {
  fill: #ffffff;
  opacity: 0.5;

  cursor: pointer;
  width: 18px;
  height: auto;

  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}
