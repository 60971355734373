.rulesWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 300px;
  padding: 10px 30px 30px 30px;

  font-family: 'Montserrat';
}

.rulesTitle {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.rulesTitleItem {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.12px;
  position: relative;

  color: #ffffff;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}
.rulesTitleItem.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 100%;
  background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
}

.limitsWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 30px 0 0 30px;
}
.limitsContent {
  display: flex;
  width: 48.5%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    margin: 0 0 10px;
  }
}
.limitsElemWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 35px;
    margin-left: -8px;
  }
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;
  }
}

@media screen and (max-width: 540px) {
  .rulesWrap {
    padding: 15px;
  }

  .limitsElemWrap {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 30px;
      margin-left: -8px;
    }
    h4 {
      font-size: 12px;
    }
  }
}
