.slotCardWrap {
  position: relative;
  width: 100%;
  font-size: 10px;
  border-radius: 20px;

  &:hover {
    .live {
      display: none;
    }
  }

  background: #202d4a;
}

.slotCardLock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 34, 51, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  z-index: 4;
  border-radius: 20px;
  overflow: hidden;
  padding: 10%;

  img {
    display: inline-block;
    width: 40px !important;
    height: 40px;
  }
  h6 {
    color: #ffffff;
    cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    margin: 5px 0 0;
    text-align: center;
  }
}

.slotCardLabel {
  position: absolute;
  top: 4%;
  right: 3%;
  height: 20%;
  width: auto !important;
  z-index: 3;
}

.slotCardBg {
  width: 100%;
  border-radius: 20px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  height: 100%;

  display: inline-block;
  max-height: 200px;
  aspect-ratio: 312 / 200;
  object-fit: cover;
}

.slotCardGift {
  position: absolute;
  right: 3%;
  bottom: 4%;
  height: 20%;
  width: auto !important;
  visibility: hidden;
  z-index: 3;
}

.slotCardFreeSpins,
.slotCardDemoNoBonus,
.games__card-soon,
.slotCardDemo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  z-index: 2;
  padding: 10px;
  font-size: 1.6em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    visibility: hidden;
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 1.6em;
    color: #ffffff;
    margin: 0;
  }
}
.slotCardFreeSpins {
  box-shadow: 0 0 10px 5px #ed9700;
}

.slotCardDropsIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 25% !important;
  z-index: 1;
}
.slotCardProvider {
  position: absolute;
  bottom: 20px;
  left: 20px;
  visibility: hidden;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.4em;
  color: #ffffff;
  margin: 0;
  z-index: 2;
}

.slotCardButton {
  background: transparent;
  border: none;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  box-shadow: none;
  outline: none;
  position: absolute;
  top: 20px;
  right: 20px;
  visibility: hidden;
  z-index: 3;
  width: 15%;
  &:hover,
  &:active,
  &:disabled {
    box-shadow: none;
    border: none;
    outline: none;
  }
  &:disabled {
    cursor: none;
    pointer-events: none;
  }

  img {
    width: 100%;
  }
}

.slotCardWrap {
  &:hover,
  &:active {
    .slotCardBg {
      filter: blur(1px);
    }
    .games__card-soon,
    .slotCardDemo,
    .slotCardDemoNoBonus,
    .slotCardFreeSpins {
      visibility: visible;
      width: 100%;
      height: 100%;
      z-index: 2;
      box-shadow: 0 0 10px 5px #0e9628;
      padding: 10px;
      background-color: rgba(27, 34, 51, 0.8);
      p {
        visibility: visible;
      }
    }
    .slotCardLabel {
      display: none;
    }
    .slotCardFreeSpins {
      box-shadow: 0 0 10px 5px #ed9700;
    }
    .slotCardDemoNoBonus {
      box-shadow: 0 0 10px 5px red;
    }
    .slotCardProvider,
    .slotCardGift,
    .slotCardButton {
      visibility: visible;
    }
  }
}

.slotCardWrap.slotBlocked {
  &:hover {
    .slotCardFreeSpins,
    .slotCardDemoNoBonus,
    .games__card-soon,
    .slotCardDemo {
      pointer-events: none;
      cursor: none;
      box-shadow: none;
      background: none;
      p {
        visibility: hidden;
      }
    }
    .slotCardBg {
      filter: none;
      cursor: none;
      pointer-events: none;
    }
    .slotCardProvider,
    .slotCardGift,
    .slotCardButton {
      visibility: hidden;
    }
    .slotCardLabel {
      display: inline-block;
    }
    .slotCardLock {
      //pointer-events: auto;
    }
  }
}

@media (max-width: 1300px) {
  .slotCardWrap {
    font-size: 9px;
  }
}
@media (max-width: 1200px) {
  .slotCardBg {
    max-height: 152px;
  }
}
@media (max-width: 1024px) {
  .slotCardBg {
    max-height: 192px;
  }
}
@media (max-width: 940px) {
  .slotCardWrap {
    font-size: 7px;
  }
  .slotCardLock {
    img {
      width: 30px !important;
      height: 30px;
    }
    h6 {
      font-size: 12px;
    }
  }
}
@media (max-width: 840px) {
}
@media (max-width: 768px) {
}
@media (max-width: 650px) {
  .slotCardWrap {
    font-size: 5px;
    grid-gap: 15px;
  }
  .slotCardBg {
    max-height: 120px;
  }
}
@media (max-width: 550px) {
  .slotCardWrap {
    &:hover,
    &:active {
      .games__card-soon,
      .slotCardDemo,
      .slotCardDemoNoBonus,
      .slotCardFreeSpins {
        visibility: visible;
        width: 100%;
        height: 100%;
        z-index: 2;
        box-shadow: 0 0 5px 2px #0e9628;
        padding: 10px;
      }
    }
  }
  .slotCardBg {
    max-height: 182px;
  }
}
@media (max-width: 450px) {
}
@media (max-width: 400px) {
  .slotCardBg {
    max-height: 115px;
  }
}
