.preRegisterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 270px;
  margin-right: 30px;

  a {
    color: white;
    font-family: 'Montserrat';
    font-weight: 500;
    text-decoration: underline;
  }
}

.button {
  text-transform: uppercase;
}

@media (max-width: 500px) {
  .preRegisterWrap {
    width: 70%;
  }
}
