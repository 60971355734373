.footerDescription {
  display: flex;
  width: 40%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 15px;
  flex-direction: column;
}

.footerDescriptionItem1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.footerDescriptionStop {
  width: 30px;
  height: 30px;
  border: 2px solid #e01f48;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.footerDescriptionStop1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.footerDescriptionItem2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
}

@media (max-width: 900px) {
  .footerDescription {
    order: 3;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 100%;
    padding: 0 30px;
  }
  .footerDescriptionItem1 {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .footerDescriptionItem2 {
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

}