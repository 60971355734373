.hotKeyBlackJack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 300px;
  padding: 10px 30px 30px 30px;

  font-family: 'Montserrat';
  color: #ffffff;
}

.hotKeyBlackJackImage {
  display: inline-block;
  width: 100%;
  opacity: 0;
  animation: displayOption 1s ease-in-out forwards;
  min-height: 100px;
}

.hotKeyBlackJackTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}

.hotKeyBlackJackTopicWrap {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}
.hotKeyBlackJackTopicItem {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7074a4;
  margin-right: 10px;
  padding: 5px 0;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  border: none;
}
.hotKeyBlackJackTopicItemActive {
  color: #ffffff;
  border-bottom: 2px solid #5942ee;
}

.hotKeyBlackJackOptionsWrap {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  min-height: 130px;
}
.hotKeyBlackJackOptionsItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: displayOption 1s ease-in-out forwards;
  opacity: 0;
  margin: 0 0 10px;
  min-width: 50%;
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin: 0 10px 0 0;
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid #7a2dfe;
  }
  h5 {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    text-align: left;
  }
}

@keyframes displayOption {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hotKeyBlackJackFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  p {
    margin: 0 15px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}
.hotKeyBlackJackInputWrap {
  position: relative;
  margin-top: -5px;
  height: 100%;
  input {
    opacity: 0;
    z-index: 1;
    border-radius: 19px;
    width: 42px;
    height: 21px;
    &:checked + .hotKeyBlackJackInputLabel {
      border: 3px solid #7a2dfe;
      background: transparent;
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        margin-left: 25px;
        transition: 0.2s;
        background: #ffffff;
      }
    }
    &:disabled + .hotKeyBlackJackInputLabel {
      pointer-events: none;
    }
  }
}
.hotKeyBlackJackInputLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 30px;
  border-radius: 25px;
  background: transparent;
  border: 3px solid #888aa7;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    background: #888aa7;
    margin: 1px 3px 3px 5px;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
}

.hotKeyBlackJackClose {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
}

.button {
  text-transform: uppercase;
}

@media screen and (max-width: 540px) {
  .hotKeyBlackJack {
    padding: 15px;
  }
}
