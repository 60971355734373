.wrapper {
  position: relative;

  width: 100%;
  padding: 30px 14px;
}

.gradient {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;

  width: 100%;
  height: 280px;

  background: linear-gradient(180deg, rgba(167, 0, 208, 0.54) -15.89%, rgba(156, 0, 230, 0) 100%);
}

.content {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0 0 24px;

  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
  color: #ffffff;
}

.slider {
  margin: 0 0 30px;
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #c1c5fb;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0 0 32px;
  row-gap: 4px;

  list-style-type: none;
}

.listItem {
  display: inline-flex;
  align-items: center;
  padding: 8px;
  width: max-content;
  column-gap: 5px;

  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #c1c5fb;

  border-radius: 8px;
  background: #161e31;

  & img {
    width: 24px;
    height: 24px;
  }

  & span.white {
    color: white;
  }
}

.listAdditional {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  padding: 0;
  margin: 0 0;
  gap: 12px;

  list-style-type: none;
}

.listAdditionalItem {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px;

  & img {
    width: 20px;
    height: 20px;
  }

  & span {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #c1c5fb;
  }
}
