.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  width: 100%;
  padding: 0;
  margin: 0;

  list-style-type: none;

  & *::selection {
    pointer-events: none;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  overflow: hidden;
}

.icon {
  width: 22px;
  height: auto;
  margin: 0 10px 0 0;
}

.text {
  margin: 0px;

  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: inherit;

  white-space: nowrap;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 2px;
}

@media (max-width: 1100px) {
  .list {
    grid-gap: 6px;
    padding: 10px 0 0;
  }
  .item {
    flex-direction: column;
  }

  .icon {
    margin: 0 0 10px;
  }

  .text {
    font-size: 10px;
    line-height: 18px;
  }
}
