.gamesNavItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 15px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  flex-shrink: 0;
  img {
    display: inline-block;
    width: 26px;
    border-radius: 50%;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #556B9A;
    margin: 0 0 0 10px;
  }

  &:hover {
    p {
      color: #ffffff;
    }
  }
}

.gamesNavItem.active {
  background: rgba(0, 0, 0, 0.34);
  border-radius: 14px;

  p{
    color: #ffffff;
  }
}

.gamesNavItem:nth-of-type(1).active {
  img {
    box-shadow: 0 0 25px -3px #7226C0;
  }
}
.gamesNavItem:nth-of-type(2).active {
  img {
    box-shadow: 0 0 25px -3px #417124;
  }
}
.gamesNavItem:nth-of-type(3).active {
  img {
    box-shadow: 0 0 25px -3px #3035B1;
  }
}
.gamesNavItem:nth-of-type(4).active {
  img {
    box-shadow: 0 0 25px -3px #B52741;
  }
}
.gamesNavItem:nth-of-type(5).active {
  img {
    box-shadow: 0 0 25px -3px #E47414;
  }
}
.gamesNavItem:nth-of-type(6).active {
  img {
    box-shadow: 0 0 25px -3px #E09419;
  }
}
.gamesNavItem:nth-of-type(7).active {
  img {
    box-shadow: 0 0 25px -3px #179234;
  }
}
.gamesNavItem:nth-of-type(8).active {
  img {
    box-shadow: 0 0 25px -3px #CA484B;
  }
}

@media (max-width: 1250px) {
  .gamesNavItem {
    flex-direction: column;
    padding: 7px 10px;
    p {
      font-size: 14px;
      line-height: 17px;
      margin: 0;
    }
  }
}

@media (max-width: 600px) {
  .gamesNavItem {
    flex-direction: column;
    padding: 5px 10px;
    img {
      width: 20px;
    }
    p {
      font-size: 12px;
      line-height: 14px;
      margin: 0;
    }
  }
}

@media (max-width: 370px) {
  .gamesNavItem {
    flex-direction: column;
    padding: 5px 10px;
    p {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

