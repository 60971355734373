.limboOptionInputWrap {
  width: 157px;
  height: 91px;
  background: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;


  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #888AA7;
    margin-bottom: 4px;
  }
}

.limboOptionInputContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.limboOptionInputContentBtn {
  width: 21px;
  height: 21px;
  background: rgba(136, 138, 167, 0.15);
  border-radius: 4px;
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 5px 0;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 21px;
  color: #ffffff;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;

  &:hover, &:active, &:focus {
    box-shadow: none;
    outline: none;
  }
  &:disabled {
    pointer-events: none;
  }
}

.limboOptionInputContentInput {
  width: 60px;
  cursor: url('../../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  border: none;
  background: transparent;
  height: 33px;
  padding: 0 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;

  text-align: center;
  letter-spacing: 0.09px;

  color: #FFFFFF;

  &:hover, &:active, &:focus {
    box-shadow: none;
    outline: none;
    border: none;
    outline: none;
  }
  &:disabled {
    pointer-events: none;
  }
}

@media (max-width: 1300px) {
}
@media (max-width: 1250px) {
  .limboOptionInputWrap {
    width: 127px;
    height: 91px;

    h3 {
      font-size: 10px;
    }
  }
  .limboOptionInputContentInput {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .limboOptionInputWrap {
    width: 117px;
    height: 91px;
  }
  .limboOptionInputContentInput {
    font-size: 16px;
  }
}
@media (max-width: 1100px) {
  .limboOptionInputWrap {
    width: 157px;
    height: 91px;

    h3 {
      font-size: 12px;
    }
  }
  .limboOptionInputContentInput {
    font-size: 22px;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 768px) {
  .limboOptionInputWrap {
    width: 100%;
    height: 51px;

    h3 {
      font-size: 14px;
      margin: 0;
    }
  }
  .limboOptionInputContent {
    flex-direction: row;
  }
  .limboOptionInputContentBtn {
    margin: 0 10px;
  }
  .limboOptionInputContentInput {
    font-size: 16px;
  }
}
@media (max-width: 700px) {
}
@media (max-width: 640px) {
  .limboOptionInputContentBtn {
    margin: 0 10px;
  }
  .limboOptionInputContentInput {
    font-size: 16px;
  }
}
@media (max-width: 580px) {
}
@media (max-width: 500px) {
}
@media (max-width: 400px) {
}
