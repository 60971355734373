.autoBetNewWrap {
  background: linear-gradient(to right, #7a2cff 25%, #3856dd 100%);
  background-origin: padding-box, border-box;
  background-repeat: no-repeat;
  border-radius: 16px;
  margin-top: 10px;
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
}

.autoBetNewWrap.autoBetsOpen {
  height: 228px;
}

.autoBetNewContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 10px;
  margin: 4px;
  background-color: #18213d;
  border-radius: 16px;
  justify-content: space-around;

  color: #fff;
}

.autoBetNewContainer.autoBetsDisable {
  pointer-events: none;
}

.autoBetNewItem {
  margin: 10px 0;
  width: 30%;
  display: flex;
  flex-direction: column;
  counter-reset: order-counter;
  &:nth-child(1) {
    order: 1;
  }
  &:nth-child(2) {
    order: 2;
  }
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(4) {
    order: 4;
  }
  &:nth-child(5) {
    order: 5;
  }
  &:nth-child(6) {
    order: 6;
  }
}

.autoBetNewCheckboxWrap {
  position: relative;
  height: 100%;
  input {
    opacity: 0;
    z-index: 1;
    border-radius: 19px;
    width: 42px;
    height: 21px;
    &:checked + .autoBetNewCheckboxLabel {
      background: linear-gradient(to right, #7a2cff 25%, #3856dd 100%),
      linear-gradient(to right, #7a2cff 25%, #3856dd 100%);
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        transition: 0.2s;
      }
    }
  }
}
.autoBetNewCheckboxLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background: #bebebe;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 4px 3px 3px 5px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
}
.autoBetNewFieldIcon {
  position: absolute;
  right: 8px;
  top: 7px;
}



@media (max-width: 680px) {
  .autoBetNewWrap.autoBetsOpen {
    height: 313px;
  }
  .autoBetNewItem {
    width: 45%;
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 4;
    }
    &:nth-child(4) {
      order: 2;
    }
    &:nth-child(5) {
      order: 5;
    }
    &:nth-child(6) {
      order: 6;
    }
  }
}

@media (max-width: 400px) {
  .autoBetNewWrap.autoBetsOpen {
    height: 295px;
  }
  .autoBetNewItem {
    h6 {
      font-size: 0.67em;
    }
  }
}
