.gamesPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: 1400px;
  padding: 0 30px 30px;
}

@media (max-width: 640px) {
  .gamesPage {
    padding: 0 15px 30px;
  }
}
