.kenoSquaresWrap {
  display: grid;
  width: 100%;
  background: radial-gradient(64.72% 64.72% at 50% 72.66%, #201246 0%, #000516 100%);
  padding: 8px 0;
}

.kenoSquaresContent {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  margin: 0 auto;
  grid-gap: 8px;

  max-width: 550px;
  min-height: 295px;
}

.kenoSquareItem,
.kenoSquareItemFast {
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  justify-content: center;
  align-items: center;

  background: rgba(136, 138, 167, 0.15);
  mix-blend-mode: normal;
  border-radius: 16px;

 p {
   font-family: 'Montserrat';
   font-weight: 400;
   font-size: 18px;
   line-height: 22px;
   text-align: center;
   letter-spacing: 0.12px;
   color: #FFFFFF;
   margin: 0;
 }
  img {
    display: none;
  }

  &:hover {
    cursor: url("../../../../../assets/img/common/cursor-pointer.webp"), auto !important;
    transform: scale(0.95);
  }
  &:disabled {
    pointer-events: none;
  }
}

@keyframes kenoSquareScale {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.kenoSquareItem.kenoSquareItemLose,
.kenoSquareItemFast.kenoSquareItemLoseFast {
  background: #302267;
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}
.kenoSquareItem.kenoSquareItemLose {
  animation: kenoSquareScale 0.4s ease-in-out;
}

.kenoSquareItem.kenoSquareItemPick,
.kenoSquareItemFast.kenoSquareItemPickFast {
  background: linear-gradient(135.09deg, #791FED 0%, #AE5EFF 44.25%, #7720E7 99.96%);
  box-shadow: 0 5px 10px #450E8B;
}
.kenoSquareItem.kenoSquareItemPick{
  animation: kenoSquareScale 0.4s ease-in-out;
}
.kenoSquareItem.kenoSquareItemWin,
.kenoSquareItemFast.kenoSquareItemWinFast {
  position: relative;
  background: transparent;
  border: 2px solid #791FED;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #232323;
    font-weight: 600;
  }
  img {
    display: inline-block;
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}
.kenoSquareItem.kenoSquareItemWin {
  animation: kenoSquareScale 0.4s ease-in-out;
}

@media (max-width: 640px) {
  .kenoSquaresWrap {
    padding: 10px 5px;
  }
  .kenoSquaresContent {
    min-height: calc(100px + 30vw);
    grid-gap: 5px;
  }
  .kenoSquareItem,
  .kenoSquareItemFast {
    border-radius: 8px;
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
