.dividendsTables {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 0 30px 0;
  position: relative;
  color: white;
  flex-direction: column;
}

.dividendsTablesTitles {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dividendsTablesWrap {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 30px;
}

@media (max-width: 1024px) {
  .dividendsTables {
    margin: 0;
  }
}

@media (max-width: 580px) {
  .dividendsTablesTitles {
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
