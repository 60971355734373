.burgerWrap {
  display: none;
  z-index: 15;
}

.burgerBtn {
  width: 32px;
  height: 32px;
  margin-left: 20px;
  position: relative;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  touch-action: manipulation;
}
.burgerBtn:before {
  content: '';
  position: absolute;
  top: 11px;
  width: 32px;
  background-color: #ffffff;
  height: 2px;
}
.burgerBtn:after {
  content: '';
  position: absolute;
  bottom: 11px;
  width: 32px;
  background-color: #ffffff;
  height: 2px;
}
.burgerBtnOpen {
  width: 32px;
  height: 10px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
  z-index: 15;
}
.burgerBtnOpen:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 22px;
  background-color: #ffffff;
  height: 2px;
  transform: rotate(45deg);
}
.burgerBtnOpen:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 22px;
  background-color: #ffffff;
  height: 2px;
  transform: rotate(135deg);
}

.burgerMenu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateX(-130%);
  transition: all 1s ease-in-out;
  transition-duration: 500ms;
  transition-delay: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  height: 100vh;
  background: #161e30;
  padding-bottom: 40px;
  overflow-y: auto;
  -ms-overflow-style: none;
  z-index: 12;
}
.burgerMenu.open {
  transform: translateX(0%);
}

.burgerMenuLogoWrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
}

.burgerMenuLogo {
  display: inline-block;
  width: 120px;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.burgerMenuContent,
.burgerMenuContentAuth {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.burgerMenuContentAuth {
  border: none;
}
.burgerMenu::-webkit-scrollbar {
  width: 0;
}

.burgerMenuElem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.burgerMenuLogOut {
  display: inline-block;
  margin: 0 15px 0 0;
  width: 23px;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.burgerMenuTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;

  color: #ffffff;
  cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
}

.languageWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 24px;
  margin-top: 28px;
  padding-bottom: 40px;
}
.languageWrap.auth {
  margin-top: 0;
}

@media (max-width: 1024px) {
  .burgerWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    overflow: hidden;
  }
  .burgerMenu.open {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: 15;
  }
  .burgerBtnOpen {
    width: 32px;
    height: 10px;
    margin-left: auto;

    cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
  }
}

@media (max-width: 600px) {
  //.burger__menu-input {
  //  padding-left: 0;
  //}
}
@media (max-width: 360px) {
  //.burger__menu--user__additional-block-image {
  //  width: 35px;
  //}
  //
  //.burger__menu--user__additional-block-wheel {
  //  width: 40px;
  //}
}
