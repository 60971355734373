.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1340px;
  padding: 30px;

  font-family: 'Montserrat';
}

.loader {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 32px;
}

.link {
  position: relative;

  display: flex;
  align-items: center;
  width: max-content;

  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;

  span {
    position: relative;
    z-index: 2;

    padding: 3px 13px;
    width: 100%;
    height: 100%;

    background: #202d4a;
    border-radius: 16px;
  }

  &:hover {
    color: white;
    box-shadow: -7.9602e-16px 0px 15px #7a2cff;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: -3px;
    top: -3px;

    padding: 3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);

    background: rgb(56, 86, 221);
    background: linear-gradient(90deg, rgba(56, 86, 221, 1) 0%, rgba(122, 44, 255, 1) 100%);
    border-radius: 16px;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 60px;

  max-height: 400px;
  height: 100%;
  width: 100%;

  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
}

.content {
  margin: 0 auto 1rem;
  width: 860px;
}

.meta {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 16px;
}

.tagWrapper {
  height: 24px;
}

.tag {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #fccd00;

  &::after {
    content: ', ';
  }

  &:last-child {
    &::after {
      content: '';
    }
  }
}

.date {
  margin-left: auto;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #7074a4;
}

.title {
  margin: 0 0 40px;

  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #ffffff;
}

.html {
  * {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }

  h1,
  h2,
  h3 {
    margin: 0 0 32px;
    letter-spacing: -0.015em;

    &:last-child {
      margin: 0;
    }
  }

  h1 {
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-weight: 600;
  }

  p,
  li {
    margin: 0 0 32px;

    &:last-child {
      margin: 0;
    }
  }

  table {
    width: 100%;
    margin: 0 0 32px;

    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid white;

    tr {
      border-bottom: 1px solid white;
    }

    thead {
      th {
        padding: 10px 4px;

        font-weight: bold;

        border-right: 1px solid white;
      }
    }

    tbody {
      td {
        padding: 10px 4px;

        border-right: 1px solid white;
      }
    }
  }
}

@media (max-width: 920px) {
  .content {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .imageWrapper {
    margin: 0 0 40px;
  }

  .title {
    font-size: 28px;
    line-height: 32px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    padding: 30px 0;
  }

  .nav,
  .content {
    padding: 0 30px;
  }

  .imageWrapper {
    margin: 0 0 20px;
  }

  .title {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 340px) {
  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
}
