.combinations {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
}

.payouts {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 10px;
}

.payout {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 25%;
  margin: 0;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #a2a5cc;
}

.amount {
  margin: 0 0 0 6px;
  color: #ffffff;
}

.seeds {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 5px 0 0;
}

.subtitleItem:first-child {
  margin: 5px 1rem 0 0;
  min-width: 100px;
  p {
    margin: 0;
    padding: 0.3rem 0;

    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #888aa7;
    text-align: left;
  }
}

.subtitleItem:last-child {
  flex-grow: 1;
  width: 50%;
}

.seedsText {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  padding: 0.3rem 0.6rem;
  margin: 0;
  width: 100%;

  border: 2px solid #3757dd;
  border-radius: 14px;

  p {
    margin: 0;

    color: #ffffff;
    font-size: 0.8rem;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 17px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div {
    margin-left: auto;
  }
}

.verifyButtonWrapper {
  margin-top: 1rem;
}

.verifyButton {
  text-transform: uppercase;
}

@media (max-width: 540px) {
  .combinations {
    flex-direction: column;
    align-items: flex-start;
  }

  .payouts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
