.lotteryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 4px 0 0;
  background: rgba(255, 255, 255, 0.07);
  mix-blend-mode: normal;
  background: #151d2f;
  border: 2px solid #202d4a;
  border-radius: 26px;
  margin-left: 10px;

  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}

.lotteryHeaderImg {
  display: inline-block;
  height: 48px;
}

.lotteryHeaderPrize {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  img {
    display: inline-block;
    width: 38px;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;

    color: #ffffff;
    flex-shrink: 0;
  }
}

.lotteryHeaderButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  button {
    width: 120px;
    height: 38px;
    border: none;
    outline: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.1px;
    text-transform: uppercase;

    color: #ffffff;

    background: linear-gradient(180deg, #eab62f 0%, #f7931a 100%);
    box-shadow: -7.9602e-16px 0px 10px rgba(247, 147, 26, 0.75);
    border-radius: 19px;
    cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;

    &:hover {
      cursor: url('../../../../assets/img/common/cursor-pointer.webp'), auto !important;
      box-shadow: -7.9602 e-16px 0px 15px rgba(247, 147, 26, 0.75);
    }

    &:active {
      box-shadow: 4px 4px 8px 0px rgba(63, 80, 34, 0.2);
    }
  }
}



@media (max-width: 1450px) {
  .lotteryHeaderButton {
    button {
      font-size: 10px;
      width: 90px;
    }
  }
}
@media (max-width: 1400px) {
  .lotteryHeaderPrize {
    img {
      width: 28px;
    }
    p {
      font-size: 14px;
    }
  }
}
@media (max-width: 1380px) {
  .lotteryHeader {
    height: 38px;
  }
  .lotteryHeaderImg {
    width: 30px;
    height: 30px;
  }
  .lotteryHeaderButton {
    button {
      font-size: 8px;
      width: 70px;
      height: 32px;
      border-radius: 15px;
    }
  }
  .lotteryHeaderPrize {
    img {
      width: 25px;
    }
    p {
      font-size: 11px;
    }
  }
}
@media (max-width: 1260px) {
  .lotteryHeader {
    display: none;
  }
}

//@media (max-width: 400px) {
//  .lottery-mob-ticket-Img {
//    width: 20px;
//  }
//  .lottery-mob-prize {
//    img {
//      width: 25px;
//    }
//    p {
//      font-size: 14px;
//      line-height: 16px;
//    }
//  }
//  .lottery-mob-button {
//    button {
//      width: 80px;
//      height: 24px;
//      font-size: 10px;
//    }
//  }
//}