.wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
}

.betAmount {
  height: 91px;
  flex-grow: 3;
}

.diceSubmitAutoWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 4;
  min-width: 190px;
  max-width: 406px;
}

@media (max-width: 640px) {
  .wrapper {
    gap: 10px;
  }
  .betAmount {
    width: 100%;
    min-height: 91px;
    height: 91px;
  }
  .diceSubmitAutoWrap {
    max-width: 100%;
  }
}
